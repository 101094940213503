/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import cookie from 'react-cookies';
import Constants from '../constants';

/**
 * Delete the cookies by the name provided
 * @param {*} name cookie name
 */
export const deleteCookieByName = name => {
  cookie.remove(name, {
    domain: '.pearson.com',
    path: '/',
    secure: true
  });
  return true;
};

export const deleteCookie = name => {
  cookie.remove(name);
};

export const saveCookie = (name, value, isExpiryRequired) => {
  const maxAge = {
    maxAge: Constants.COOKIE_MAX_AGE,
    secure: true,
    sameSite: 'strict'
  };
  if (isExpiryRequired) {
    // setting maxAge for 24hr only for required cookie
    cookie.save(name, value, maxAge);
  } else {
    cookie.save(name, value);
  }
};

export const loadCookie = name => {
  return cookie.load(name);
};
