/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import { axiosFetch } from './axiosFetch';
import API_CONSTANTS from '../constants/apiConstants';
import { getHeaders, parseURLParams } from '../utils';

export const getConfig = async () => {
  const apiPath = API_CONSTANTS.GET_CONFIG;
  const reqOptions = {
    ...getHeaders()
  };

  // skip error handling through interceptor
  const errorHandlingProps = {
    skipError: true,
    codes: [403]
  };

  const response = await axiosFetch(
    reqOptions,
    apiPath,
    {},
    errorHandlingProps
  );
  return response.data;
};

export const getAssetDownloadConfig = async () => {
  const replacement = { '%configDataKey%': 'assetDownloadConfig' };
  const apiPath = parseURLParams(replacement, API_CONSTANTS.FETCH_CONFIG_DATA);

  const reqOptions = {
    ...getHeaders()
  };

  const response = await axiosFetch(reqOptions, apiPath);
  const configData = response.data.data;

  const formattedConfig = {};
  configData.forEach(item => {
    formattedConfig[item.id] = item.value;
  });

  return formattedConfig;
};

export const getPaceAITabData = async () => {
  const replacement = { '%configDataKey%': 'paceAITabsData' };
  const apiPath = parseURLParams(replacement, API_CONSTANTS.FETCH_CONFIG_DATA);

  const reqOptions = {
    ...getHeaders()
  };

  const response = await axiosFetch(reqOptions, apiPath);
  return response.data.data || [];
};
