/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const ListViewIcon = () => (
  <SvgIcon width="24px" height="24px" viewBox="0 0 24 24">
    <path
      d="M21,3 L21,8 L3,8 L3,3 L21,3 Z M19,4.5 L5,4.5 L5,6.5 L19,6.5 L19,4.5 Z M21,9.5 L21,14.5 L3,14.5 L3,9.5 L21,9.5 Z M19,11.05 L5,11.05 L5,13.05 L19,13.05 L19,11.05 Z M21,16 L21,21 L3,21 L3,16 L21,16 Z M19,17.5 L5,17.5 L5,19.5 L19,19.5 L19,17.5 Z"
      id="path-1"
    ></path>
  </SvgIcon>
);
