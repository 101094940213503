/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */
/* eslint-disable no-param-reassign */

import { types } from 'mobx-state-tree';
import { getUserPreferenceMasterData } from '../services/preferences.service';
import { Platform, TableModeColumn } from './UserPreference';
import Constants from '../constants';

const Layout = types.model('Layout', {
  id: types.string,
  label: types.string
});
const Filter = types.model('Filter', {
  label: types.string,
  value: types.string
});
const Status = types.model('Staus', {
  label: types.string,
  value: types.string,
  visible: types.maybeNull(types.boolean)
});

export default types
  .model('UserPreferenceMasterData', {
    filters: types.array(Filter),
    layouts: types.array(Layout),
    platforms: types.array(Platform),
    status: types.array(Status),
    tableModeColumns: types.array(TableModeColumn)
  })
  .actions(self => ({
    async getMasterData() {
      const data = await getUserPreferenceMasterData();
      self.setMasterData(data);
    },
    setMasterData(masterData) {
      const {
        GRID_VIEW_MODE_TOOLTIP,
        LIST_VIEW_MODE_TOOLTIP,
        TABLE_VIEW_MODE_TOOLTIP
      } = Constants;
      const layoutOptions = masterData.layout.map(layout => {
        let label;
        if (layout === 'table') {
          label = TABLE_VIEW_MODE_TOOLTIP;
        } else if (layout === 'list') {
          label = LIST_VIEW_MODE_TOOLTIP;
        } else {
          label = GRID_VIEW_MODE_TOOLTIP;
        }
        return { label, id: layout };
      });
      self.layouts = layoutOptions;
      self.filters = masterData.filter;
      self.status = masterData.status.map(status => ({
        ...status,
        visible: true
      }));
      self.platforms = masterData.platforms;
      self.tableModeColumns = masterData.tableModeColumns.map(column => ({
        ...column,
        sortOn: column.sortOn || '',
        mandatory: column.mandatory !== undefined ? column.mandatory : false
      }));
    }
  }));
