/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { getBuildInfo } from '../services/getBuildInfo';

export default types
  .model('BuildInfo', {
    buildInfo: types.frozen({})
  })
  .actions(self => ({
    /**
     * @function - get build Information of UDB API Server
     */
    async getBuildInformation() {
      const buildInfo = await getBuildInfo();
      self.updateBuildInfo(buildInfo);
      return buildInfo;
    },

    updateBuildInfo(buildInfo) {
      self.buildInfo = { ...buildInfo };
    }
  }));
