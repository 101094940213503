/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import Constants from '../../constants';
import { getFormattedDate } from '../../utils';

const {
  // FAILURE
  VERSION_STATUS_FAILURE,
  // FAILED
  VERSION_STATUS_DISPLAY_FAILED,
  // IN_PROGRESS
  VERSION_STATUS_IN_PROGRESS,
  // WIP_IN_PROGRESS
  VERSION_STATUS_WIP_IN_PROGRESS,
  // DELIVERED
  VERSION_STATUS_DELIVERED,
  // APPROVED
  VERSION_APPROVED,
  // COMPLETED
  VERSION_COMPLETED_DISPLAY_STATUS,
  // WIP
  VERSION_WIP,
  VERSION_WIP_STATUS,
  // WIP-FAILED
  VERSION_WIP_FAILED,
  // WIP-DELIVERED
  VERSION_WIP_DELIVERED,
  // LOCKED
  VERSION_LOCKED,
  PRODUCT_MODEL_REVEL,
  PRODUCT_MODEL_ETEXT2,
  DESTINATION_CITE,
  DESTINATION_GPS,
  DESTINATION_GLP,
  DESTINATION_BAU,
  ETEXT,
  REVEL,
  VERSION_IN_REVIEW,
  VERSION_PUBLISHED_LIVE,
  VERSION_IN_REVIEW_STATUS,
  VERSION_PUBLISHED_LIVE_DISPLAY_STATUS,
  VERSION_WIP_IN_REVIEW,
  VERSION_WIP_PUBLISHED_LIVE,
  VERSION_POST_C6_DELIVERED,
  VERSION_POST_C6_IN_PROGRESS,
  VERSION_POST_C6_FAILED,
  VERSION_POST_C6_DELIVERED_DISPLAY_STATUS,
  VERSION_POST_C6_IN_PROGRESS_DISPLAY_STATUS,
  VERSION_POST_C6_FAILED_DISPLAY_STATUS,
  VERSION_WIP_POST_C6_DELIVERED,
  VERSION_WIP_POST_C6_FAILED,
  VERSION_WIP_POST_C6_IN_PROGRESS
} = Constants;

// if publish status is available then checking scenarios based on matrix
const getDisplayStatusTextFromPublishStatus = publishStatus => {
  switch (publishStatus) {
    // approved && in-progress
    case VERSION_STATUS_IN_PROGRESS: {
      return VERSION_STATUS_IN_PROGRESS;
    }

    // approved && failure
    case VERSION_STATUS_FAILURE: {
      return VERSION_STATUS_DISPLAY_FAILED;
    }

    // approved && delivered
    case VERSION_STATUS_DELIVERED: {
      return VERSION_COMPLETED_DISPLAY_STATUS;
    }
    // approved && in-review
    case VERSION_IN_REVIEW: {
      return VERSION_IN_REVIEW_STATUS;
    }

    // approved && published-live
    case VERSION_PUBLISHED_LIVE: {
      return VERSION_PUBLISHED_LIVE;
    }

    // approved && post-c6-delivered
    case VERSION_POST_C6_DELIVERED: {
      return VERSION_POST_C6_DELIVERED_DISPLAY_STATUS;
    }

    // approved && post-c6-failed
    case VERSION_POST_C6_FAILED: {
      return VERSION_POST_C6_FAILED_DISPLAY_STATUS;
    }

    // approved && post-c6-in-progress
    case VERSION_POST_C6_IN_PROGRESS: {
      return VERSION_POST_C6_IN_PROGRESS_DISPLAY_STATUS;
    }

    default: {
      return publishStatus;
    }
  }
};

/**
 *    ========================================================================
 *     PROJECT STATUS      ||   PUBLISH STATUS   ||  DISPLAY STATUS  || COLOR
 *    ------------------------------------------------------------------------
 *     APPROVED            ||   Not Available    ||  LOCKED          || Green
 *     APPROVED            ||   FAILURE          ||  FAILED          || RED
 *     APPROVED            ||   IN-PROGRESS      ||  IN-PROGRESS     || ORANGE  // Rare Scenario
 *     APPROVED            ||   DELIVERED        ||  COMPLETED       || GREEN
 *     WIP                 ||   Not Available    ||  WIP             || GREY
 *     WIP                 ||   FAILURE          ||  WIP-FAILED      || RED
 *     WIP                 ||   IN-PROGRESS      ||  WIP-IN-PROGRESS     || ORANGE
 *     WIP                 ||   DELIVERED        ||  WIP-DELIVERED   || GREEN   // Rare Scenario
 *     APPROVED            ||   IN-REVIEW        ||  IN REVIEW       || PINK
 *     APPROVED            ||   PUBLISHED-LIVE   ||  PUBLISHED LIVE  || GREEN
 *     WIP                 ||   IN-REVIEW        ||  WIP-IN-REVIEW   || PINK   // Rare Scenario
 *     WIP                 ||   PUBLISHED-LIVE   ||  WIP-PUBLISHED-LIVE || GREEN  //Rare Scenario
 *    ========================================================================
 *
 * Calculates the actual display status that needs to be shown on UI based on project and publishStatus
 * @param {object} versionStatus status object containing projectStatus & publishStatus
 * returns  version display status
 */
const getDisplayTextForWip = publishStatus => {
  switch (publishStatus) {
    case VERSION_STATUS_IN_PROGRESS:
      return VERSION_STATUS_WIP_IN_PROGRESS;
    case VERSION_STATUS_FAILURE:
      return VERSION_WIP_FAILED;
    case VERSION_STATUS_DELIVERED:
      return VERSION_WIP_DELIVERED;
    case VERSION_IN_REVIEW:
      return VERSION_WIP_IN_REVIEW;
    case VERSION_PUBLISHED_LIVE:
      return VERSION_WIP_PUBLISHED_LIVE;
    case VERSION_POST_C6_IN_PROGRESS:
      return VERSION_WIP_POST_C6_IN_PROGRESS;
    case VERSION_POST_C6_FAILED:
      return VERSION_WIP_POST_C6_FAILED;
    case VERSION_POST_C6_DELIVERED:
      return VERSION_WIP_POST_C6_DELIVERED;
    default:
      return null;
  }
};

export const getDisplayStatusText = versionStatus => {
  // setting publish status to null if its not available
  const { projectStatus, publishStatus = null } = versionStatus;
  // returning the project status if publish status is not defined
  if (!publishStatus && projectStatus) {
    // if project status is 'approved' and publish status is not available then returning locked
    if (projectStatus === VERSION_APPROVED) {
      return VERSION_LOCKED;
    }
    if (projectStatus === VERSION_WIP) {
      return VERSION_WIP_STATUS;
    }
    return projectStatus;
  }

  if (projectStatus === VERSION_WIP && publishStatus) {
    return getDisplayTextForWip(publishStatus);
  }

  // if publish status is available and above conditions are not met then checking scenarios based on above matrix
  return getDisplayStatusTextFromPublishStatus(publishStatus);
};

/* this method maps the Distribution of version Publish Info */
export const getDistribution = distribution => {
  switch (distribution) {
    case DESTINATION_CITE:
      return DESTINATION_BAU;
    case DESTINATION_GLP:
      return DESTINATION_GLP;
    case DESTINATION_GPS:
      return DESTINATION_GPS;
    default:
      return distribution;
  }
};

/* this method maps the Product Model of version Publish Info */
export const getProductModel = productModel => {
  switch (productModel) {
    case PRODUCT_MODEL_REVEL:
      return REVEL;
    case PRODUCT_MODEL_ETEXT2:
      return ETEXT;
    default:
      return productModel;
  }
};

export const getDistributionText = (publishInfo, dateFormat) => {
  let productModel = '';
  let distribution = '';
  let transactionDate = '';
  let distributionText = '';
  // if Publish Info available get the distribution detail by its model and destination
  if (publishInfo) {
    productModel = getProductModel(publishInfo.productModel) || '';
    distribution = getDistribution(publishInfo.publishDestination) || '';
    transactionDate = getFormattedDate(publishInfo.transactionDate, dateFormat);
  }

  if (productModel === Constants.REVEL) {
    if (distribution === Constants.DESTINATION_GLP) {
      // If distribution is GLP and productModel is revel change distribution display as PLP
      distribution = Constants.DESTINATION_PLP;
    } else if (distribution === Constants.DESTINATION_BAU) {
      // If distribution is BAU and productModel is revel change distribution display as ILP
      distribution = Constants.DESTINATIN_ILP;
    } else {
      // do nothing
    }
  }

  distributionText = `${productModel} ${distribution}`;

  if (
    productModel === Constants.ETEXT &&
    distribution === Constants.DESTINATION_PCX
  ) {
    distributionText = `${Constants.VIRTUALSCHOOLS}`;
  }

  // If distribution is GPS or PRIZM  don't show productModel
  if (
    distribution === Constants.DESTINATION_GPS ||
    distribution === Constants.DESTINATION_PRIZM
  ) {
    distributionText = distribution;
  }

  return { distributionText, transactionDate };
};

/**
 *    ========================================================================
 *     DISPLAY STATUS   || STATUS           || TOOLTIP          || COLOR
 *    ------------------------------------------------------------------------
 *     LOCKED           || LOCKED           || LOCKED           || Green
 *     FAILED           || FAILED           || FAILED           || RED
 *     IN-PROGRESS      || IN-PROGRESS      || IN-PROGRESS      || ORANGE
 *     COMPLETED        || COMPLETED        || COMPLETED        || GREEN
 *     WIP              || WORK IN PROGRESS || WORK IN PROGRESS || GREY
 *     WIP-FAILED       || WORK IN PROGRESS || WIP-FAILED       || RED
 *     WIP-IN-PROGRESS  || WORK IN PROGRESS || WIP-IN-PROGRESS  || ORANGE
 *     WIP-DELIVERED    || WORK IN PROGRESS || WIP-DELIVERED    || GREEN
 *    ========================================================================
 *
 * Calculates the actual display status and tooltip in dashboard cards and tableview
 * @param {string} displayStatusText
 */
export const displayStatusTextWithTooltip = displayStatusText => {
  switch (displayStatusText) {
    case VERSION_WIP:
      return {
        statusText: VERSION_WIP_STATUS,
        toolTipText: VERSION_WIP_STATUS
      };
    case VERSION_WIP_FAILED:
      return {
        statusText: VERSION_WIP_STATUS,
        toolTipText: VERSION_WIP_FAILED
      };
    case VERSION_WIP_DELIVERED:
      return {
        statusText: VERSION_WIP_STATUS,
        toolTipText: VERSION_WIP_DELIVERED
      };
    case VERSION_STATUS_WIP_IN_PROGRESS:
      return {
        statusText: VERSION_WIP_STATUS,
        toolTipText: VERSION_STATUS_WIP_IN_PROGRESS
      };
    case VERSION_IN_REVIEW:
      return {
        statusText: VERSION_IN_REVIEW_STATUS,
        toolTipText: VERSION_IN_REVIEW_STATUS
      };
    case VERSION_PUBLISHED_LIVE:
      return {
        statusText: VERSION_PUBLISHED_LIVE_DISPLAY_STATUS,
        toolTipText: VERSION_PUBLISHED_LIVE_DISPLAY_STATUS
      };
    case VERSION_WIP_IN_REVIEW:
      return {
        statusText: VERSION_WIP_STATUS,
        toolTipText: VERSION_WIP_IN_REVIEW
      };
    case VERSION_WIP_PUBLISHED_LIVE:
      return {
        statusText: VERSION_WIP_STATUS,
        toolTipText: VERSION_WIP_PUBLISHED_LIVE
      };
    case VERSION_WIP_POST_C6_DELIVERED:
      return {
        statusText: VERSION_WIP_STATUS,
        toolTipText: VERSION_WIP_POST_C6_DELIVERED
      };
    case VERSION_WIP_POST_C6_FAILED:
      return {
        statusText: VERSION_WIP_STATUS,
        toolTipText: VERSION_WIP_POST_C6_FAILED
      };
    case VERSION_WIP_POST_C6_IN_PROGRESS:
      return {
        statusText: VERSION_WIP_STATUS,
        toolTipText: VERSION_WIP_POST_C6_IN_PROGRESS
      };
    default:
      return {
        statusText: displayStatusText,
        toolTipText: displayStatusText
      };
  }
};

export const getStatusColorClassName = statusText => {
  let className = statusText.toLowerCase();
  if (className === 'in review' || className === 'work in progress') {
    const classParts = className.split(' ');
    className = classParts.join('-');
  }
  return className;
};
