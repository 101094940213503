/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { LockIcon } from '../../custom/icons/LockIcon';
import Header from '../Header/Header';
import Constants from '../../constants';
import './ForbiddenPage.style.scss';

const ForbiddenPage = props => {
  const {
    store: {
      UserInfo: { firstName: userName }
    }
  } = props;
  const {
    FORBIDDEN_MESSAGE1,
    FORBIDDEN_MESSAGE2,
    AD_GROUP_EDITOR,
    BROWSER_TITLE,
    PROJECT_TITLE,
    FORBIDDEN_IES_AES_PROD,
    FORBIDDEN_IES_AES_NON_PROD,
    FORBIDDEN_PEDEV_ACCOUNT_HELP
  } = Constants;

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  // retrieving forbidden query parameter value send in Redirect route or the query string
  const forbiddenRole = location.query?.role || searchParams.get('role');
  const forbiddenApp = location.query?.app || searchParams.get('app');
  const forbiddenUser =
    location.query?.firstName || searchParams.get('firstName');

  const isProdENv =
    window.location.host === Constants.PROD_URL ||
    window.location.host === Constants.PROD_PACE_URL;

  const welcomeText =
    userName || forbiddenUser
      ? `Welcome, ${userName || forbiddenUser}`
      : 'Dear User';

  // show the required permission section if application passing the app name and role both or else if its a UDB default page
  const showPermissionSection =
    (forbiddenApp && forbiddenRole) || !forbiddenApp;

  return (
    <Fragment>
      <Helmet>
        <title>{forbiddenApp || BROWSER_TITLE}</title>
      </Helmet>
      <Header
        isUnAuthorized
        showReleaseVersion
        appName={forbiddenApp}
        isIconDisable
      />

      <div className="forbidden-page-wrapper">
        <div>
          <p className="welcome-text" data-testid="welcome-text">
            {welcomeText}
          </p>
          <p>{FORBIDDEN_MESSAGE1(forbiddenApp || PROJECT_TITLE)}</p>
          {showPermissionSection && (
            <>
              <p>{FORBIDDEN_MESSAGE2}</p>
              <div className="ad-groups-text">
                <div>{forbiddenRole || AD_GROUP_EDITOR}</div>
              </div>
            </>
          )}
          <p>Do you have a Pearson account?</p>
          <ul>
            <li>
              Yes - Please go to the following Pearson Microsoft Teams channel.
            </li>
            {isProdENv ? (
              <ul>
                <li>
                  <a href={FORBIDDEN_IES_AES_PROD} target="_blank">
                    PACE Support Team
                  </a>
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  PEDEV Account Help:{' '}
                  <a href={FORBIDDEN_PEDEV_ACCOUNT_HELP} target="_blank">
                    PEDEV Account
                  </a>
                </li>
                <li>
                  IES/AES Non-Prod:{' '}
                  <a href={FORBIDDEN_IES_AES_NON_PROD} target="_blank">
                    Non-prod
                  </a>
                </li>
              </ul>
            )}
            <li>
              No - Please reach out to your line manager if known or send an
              email to PACEAppsSupport@pearson.com, requesting access to PACE
              applications.
            </li>
          </ul>
        </div>
        <div className="forbidden-lock-image">
          <LockIcon fontSize="large" />
        </div>
      </div>
    </Fragment>
  );
};

ForbiddenPage.propTypes = {
  store: PropTypes.shape({
    UserInfo: PropTypes.shape({
      firstName: PropTypes.string.isRequired
    }),
    FileUrls: PropTypes.shape({
      REQUEST_AD_GROUP_PDF: PropTypes.string
    })
  })
};

export default inject('store')(observer(ForbiddenPage));
