/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import merge from 'lodash/merge';

/**
 *
 * @param {Object} customHeaders - additional headers
 * @usage contentTypeHeader({ custom: 'test123' })
 */
const contentTypeHeader = customHeaders => ({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...customHeaders
  }
});

/**
 * Headers for post request
 * @param {Object} customHeaders - additional headers
 */
const postHeaders = customHeaders =>
  merge({ method: 'POST' }, contentTypeHeader(customHeaders));

/**
 * Headers for put request
 * @param {Object} customHeaders - additional headers
 */
const putHeaders = customHeaders =>
  merge({ method: 'PUT' }, contentTypeHeader(customHeaders));

/**
 * Headers for get request
 * @param {Object} customHeaders - additional headers
 */
const getHeaders = customHeaders =>
  merge({ method: 'GET' }, contentTypeHeader(customHeaders));

/**
 * Headers for delete request
 * @param {Object} customHeaders - additional headers
 */
const deleteHeaders = customHeaders =>
  merge({ method: 'DELETE' }, contentTypeHeader(customHeaders));

/**
 * Headers for patch request
 * @param {Object} customHeaders - additional headers
 */
const patchHeaders = customHeaders =>
  merge({ method: 'PATCH' }, contentTypeHeader(customHeaders));

export { postHeaders, putHeaders, getHeaders, deleteHeaders, patchHeaders };
