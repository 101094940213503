/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { toJS } from 'mobx';
import User from './User';
import { getUsers as getUsersService } from '../services/getUsers';
import { removeUser as removeUserService } from '../services/removeUser';
import { addUser as addUserService } from '../services/addUser';
import {
  sortByPropAndDirection,
  getProjectCardBackgroundColor
} from '../utils';
import constants from '../constants';
import { UserRole } from './UserRoles';

export default types
  .model('UserManagement', {
    visible: false,
    users: types.array(User),
    admins: types.array(User),
    // state to be updated when members are searched
    filteredUser: types.array(User),
    // state to be updated when members are searched
    filteredAdmins: types.array(User),
    searchTerm: types.frozen(''),
    columnToSort: 'lastModifiedDate',
    sortDirection: constants.USER_MANAGEMENT_DEFAULT_SORT_DIRECTION,
    userRoles: types.map(UserRole),
    selectedUsersForRemoval: types.array(types.string),
    selectAllUserForRemoval: false
  })
  .actions(self => ({
    openUserManagementDrawer() {
      self.visible = true;
    },
    closeUserManagementDrawer() {
      self.visible = false;
    },
    async getUsersByEntity(entityUrn) {
      self.users = [];
      self.admins = [];
      const response = await getUsersService(entityUrn);
      self.setUsers(response);
      self.sortMembers();
    },
    setUsers(data) {
      self.users = [];
      self.admins = [];
      const { users, admins } = data;

      // pushing users into Model.users
      users.forEach(user => {
        const { backgroundColor: color } = getProjectCardBackgroundColor();
        self.users.push({ ...user, color });
      });

      // pushing admins into Model.admins
      admins.forEach(admin => {
        const { backgroundColor: color } = getProjectCardBackgroundColor();
        self.admins.push({ ...admin, roleId: 'admin', color });
      });

      self.updateRoleLabel();
    },
    updateUsers(userIds) {
      // eslint-disable-next-line no-unused-expressions
      userIds &&
        userIds.forEach(userId => {
          const index = self.users.findIndex(user => user.userId === userId);
          if (index !== -1) {
            self.users.splice(index, 1);
          }
        });
      // if SearchTerm exists update the filter users array too
      if (self.searchTerm) {
        self.searchUser(self.searchTerm);
      }
    },
    addNewUserToLocalData(newUser) {
      const allUsers = [...toJS(self.users), newUser];
      self.users = [];

      // pushing users into Model.users
      allUsers.forEach(user => {
        const { backgroundColor: color } = getProjectCardBackgroundColor();
        self.users.push({ ...user, color });
      });

      self.updateRoleLabel();
      self.sortMembers();

      // if SearchTerm exists update the filter users array too
      if (self.searchTerm) {
        self.searchUser(self.searchTerm);
      }
    },
    async removeUserByEntityAndId(entityUrn, userIds) {
      await removeUserService(entityUrn, userIds);
      self.updateUsers(userIds);
      // after removal of user reset the checked state of all users to false
      self.selectUnselectAllUsersForRemoval(false);
    },
    async addUser(entityUrn, roleId, userId, email) {
      const { lastModifiedDate } = await addUserService(
        entityUrn,
        roleId,
        userId
      );
      self.addNewUserToLocalData({ userId, roleId, email, lastModifiedDate });
    },

    // add bulk user
    addBulkUsers(users) {
      users.forEach(user => {
        const { userId, roleId, email, lastModifiedDate } = user;
        self.addNewUserToLocalData({ userId, roleId, email, lastModifiedDate });
      });
      // after addition of users reset the checked state of all users to false
      self.selectUnselectAllUsersForRemoval(false);
    },

    setSearchTerm(searchTerm) {
      self.searchTerm = searchTerm;
    },
    resetSearchUser(loggedInUser) {
      self.searchTerm = '';
      self.filteredUser = [];
      self.filteredAdmins = [];
      self.validateUserSelectionCount(loggedInUser);
    },
    searchUser(searchTerm, loggedInUser) {
      const allUsers = [...toJS(self.users)];
      const allAdmins = [...toJS(self.admins)];
      self.filteredUser = [];
      self.filteredAdmins = [];
      if (searchTerm) {
        allUsers.forEach(user => {
          if (
            user.userId.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            self.filteredUser.push(user);
          }
        });
        allAdmins.forEach(user => {
          if (
            user.userId.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            self.filteredAdmins.push(user);
          }
        });
        self.validateUserSelectionCount(loggedInUser);
      }
    },
    sortUsers() {
      const allUsers = [...toJS(self.users)];

      // sort users and admins
      allUsers.sort(
        sortByPropAndDirection(self.columnToSort, self.sortDirection)
      );

      self.users = [];

      // pushing users into Model.users
      allUsers.forEach(user => {
        self.users.push(user);
      });

      // Sorting filtered Users
      if (self.searchTerm) {
        const allFilteredUsers = [...toJS(self.filteredUser)];
        allFilteredUsers.sort(
          sortByPropAndDirection(self.columnToSort, self.sortDirection)
        );

        self.filteredUser = [];
        allFilteredUsers.forEach(user => {
          self.filteredUser.push(user);
        });
      }
    },
    sortAdmins() {
      const allAdmins = [...toJS(self.admins)];

      allAdmins.sort(
        sortByPropAndDirection(self.columnToSort, self.sortDirection)
      );

      self.admins = [];

      // pushing admins into Model.admins
      allAdmins.forEach(admin => {
        self.admins.push({ ...admin, roleId: 'admin' });
      });

      // Sorting filtered admins
      if (self.searchTerm) {
        const allFilteredAdmins = [...toJS(self.filteredAdmins)];
        allFilteredAdmins.sort(
          sortByPropAndDirection(self.columnToSort, self.sortDirection)
        );

        self.filteredAdmins = [];
        allFilteredAdmins.forEach(user => {
          self.filteredAdmins.push(user);
        });
      }
    },
    sortMembers() {
      self.sortUsers();
      self.sortAdmins();
    },
    setSortDirection(value) {
      self.sortDirection = value;
    },
    setColumnToSort(value) {
      self.columnToSort = value;
    },
    resetSortCriteria() {
      self.columnToSort = 'lastModifiedDate';
      self.sortDirection = constants.USER_MANAGEMENT_DEFAULT_SORT_DIRECTION;
    },
    addRoleLabel(roles) {
      const objUserRoles = Object.fromEntries(toJS(roles));
      self.userRoles = objUserRoles;
      self.updateRoleLabel();
    },
    updateUsersRoleLabel() {
      if (self.userRoles) {
        const allUsers = [...toJS(self.users)];
        self.users = [];

        // adding roles label to users
        allUsers.forEach(user => {
          const roleLabel = self.userRoles.get(user.roleId)
            ? self.userRoles.get(user.roleId).label
            : '';
          self.users.push({ ...user, roleLabel });
        });

        // update user roles in filtered array
        if (self.searchTerm) {
          const allFilteredUsers = [...toJS(self.filteredUser)];
          self.filteredUser = [];

          // adding roles label to users
          allFilteredUsers.forEach(user => {
            const roleLabel = self.userRoles.get(user.roleId)
              ? self.userRoles.get(user.roleId).label
              : '';
            self.filteredUser.push({ ...user, roleLabel });
          });
        }
      }
    },
    updateAdminsRoleLabel() {
      if (self.userRoles) {
        const allAdmins = [...toJS(self.admins)];

        self.admins = [];
        // adding roles label to admins
        allAdmins.forEach(admin => {
          const roleLabel = self.userRoles.get(admin.roleId)
            ? self.userRoles.get(admin.roleId).label
            : '';

          self.admins.push({ ...admin, roleLabel });
        });
      }
    },
    updateRoleLabel() {
      self.updateUsersRoleLabel();
      self.updateAdminsRoleLabel();
    },
    updateUserData(userId, newRoleId, lastModifiedDate) {
      const [updatedUser] = self.users.filter(user => user.userId === userId);
      updatedUser.updateUserRole(newRoleId);
      updatedUser.updateLastModifiedDate(lastModifiedDate);

      if (self.searchTerm) {
        const [updatedFilteredUser] = self.filteredUser.filter(
          user => user.userId === userId
        );
        updatedFilteredUser.updateUserRole(newRoleId);
        updatedFilteredUser.updateLastModifiedDate(lastModifiedDate);
      }

      self.updateRoleLabel();
      self.sortMembers();
    },
    updateUserRoles(roleId, label, owner, createdBy) {
      self.userRoles.put({ roleId, label, owner, createdBy });
    },
    // updating checkbox state for a user Id
    selectUnselectUsersForRemoval(userId, loggedInUser, allUnAuthorizedUsers) {
      self.selectAllUserForRemoval = false;
      const index = self.selectedUsersForRemoval.findIndex(
        user => user === userId
      );

      const loggedIUuser = self.users.find(
        user => user.userId === loggedInUser
      );

      // id user is already added to selectedUsers array remove it else add it to array
      if (index !== -1) {
        self.selectedUsersForRemoval.splice(index, 1);
      } else {
        self.selectedUsersForRemoval.push(userId);
      }
      // will update the value of select All checkbox
      self.validateUserSelectionCount(loggedIUuser, allUnAuthorizedUsers);
    },

    validateUserSelectionCount(loggedInUser, allUnAuthorizedUsers) {
      const allUsers =
        allUnAuthorizedUsers && allUnAuthorizedUsers.length > 0
          ? self.users.length - allUnAuthorizedUsers.length
          : self.users.length;
      // if the length if selected users array is equal to original users array this indicates that all the users are checked
      if (self.selectedUsersForRemoval.length === self.users.length) {
        self.selectAllUserForRemoval = true;
      }
      // if original users array has loggedIn user detail then  compare the length of selected users array with original users array -1 -> this indicates that all the users are checked or if original users array has unAuthorized users then compare the selected users length array with (original - unAuthorizedusers length)
      else if (
        (loggedInUser &&
          self.selectedUsersForRemoval.length === allUsers - 1) ||
        self.selectedUsersForRemoval.length === allUsers
      ) {
        self.selectAllUserForRemoval = true;
      } else {
        self.selectAllUserForRemoval = false;
      }
    },
    // updating the checkbox state for all users
    selectUnselectAllUsersForRemoval(value, loggedInUser) {
      self.selectedUsersForRemoval = [];
      if (value === false) {
        self.selectAllUserForRemoval = false;
      } else {
        // if search term is selected use filtered user array else use default user array
        const userArray = self.searchTerm
          ? [...toJS(self.filteredUser)]
          : [...toJS(self.users)];
        userArray.forEach(user => {
          // except the logged in user all other user are added to selected user array
          if (user.userId !== loggedInUser && user.isAuthorizedUser) {
            self.selectedUsersForRemoval.push(user.userId);
            self.selectAllUserForRemoval = true;
          }
        });
      }
    }
  }));
