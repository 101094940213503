/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableRow,
  TableContainer
} from '@mui/material';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import {
  CustomDialog,
  DialogHeader,
  DialogContent
} from '../../components/common/Dialog';
import Constants from '../../constants';
import './BuildInfo.style.scss';

const BuildInfo = props => {
  const {
    store: {
      Config: {
        BUILD_NUMBER,
        GIT_TAG,
        GIT_COMMIT,
        GIT_BRANCH,
        AUTHOR_NAME,
        CTOOL_HOST
      },
      BuildInfo: { buildInfo, getBuildInformation }
    }
  } = props;

  const [udbDashboardApiBuildInfo, setudbApiBuildInfo] = useState(buildInfo);
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { search, pathname } = location;

  const queryParam = queryString.parse(search);
  // retrieving buildInfo query parameter value
  const buildInfoParam = queryParam.buildInfo;

  const closeDialog = () => {
    navigate(pathname);
    setDialogOpen(false);
  };

  const {
    BUILD_INFORMATION,
    DASHBOARD_API_V2_SERVER_INFO,
    UDB_DASHBOARD_API_SERVER_INFO,
    NOT_AVAILABLE
  } = Constants;

  /** *
   TO DO  Displaying  UI build info Object
  const uiBuildInfo = {
    BUILD_NUMBER: process.env.BUILD_NUMBER,
    GIT_TAG: process.env.GIT_BRANCH,
    GIT_COMMIT: process.env.GIT_COMMIT,
    GIT_BRANCH: process.env.GIT_BRANCH,
    AUTHOR_NAME: process.env.AUTHOR_NAME
   }; */

  // Api build info Object , getting values from Config service
  const dashboardApiBuildInfo = {
    BUILD_NUMBER,
    GIT_TAG,
    GIT_COMMIT,
    GIT_BRANCH,
    AUTHOR_NAME,
    C4_URL: CTOOL_HOST
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      // Fetch UDB Api Build Info from service
      const result = await getBuildInformation();
      setudbApiBuildInfo(result);
    };

    // if query parameter has builldInfo true and dialog is not open , set dialog open to true
    if (buildInfoParam && buildInfoParam === 'true' && !dialogOpen) {
      setDialogOpen(true);
      // UDB Api server info is not available call the service
      if (isEmpty(udbDashboardApiBuildInfo)) {
        fetchInitialData();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBuildInformation, udbDashboardApiBuildInfo]);

  // Iterating the Build Info Objects to showcase key and value in cell
  const showBuildInfo = buildInformation => {
    return Object.entries(buildInformation).map(data => {
      const key = data[0] === 'AUTHOR_NAME' ? 'BUILD_PROMOTED_BY' : data[0];
      const value = data[1] || NOT_AVAILABLE;
      return (
        <TableRow key={key}>
          <TableCell>{key}</TableCell>
          <TableCell>{value}</TableCell>
        </TableRow>
      );
    });
  };

  // Creating a table format to show Build Infomation
  const getbuildTable = buildInformation => {
    return (
      <TableContainer component={Paper}>
        <Table data-testid="build-info_table" aria-label="simple table">
          <TableBody>
            {!isEmpty(buildInformation) && showBuildInfo(buildInformation)}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <CustomDialog
      open={dialogOpen}
      onClose={closeDialog}
      maxWidth="md"
      className="build-info-dialog"
    >
      <DialogHeader title={BUILD_INFORMATION} onClose={closeDialog} />
      <DialogContent>
        <div className="build-dialog__content">
          <Typography className="build-app">
            {DASHBOARD_API_V2_SERVER_INFO}
          </Typography>
          {getbuildTable(dashboardApiBuildInfo)}
        </div>
        <div className="build-dialog__content">
          <Typography className="build-app">
            {UDB_DASHBOARD_API_SERVER_INFO}
          </Typography>
          {getbuildTable(udbDashboardApiBuildInfo)}
        </div>
      </DialogContent>
    </CustomDialog>
  );
};

BuildInfo.propTypes = {
  store: PropTypes.shape({
    Config: PropTypes.shape({
      BUILD_NUMBER: PropTypes.string.isRequired,
      GIT_TAG: PropTypes.string.isRequired,
      GIT_COMMIT: PropTypes.string.isRequired,
      GIT_BRANCH: PropTypes.string.isRequired,
      AUTHOR_NAME: PropTypes.string.isRequired,
      CTOOL_HOST: PropTypes.string.isRequired
    }),
    BuildInfo: PropTypes.shape({
      getBuildInformation: PropTypes.func.isRequired,
      buildInfo: PropTypes.objectof(PropTypes.string).isRequired
    })
  })
};

export default inject('store')(observer(BuildInfo));
