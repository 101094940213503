import TagManager from 'react-gtm-module';

let isGTMInitialized = false;

export const gaInitializeGTM = (auth, gtmId, preview) => {
  const tagManagerArgs = {
    gtmId,
    auth,
    preview
  };
  TagManager.initialize(tagManagerArgs);
  isGTMInitialized = true;
};

export const gaTriggerGTMCustomEvents = data => {
  if (isGTMInitialized) {
    window.dataLayer = window.dataLayer ? window.dataLayer : [];
    window.dataLayer.push(data);
  }
};
