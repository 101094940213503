/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { saveCookie } from '../utils';
import constants from '../constants';

export default types
  .model('PageTour', {
    isTourStarted: false,
    autoStart: true,
    clickStart: false
  })
  .actions(self => ({
    stopTour(userId) {
      self.isTourStarted = false;
      self.autoStart = false;
      self.clickStart = false;
      saveCookie(`${constants.TUTORIAL_FOR}${userId}`, true, true);
    },
    startTour() {
      self.isTourStarted = true;
    },
    startTourOnClick() {
      self.clickStart = true;
    }
  }));
