/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import {
  getFeatureList as getFeatureListService,
  updateFeature
} from '../services/getFeatureList';
import { sortByPropAndDirection } from '../utils';

const Feature = types.model({
  featureId: types.maybeNull(types.string),
  isEnabled: types.maybeNull(types.boolean),
  label: types.maybeNull(types.string),
  lastModifiedBy: types.maybeNull(types.string),
  lastModifiedDate: types.maybeNull(types.string)
});
export default types
  .model('FeatureManager', {
    featureList: types.array(Feature)
  })
  .views(self => ({
    isFeatureEnabled(id) {
      return !!self.featureList.find(
        feature => feature.featureId === id && feature.isEnabled
      );
    }
  }))
  .actions(self => ({
    async getFeatureList() {
      const data = await getFeatureListService();
      self.setFeatureList(data);
    },
    setFeatureList(data) {
      self.featureList = [];
      data.sort(sortByPropAndDirection('label', 'asc'));

      data.forEach(feature => {
        self.featureList.push(feature);
      });
    },
    async updateFeatureList(updatedData, updatedFeatureList) {
      await updateFeature(updatedData);
      self.setFeatureList(updatedFeatureList);
    }
  }));
