/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';

import Message from './InfoMessage';
import Constants from '../constants';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong';
export default types
  .model('AlertMessage', {
    alertMessages: types.array(Message)
  })
  .actions(self => ({
    /**
     * Adds message to the alertMessages collection
     * @param {string} message Message is mandatory, type is optional if nothing is passed it will render Error dialog
     * @param {string} type Possible type are [error, success, warning, info] maintained in constants
     */
    setAlertMessage(
      message = DEFAULT_ERROR_MESSAGE,
      type = Constants.ALERT_TYPE_ERROR
    ) {
      // checking if message is already present in the collection
      const isMessageExist = self.alertMessages.some(
        objMessage => objMessage.message === message
      );
      if (!isMessageExist) {
        self.alertMessages.push({ message, type });
      }
    },

    /**
     * Message and types are removed from the alertMessages collection
     */
    removeAlertMessage() {
      self.alertMessages.shift();
    }
  }));
