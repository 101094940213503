/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import { axiosFetch } from './axiosFetch';
import API_CONSTANTS from '../constants/apiConstants';
import { getHeaders, postHeaders } from '../utils';
import { convertImageIntoBase64 } from '../utils/utils';

export const startUplaod = async params => {
  const reqOptions = {
    ...getHeaders()
  };

  const response = await axiosFetch(
    reqOptions,
    API_CONSTANTS.START_UPLOAD,
    params
  );
  return response.data;
};

export const completeUpload = async (params, parts) => {
  const reqOptions = {
    ...postHeaders(),
    body: { parts }
  };

  const response = await axiosFetch(
    reqOptions,
    API_CONSTANTS.COMPLETE_UPLOAD,
    params
  );
  return response.data;
};

export const partUplaod = async (data, params) => {
  const base64DataBlob = await convertImageIntoBase64(data);
  const reqOptions = {
    ...postHeaders(),
    body: { data: base64DataBlob }
  };

  return axiosFetch(reqOptions, API_CONSTANTS.PARTS_UPLOAD, params);
};
