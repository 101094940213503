/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  TextField
} from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddIcon from '@mui/icons-material/Add';
import { inject, observer } from 'mobx-react';
import constants from '../../constants';
import {
  getTaxonomies,
  getTaxonomiesTypes,
  createNewTaxonomy,
  updateTaxonomy
} from '../../services/getTaxonomiesService';
import ContentMetadataEditBox from './ContentMetadataEditBox';
import './ContentMetadata.style.scss';

const EDIT_BOX_TYPES = {
  ADD_NEW_TAXONOMY: 'AddNewTaxonomy',
  EDIT_TAXONOMY: 'EditTaxonomy'
};

const ContentMetadata = props => {
  const {
    store: {
      LineOfBusiness: { getLineOfBusinessList, lobList }
    }
  } = props;

  const { SELECT_LOB, TAXONOMY_TYPE_HEADER, NO_TAXONOMY_TYPES, NO_TAXONOMIES } =
    constants;

  const [selectedLob, setSelectedLob] = useState('');
  const [taxonomiesTypes, setTaxonomiesTypes] = useState([]);
  const [taxonomies, setTaxonomies] = useState([]);
  const [selectedTaxonomyType, setSelectedTaxonomyType] = useState({
    taxonomyType: '--',
    context: ''
  });
  const [selectedTaxonomy, setSelectedTaxonomy] = useState({});
  const [openEditBox, setOpenEditBox] = useState(null);
  const [initialValueForEditBox, setInitialValueForEditBox] = useState('');
  const [editedTaxonomy, setEditedTaxonomy] = useState('');

  const resetStates = () => {
    setTaxonomies([]);
    setSelectedTaxonomyType({ taxonomyType: '--', context: '' });
    setSelectedTaxonomy({});
    setTaxonomiesTypes([]);
    setSelectedLob('');
    setOpenEditBox(null);
    setInitialValueForEditBox('');
    setEditedTaxonomy('');
  };

  useEffect(() => {
    getLineOfBusinessList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeTaxonomyType = async taxonomyType => {
    const response = await getTaxonomies(
      selectedLob,
      taxonomyType.taxonomyType,
      true
    );
    setTaxonomies(response);
    setSelectedTaxonomyType(taxonomyType);
    setEditedTaxonomy('');
  };

  const handleLobChange = async event => {
    const { value: lob } = event.target;
    resetStates();

    // fetch taxonomyTypes for this lob
    const response = await getTaxonomiesTypes(lob);
    const formattedTaxonomyTypes = [];
    Object.keys(response).forEach(context => {
      response[context].forEach(taxonomyType => {
        formattedTaxonomyTypes.push({
          taxonomyType,
          context
        });
      });
    });
    setTaxonomiesTypes(formattedTaxonomyTypes);
    setSelectedLob(lob);
  };

  // to populate taxonomies whenever lob is changed
  useEffect(() => {
    if (taxonomiesTypes.length > 0) {
      changeTaxonomyType(taxonomiesTypes[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLob]);

  const closeEditBox = () => {
    setOpenEditBox(null);
  };

  const handleAddTaxonomy = async taxonomyLabel => {
    const { taxonomyType, context } = selectedTaxonomyType;
    const response = await createNewTaxonomy(
      selectedLob,
      taxonomyType,
      context,
      taxonomyLabel
    );
    const newTaxonomy = {
      key: response.value,
      value: response.label
    };
    setTaxonomies(existingTaxonomies => [newTaxonomy, ...existingTaxonomies]);
    setEditedTaxonomy(newTaxonomy.key);
  };

  const handleEditTaxonomy = async taxonomyLabel => {
    const { taxonomyType, context } = selectedTaxonomyType;
    const payload = {
      label: taxonomyLabel,
      value: selectedTaxonomy.key
    };
    const response = await updateTaxonomy(
      selectedLob,
      taxonomyType,
      context,
      payload
    );
    const updatedTaxonomy = {
      key: response.value,
      value: response.label
    };

    // removing existing taxonomy and adding edited taxonomy on top of the list
    setTaxonomies(existingTaxonomies => [
      updatedTaxonomy,
      ...existingTaxonomies.filter(item => item.key !== selectedTaxonomy.key)
    ]);
    setEditedTaxonomy(updatedTaxonomy.key);
  };

  const handleOpenEditBox = taxonomy => {
    setInitialValueForEditBox(taxonomy.value);
    setSelectedTaxonomy(taxonomy);
    setEditedTaxonomy('');
    setOpenEditBox(EDIT_BOX_TYPES.EDIT_TAXONOMY);
  };

  const handleOpenAddNewBox = () => {
    setInitialValueForEditBox('');
    setEditedTaxonomy('');
    setOpenEditBox(EDIT_BOX_TYPES.ADD_NEW_TAXONOMY);
  };

  return (
    <div className="project-content-metadata-wrapper">
      <div className="lob-selection-wrapper">
        <TextField
          select
          inputProps={{
            'data-testid': 'lob-input',
            'aria-label': 'lob-input'
          }}
          size="small"
          label={SELECT_LOB}
          value={selectedLob || ''}
          onChange={handleLobChange}
          className=""
          margin="dense"
          variant="filled"
          SelectProps={{
            SelectDisplayProps: {
              'data-testid': 'lob-dropdown'
            },
            MenuProps: {
              container: this,
              className: 'lob-dropdown-menu'
            }
          }}
        >
          {lobList &&
            lobList.map(opt => (
              <MenuItem key={opt.label} value={opt.aliasToLOB} name={opt.label}>
                {opt.label}
              </MenuItem>
            ))}
        </TextField>
      </div>
      <div className="taxonomies-list-wrapper">
        <div className="list-container taxonomy-types-list">
          <div className="list-header">{TAXONOMY_TYPE_HEADER}</div>
          <List className="list-content">
            {taxonomiesTypes.length === 0 && selectedLob && (
              <div className="no-rules">{NO_TAXONOMY_TYPES}</div>
            )}
            {taxonomiesTypes.map(data => {
              return (
                <ListItem
                  key={data.taxonomyType}
                  className={
                    selectedTaxonomyType.taxonomyType === data.taxonomyType
                      ? 'taxonomy-type-item selected-taxonomy-type'
                      : 'taxonomy-type-item'
                  }
                >
                  <ListItemText
                    primary={`${data.taxonomyType} (${data.context})`}
                    onClick={() => changeTaxonomyType(data)}
                  />
                  <ListItemSecondaryAction>
                    <ArrowForwardIosIcon />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </div>
        <div className="list-container taxonomies-value-list">
          <div className="list-header">
            <div>{selectedTaxonomyType.taxonomyType}</div>
            {taxonomiesTypes.length > 0 && (
              <IconButton
                onClick={handleOpenAddNewBox}
                aria-label="Add taxonomy"
                size="large"
              >
                <AddIcon />
              </IconButton>
            )}
          </div>
          <List className="list-content">
            {taxonomies.length === 0 &&
              selectedTaxonomyType &&
              selectedTaxonomyType.taxonomyType !== '--' && (
                <div className="no-rules">{NO_TAXONOMIES}</div>
              )}
            {taxonomies.map(data => {
              return (
                <ListItem
                  key={data.key}
                  autoFocus={editedTaxonomy === data.key}
                >
                  <ListItemText primary={data.value} />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => handleOpenEditBox(data)}
                      aria-label={`Edit ${data.value}`}
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </div>
      </div>
      <ContentMetadataEditBox
        open={!!openEditBox}
        isAddNewBox={openEditBox === EDIT_BOX_TYPES.ADD_NEW_TAXONOMY}
        handleAddTaxonomy={handleAddTaxonomy}
        handleEditTaxonomy={handleEditTaxonomy}
        currentValue={initialValueForEditBox}
        closeEditBox={closeEditBox}
        selectedTaxonomyType={selectedTaxonomyType.taxonomyType}
      />
    </div>
  );
};

ContentMetadata.propTypes = {
  store: PropTypes.shape({
    LineOfBusiness: PropTypes.shape({
      getLineOfBusinessList: PropTypes.func.isRequired,
      lobList: PropTypes.arrayOf(
        PropTypes.shape({
          lineOfBussiness: PropTypes.string
        })
      )
    })
  })
};

export default inject('store')(observer(ContentMetadata));
