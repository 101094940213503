/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import './Dots.style.scss';

/**
 * Returning single dot, which is getting used inside Dots component
 * to generate all the dots
 */
const Dot = props => {
  const { active } = props;
  return (
    <div className={`caraousel-dot ${active ? 'caraousel-dot-active' : ''}`} />
  );
};

Dot.propTypes = {
  active: PropTypes.bool.isRequired
};

/**
 * Returning Dots for Caraousel component
 * @param {*} props
 */
const Dots = props => {
  const { slides, activeSlideIndex } = props;

  return (
    <div
      className="caraousel-dots-container"
      data-testid="caraousel-dots-container"
    >
      {slides.map((slide, index) => (
        <Dot key={slide.image} active={activeSlideIndex === index} />
      ))}
    </div>
  );
};

Dots.propTypes = {
  slides: PropTypes.arrayOf({
    image: PropTypes.string.isRequired
  }),
  activeSlideIndex: PropTypes.number.isRequired
};

export default Dots;
