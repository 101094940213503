/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Typography, Fab, Popper } from '@mui/material';
import { observer, inject } from 'mobx-react';
import './ForbiddenUserDetail.style.scss';
import { ForbiddenUserDetailPopper } from '../../components/Header/ForbiddenUserDetailPopper';
import { logout, getUserAvatar } from '../../utils';

export const userDetailMethods = (
  setOpen,
  setAnchorEl,
  setPlacement,
  placement,
  userId
) => {
  return {
    // on logout we are removing the PearsonSSOSession & reloading so that user can be re-directed to login page
    handleLogout: async () => {
      // deleting the cookie and reloading page
      logout(userId);
    },

    // handling toggling of popup
    handleToggle: newPlacement => event => {
      setAnchorEl(event.currentTarget);
      setOpen(prev => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    },

    // handling closing of menu on clicking anywhere outside the menu
    handleClose: () => {
      setOpen(false);
    }
  };
};

const ForbiddenUserDetail = props => {
  const {
    store: {
      UserInfo: { firstName, email, userId }
    }
  } = props;

  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const { handleLogout, handleToggle, handleClose } = userDetailMethods(
    setOpen,
    setAnchorEl,
    setPlacement,
    placement,
    userId
  );

  const firstCharOfUserName = firstName ? firstName[0].toUpperCase() : '';

  const popperDetail = {
    handleLogout,
    handleClose,
    open,
    firstName,
    email
  };

  return (
    <Fragment>
      <Fab
        variant="extended"
        color="primary"
        aria-label="menu"
        aria-haspopup="true"
        onClick={handleToggle('bottom-end')}
        className="avatar-user-details"
      >
        <Typography
          variant="h6"
          component="label"
          className="muiTypography-login"
          data-testid="forbidden-user-login"
        >
          {firstCharOfUserName || 'U'}
        </Typography>
        <Typography
          component="span"
          className="user-profile-img"
          style={getUserAvatar(email)}
        />
      </Fab>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <ForbiddenUserDetailPopper
            TransitionProps={TransitionProps}
            {...popperDetail}
          />
        )}
      </Popper>
    </Fragment>
  );
};

ForbiddenUserDetail.propTypes = {
  store: PropTypes.shape({
    UserInfo: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      isAuthorized: PropTypes.bool.isRequired,
      userId: PropTypes.string.isRequired,
      notification: PropTypes.bool.isRequired
    })
  })
};

export default inject('store')(observer(ForbiddenUserDetail));
