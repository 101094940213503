import React from 'react';
import PropTypes from 'prop-types';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { useSortable } from '@dnd-kit/sortable';
import { Checkbox, FormControlLabel } from '@mui/material';
import { CSS } from '@dnd-kit/utilities';
import './PreferencesDialog.style.scss';

const PreferencesDialogSortableItem = props => {
  const { option, updateTableColumnVisibility } = props;

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({
    id: option.id
  });

  const style = {
    position: 'relative',
    transform: CSS.Translate.toString(transform),
    transition: transition || undefined,
    zIndex: isDragging ? 1 : 0
  };

  return (
    <div
      className={`right-side-wrapper_columns-${option.id} column-wrapper ${
        isDragging ? 'table-columns-sortable-helper' : ''
      }`}
      ref={setNodeRef}
      style={style}
    >
      <FormControlLabel
        control={
          <Checkbox
            data-testid={option.label}
            value={option.id}
            checked={option.visible}
            color="primary"
            onClick={() =>
              updateTableColumnVisibility(option.id, option.visible)
            }
            disabled={option.mandatory}
            inputProps={{
              'aria-label': `table-view-${option.label}-checkbox`,
              'data-testid': `table-${option.id}-column-checkbox`
            }}
          />
        }
        label={option.label}
        key={option.id}
      />
      <DragHandleIcon className="drag-icon" {...attributes} {...listeners} />
    </div>
  );
};

PreferencesDialogSortableItem.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    mandatory: PropTypes.bool.isRequired
  }),
  updateTableColumnVisibility: PropTypes.func.isRequired
};

export default PreferencesDialogSortableItem;
