/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import {
  startUplaod,
  completeUpload,
  partUplaod
} from '../services/MultipartUpload';

// File upload in chunks for the videoFiles size more than 9MB
export const startFileUplaodInChunks = async file => {
  let s3VideoLocation = '';
  const startUploadPaarams = {
    fileName: file.name,
    fileType: file.type
  };

  // get a UploadId from S3 bucket to initaiate file upload
  const { uploadId, key } = await startUplaod(startUploadPaarams);

  // Split the file in chunks of 7MB
  const CHUNK_SIZE = 7000000; // 7MB
  const fileSize = file.size;
  const CHUNKS_COUNT = Math.floor(fileSize / CHUNK_SIZE) + 1;
  const promisesArray = [];
  let start;
  let end;
  let blob;

  // eslint-disable-next-line no-plusplus
  for (let index = 1; index < CHUNKS_COUNT + 1; index++) {
    start = (index - 1) * CHUNK_SIZE;
    end = index * CHUNK_SIZE;
    blob = index < CHUNKS_COUNT ? file.slice(start, end) : file.slice(start);

    const partsParams = {
      fileName: file.name,
      partNumber: index,
      uploadId,
      key
    };

    // initiate upload in parts
    const uploadPartsResp = partUplaod(blob, partsParams);

    promisesArray.push(uploadPartsResp);
  }

  // Resolve all Parts uplaod
  const resolvedPartsArray = await Promise.all(promisesArray);
  const uploadPartsArray = [];
  // create a array of uploaded parts Etag and number for completion
  resolvedPartsArray.forEach(resolvedPromise => {
    uploadPartsArray.push({
      ETag: resolvedPromise.data.ETag,
      PartNumber: resolvedPromise.data.partNumber
    });
  });

  const completeUplaodParamas = {
    fileName: file.name,
    uploadId,
    key
  };

  // CompleteMultipartUpload in the backend server
  const completeUploadResp = await completeUpload(
    completeUplaodParamas,
    uploadPartsArray
  );

  s3VideoLocation = completeUploadResp.Key;
  return s3VideoLocation;
};
