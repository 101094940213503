/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import TableModeConstants from '../constants/tableModeConstants';
import TableModeColumn from './TableModeColumn';

const tableModeColumns = [
  {
    id: TableModeConstants.COL_DETAILS_ID,
    label: TableModeConstants.COL_DETAILS_LABEL,
    visible: true,
    draggable: false
  },
  {
    id: TableModeConstants.COL_DISTRIBUTION_ID,
    label: TableModeConstants.COL_DISTRIBUTION_LABEL,
    sortOn: TableModeConstants.COL_DISTRIBUTION_SORT_ON_PROP,
    visible: true,
    draggable: false
  },
  {
    id: TableModeConstants.COL_COVER_IMAGE_ID,
    label: TableModeConstants.COL_COVER_IMAGE_LABEL,
    visible: true,
    draggable: false
  },
  {
    id: TableModeConstants.COL_AUTHOR_ID,
    label: TableModeConstants.COL_AUTHOR_LABEL,
    sortOn: TableModeConstants.COL_AUTHOR_ID,
    visible: true,
    draggable: false
  },
  {
    id: TableModeConstants.COL_TITLE_ID,
    label: TableModeConstants.COL_TITLE_LABEL,
    sortOn: TableModeConstants.COL_TITLE_ID,
    visible: true,
    draggable: false
  },
  {
    id: TableModeConstants.COL_PRODUCT_ID,
    label: TableModeConstants.COL_PRODUCT_LABEL,
    sortOn: TableModeConstants.COL_PRODUCT_ID,
    visible: true,
    draggable: false
  },
  {
    id: TableModeConstants.COL_URN_ID,
    label: TableModeConstants.COL_URN_LABEL,
    sortOn: TableModeConstants.COL_URN_ID,
    visible: true,
    draggable: false
  },
  {
    id: TableModeConstants.COL_USERS_ID,
    label: TableModeConstants.COL_USERS_LABEL,
    sortOn: TableModeConstants.COL_USERS_ID,
    visible: true,
    draggable: false
  },
  {
    id: TableModeConstants.COL_CREATED_ON_ID,
    label: TableModeConstants.COL_CREATED_ON_LABEL,
    sortOn: TableModeConstants.COL_CREATED_ON_ID,
    visible: true,
    draggable: false
  },
  {
    id: TableModeConstants.COL_SUBMITTED_BY_ID,
    label: TableModeConstants.COL_SUBMITTED_BY_LABEL,
    sortOn: TableModeConstants.COL_SUBMITTED_BY_ID,
    visible: true,
    draggable: false
  },
  {
    id: TableModeConstants.COL_SUBMITTED_ON_ID,
    label: TableModeConstants.COL_SUBMITTED_ON_LABEL,
    sortOn: TableModeConstants.COL_SUBMITTED_ON_SORT_ON_PROP,
    visible: true,
    draggable: false
  }
];

export default types
  .model('RequestAccessSettings', {
    tableModeColumns: types.optional(types.array(TableModeColumn), [
      ...tableModeColumns
    ]),
    columnToSort: types.maybeNull(types.string),
    sortDirection: TableModeConstants.DEFAULT_SORT_DIRECTION,
    serverSideSort: types.optional(types.array(types.string), [
      TableModeConstants.COL_AUTHOR_ID,
      TableModeConstants.COL_TITLE_ID
    ]),
    columnOrder: types.maybeNull(types.array(types.number))
  })
  .actions(self => ({
    getTableModeColumns() {
      return self.tableModeColumns;
    },
    setColumnToSort(value) {
      self.columnToSort = value;
    },
    setSortDirection(value) {
      self.sortDirection = value;
    },
    getSortCriteria() {
      return {
        columnToSort: self.columnToSort,
        sortDirection: self.sortDirection,
        serverSideSort: self.serverSideSort.includes(self.columnToSort)
      };
    },
    updateColumnOrder(value) {
      self.tableModeColumns = value;
    }
  }));
