/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { TextField, Button, FormControlLabel, Checkbox } from '@mui/material';
import { PeopleOutlined } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import {
  CustomDialog,
  DialogHeader,
  DialogContent,
  DialogActions
} from '../common/Dialog';
import { initiatePVSBulkUsers } from '../../services/bulkUserToProject';
import Constants from '../../constants';
import './PVSAddBulkUsers.style.scss';

const PVSADDBulkUsers = props => {
  const {
    store: {
      MessageDialog: { showErrorMessageDialog }
    }
  } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [entityUrns, setEntityUrns] = useState('');
  const [roleId, setRoleId] = useState('');
  const [addToAllProjects, setAddToAllProjects] = useState(false);
  const [showResponseDialog, setShowResponseDialog] = useState(false);
  const [entityUrnListError, setEntityUrnListError] = useState(false);
  const [successResults, setSuccessResults] = useState({});

  // const validationMessageForUserList = Constants.MAX_ALLOWED_USER_LENGTH(
  //   `${maxUserLength}`
  // );

  const openPVSAddBulkUserDialog = () => {
    setOpenDialog(true);
  };

  const closePVSAddBulkUserDialog = () => {
    setUserIds([]);
    setEntityUrns([]);
    setRoleId('');
    setAddToAllProjects(false);
    setOpenDialog(false);
    setShowResponseDialog(false);
  };

  const handleUserListChange = e => {
    const input = e.target.value;
    const fetchUsers = !isEmpty(input.trim()) ? input.split(',') : [];
    setUserIds(fetchUsers);
  };

  const handleEntityUrnsChange = e => {
    const urns = e.target.value;
    if (urns.length > 0) {
      setEntityUrnListError(false);
    }
    const fetchUrns = !isEmpty(urns.trim()) ? urns.split(',') : [];
    setEntityUrns(fetchUrns);
    fetchUrns.forEach(urn => {
      const trimedUrn = urn.trim();
      if (
        !trimedUrn.startsWith('urn:pearson:entity') ||
        !/^urn:pearson:entity:[a-z0-9-]+$/gi.test(trimedUrn)
      ) {
        setEntityUrnListError(true);
      }

      if (trimedUrn.length === 0) {
        setEntityUrnListError(false);
      }
    });
  };

  const handleAddToAllProjectsChange = () => {
    setAddToAllProjects(!addToAllProjects);
  };

  const handleRoleIdChange = e => {
    const newRoleId = e.target.value;
    setRoleId(newRoleId.trim());
  };

  const handleAdd = async () => {
    let inputEntityUrns = [];
    if (entityUrns.length !== 0) {
      inputEntityUrns = entityUrns
        .map(urn => urn.replace(/(\r\n|\n|\r|\s)/gm, '').trim())
        .filter(user => !isEmpty(user));
    }
    const inputUsers = userIds
      .map(user => user.replace(/(\r\n|\n|\r|\s)/gm, '').trim())
      .filter(user => !isEmpty(user));

    const response = await initiatePVSBulkUsers(
      inputEntityUrns,
      inputUsers,
      roleId,
      addToAllProjects
    );
    if (response.status === 400) {
      let details = '';
      let errorMessage = response.data.message;
      if (response.data?.details) {
        if (Array.isArray(response.data.details)) {
          details = response.data.details.join(', ');
        } else if (typeof response.data.details === 'object') {
          details = JSON.stringify(response.data.details);
        }
        errorMessage += ': ' + details;
      }
      showErrorMessageDialog(errorMessage, 'Unable to add user');
    } else {
      setShowResponseDialog(true);
      setSuccessResults(response.data.results);
    }
  };

  return (
    <>
      <button
        className="user-detail__user-manager"
        type="button"
        onClick={openPVSAddBulkUserDialog}
        data-testid="pvs-add-bulk-user-access-link"
      >
        <PeopleOutlined />
        {Constants.PVS_ADD_BULK_USERS}
      </button>
      {showResponseDialog ? (
        <CustomDialog
          onClose={closePVSAddBulkUserDialog}
          open={showResponseDialog}
          className="pvs-add-bulk-user-dialog"
          maxWidth={false}
          disableBackdropClick
        >
          <DialogHeader
            onClose={closePVSAddBulkUserDialog}
            className="pvs-add-bulk-user-title"
            title={Constants.PVS_ADD_BULK_USERS}
          />
          <DialogContent
            className="pvs-add-bulk-user-content"
            data-testid="pvsAddBulkUserDialogContent"
          >
            <pre>
              {`${Constants.PVS_ADD_BULK_USERS_SUCCESS}:\nmodified count: ${successResults?.modifiedCount || '0'}\nupserted count: ${successResults?.upsertedCount || '0'}`}
            </pre>
          </DialogContent>
          <DialogActions className="dialog-actions">
            <Button
              className="btnSave"
              onClick={closePVSAddBulkUserDialog}
              variant="contained"
              color="primary"
              aria-label="ok-pvs-add-bulk-user"
              data-testid="button-ok-pvs-add-bulk-user"
            >
              {Constants.OK}
            </Button>
          </DialogActions>
        </CustomDialog>
      ) : (
        <CustomDialog
          onClose={closePVSAddBulkUserDialog}
          open={openDialog}
          className="pvs-add-bulk-user-dialog"
          maxWidth={false}
          disableBackdropClick
        >
          <DialogHeader
            onClose={closePVSAddBulkUserDialog}
            className="pvs-add-bulk-user-title"
            title={Constants.PVS_ADD_BULK_USERS}
          />
          <DialogContent
            className="pvs-add-bulk-user-content"
            data-testid="userManagerDialogContent"
          >
            <form
              noValidate
              onSubmit={e => e.preventDefault()}
              autoComplete="off"
              data-testid="form"
            >
              <div className="pvs-add-bulk-users-input-details-container">
                <TextField
                  required
                  label="User List"
                  value={userIds}
                  id="userListField"
                  className="input-control user-list"
                  // helperText={validationMessageForUserList}
                  variant="filled"
                  multiline
                  inputProps={{
                    'aria-label': 'pvs-add-bulk-users-user-list'
                  }}
                  onChange={handleUserListChange}
                />
                <TextField
                  required
                  id="roleIdField"
                  className="input-control user-list"
                  label="RoleId"
                  variant="filled"
                  value={roleId}
                  onChange={handleRoleIdChange}
                  inputProps={{
                    'aria-label': 'pvs-add-bulk-users-add-role-id'
                  }}
                />
                <TextField
                  required
                  multiline
                  rows={7}
                  disabled={addToAllProjects}
                  error={entityUrnListError}
                  label={
                    entityUrnListError
                      ? 'Incorrect entity urn format'
                      : 'EntityURN'
                  }
                  id="entityUrnListField"
                  className="input-control"
                  value={entityUrns}
                  variant="filled"
                  inputProps={{
                    'aria-label': 'pvs-add-bulk-users-entityUrns-list'
                  }}
                  onChange={handleEntityUrnsChange}
                />
                <FormControlLabel
                  value="addToAllProjects"
                  control={
                    <Checkbox
                      required
                      onChange={handleAddToAllProjectsChange}
                      checked={addToAllProjects}
                      id="addToAllProjectsCheckBox"
                      inputProps={{
                        'aria-label': 'pvs-add-bulk-users-add-to-all-projects'
                      }}
                    />
                  }
                  label="Add to All Projects"
                  labelPlacement="start"
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions className="dialog-actions">
            <Button className="btnCancel" onClick={closePVSAddBulkUserDialog}>
              {Constants.CANCEL}
            </Button>
            <Button
              className="btnSave"
              onClick={handleAdd}
              variant="contained"
              color="primary"
              disabled={
                (entityUrns.length === 0 && !addToAllProjects) ||
                userIds.length === 0 ||
                roleId.length === 0 ||
                entityUrnListError
              }
              aria-label="add-pvs-bulk-users"
              data-testid="button-add-pvs-bulk-users"
            >
              {Constants.ADD}
            </Button>
          </DialogActions>
        </CustomDialog>
      )}
    </>
  );
};

PVSADDBulkUsers.propTypes = {
  store: PropTypes.shape({
    MessageDialog: PropTypes.shape({
      showErrorMessageDialog: PropTypes.func.isRequired
    })
  })
};

export default inject('store')(observer(PVSADDBulkUsers));
// export default PVSADDBulkUsers;
