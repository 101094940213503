/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import {
  Arrow1,
  Arrow2,
  Arrow3,
  Arrow4
} from '../../custom/icons/CustomArrows';

const arrows = {
  Arrow1,
  Arrow2,
  Arrow3,
  Arrow4
};

const parseSlidesData = slidesData => {
  const slides = {};
  slidesData.forEach(slide => {
    // eslint-disable-next-line no-param-reassign
    slide.slideObj = slide.slideObj || {};
    slides[slide.slideId] = { ...slide.slideObj, videoLink: slide.videoUrl };
  });
  return slides;
};

export const parseContent = (step, params) => {
  if (!step) {
    return {};
  }
  const content = step.content.replace(/%\w+%/g, all => params[all] || all);
  const arrowSVG = params.arrows[step.arrowSVG];
  return { ...step, ...{ content, arrowSVG } };
};

const config = (slidesData, userName, isAdmin, pageTourId = 'dashboard') => {
  const steps = parseSlidesData(slidesData);
  const params = { '%userName%': userName, arrows };
  const pageTours = {
    dashboard: {
      admin: [
        parseContent(steps.welcome_to_udb, params),
        parseContent(steps.different_viewing_mode, params),
        parseContent(steps.project_pagination, params),
        parseContent(steps.project_filter, params),
        parseContent(steps.create_new_project, params),
        parseContent(steps.user_detial_and_notifications, params)
      ],
      nonAdmin: [
        parseContent(steps.welcome_to_udb, params),
        parseContent(steps.different_viewing_mode, params),
        parseContent(steps.project_pagination, params),
        parseContent(steps.project_filter, params),
        parseContent(steps.request_project_access, params),
        parseContent(steps.user_detial_and_notifications, params)
      ]
    }
  };
  return isAdmin ? pageTours[pageTourId].admin : pageTours[pageTourId].nonAdmin;
};

export default config;
