/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import './ImageBox.style.scss';

const ImageBox = props => {
  const { draggable, onDragStart, onDragOver, onDrop, data, name = '' } = props;
  return (
    <div
      className="image-box-wrapper"
      draggable={draggable}
      onDragStart={onDragStart({ id: data.id })}
      onDragOver={onDragOver({ id: data.id })}
      onDrop={onDrop({ id: data.id })}
      data-testid="imageBox"
      aria-label={`${name}-image-box`}
    >
      <img className="image-style" src={data.url} alt="" />
    </div>
  );
};

ImageBox.propTypes = {
  draggable: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragOver: PropTypes.func,
  onDrop: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.string),
  name: PropTypes.string
};
export default ImageBox;
