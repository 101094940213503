/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import './VideoTutorial.style.scss';
import Constants from '../../constants';

const VideoDialog = props => {
  const { videoLink, handleClose, name = '' } = props;

  return (
    <div className="videoContent">
      <div className="video-content">
        <video
          controls
          disablePictureInPicture
          controlsList="nodownload"
          autoPlay
        >
          <source className="watchVideoLink" src={videoLink} type="video/mp4" />
          {Constants.VIDEO_TAG_NOT_SUPPORTED}
        </video>
        <div className="close">
          <IconButton
            className="close-button"
            aria-label={`${name}-video-close`}
            onClick={handleClose}
            data-testid="closeIcon"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

VideoDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  videoLink: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default VideoDialog;
