/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button, Switch } from '@mui/material';
import { cloneDeep } from 'lodash';
import Constants from '../../constants';
import './FeatureManagement.style.scss';

const FeatureManagement = props => {
  const {
    handleClose,
    store: {
      FeatureManager: { featureList, updateFeatureList }
    }
  } = props;

  const { SAVE } = Constants;

  const [featureData, setFeatureList] = useState(featureList);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const handleChange = (event, id) => {
    const featureListCopy = cloneDeep(featureData);
    const index = featureData.findIndex(t => t.featureId === id);
    featureListCopy[index].isEnabled = event.target.checked;

    setFeatureList(featureListCopy);

    const featureListAfterUpdate = featureListCopy;

    // comparing affiliationsBeforeUpdate and businessUnitDataAfterUpdate to enable Save button
    setIsSaveEnabled(
      JSON.stringify(featureList) !== JSON.stringify(featureListAfterUpdate)
    );
  };

  const handleSave = async () => {
    const finalDataTobeSaved = [];

    featureData.forEach(feature => {
      const data = featureList.find(
        originalFeature => feature.featureId === originalFeature.featureId
      );

      if (data.isEnabled !== feature.isEnabled) {
        finalDataTobeSaved.push({
          id: feature.featureId,
          isEnabled: feature.isEnabled
        });
      }
    });
    await updateFeatureList(finalDataTobeSaved, featureData);
    handleClose();
  };

  const renderFeatureList = () => {
    return (
      featureData.length > 0 &&
      featureData.map((item, index) => {
        const i = index + 1;
        const { label, isEnabled, featureId } = item;
        return (
          <div key={`option_${i}`} className="feature-management__feature">
            <span>{label}</span>
            <Switch
              className="feature-management__switch"
              checked={isEnabled}
              onChange={e => handleChange(e, featureId)}
              name="checkedB"
              inputProps={{
                'aria-label': 'primary checkbox',
                'data-testid': `test_${featureId}`
              }}
            />
          </div>
        );
      })
    );
  };

  return (
    <div>
      <div className="feature-management" data-testid="feature-management-area">
        {renderFeatureList()}
      </div>
      <div className="actions">
        <Button
          onClick={handleSave}
          className="btnSave"
          data-testid="btn-save"
          disabled={!isSaveEnabled}
        >
          {SAVE}
        </Button>
      </div>
    </div>
  );
};

FeatureManagement.propTypes = {
  handleClose: PropTypes.func.isRequired,
  store: PropTypes.shape({
    FeatureManager: {
      featureList: PropTypes.string.isRequired,
      updateFeatureList: PropTypes.func.isRequired
    }
  })
};

export default inject('store')(observer(FeatureManagement));
