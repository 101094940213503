/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import {
  getCypressRules,
  updateCypressRuleForLob
} from '../services/cypressRules';

export default types
  .model('CypressRules', {
    cypressRulesForLob: types.array(types.frozen())
  })
  .actions(self => ({
    async getCypressRulesForLob() {
      const rules = await getCypressRules();
      this.setCypressRulesForLob(rules);
    },
    setCypressRulesForLob(rules) {
      self.cypressRulesForLob = rules;
    },
    async updateCypressRulesForLob(rules, lob) {
      const response = await updateCypressRuleForLob(lob, rules);
      const updatedRules = [];

      self.cypressRulesForLob.forEach(data => {
        if (data.lobId === response.lobId) {
          updatedRules.push(response);
        } else {
          updatedRules.push(data);
        }
      });

      this.setCypressRulesForLob(updatedRules);
    }
  }));
