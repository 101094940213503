/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import { axiosFetch } from './axiosFetch';
import API_CONSTANTS from '../constants/apiConstants';
import { urlModifier, postHeaders } from '../utils';

export const addReleaseContent = async (
  image,
  releaseNumber,
  orderId,
  requestId
) => {
  const reqPayload = {
    image,
    releaseNumber,
    orderId,
    requestId
  };
  const apiPath = urlModifier(API_CONSTANTS.ADD_NEW_RELEASE_CONTENT_INFO);
  const reqOptions = {
    ...postHeaders(),
    body: reqPayload
  };

  const response = await axiosFetch(reqOptions, apiPath);
  return response.data;
};

export const saveGoLiveSettings = async (releaseNumber, startDate, endDate) => {
  const apiPath = `${urlModifier(
    API_CONSTANTS.SAVE_GO_LIVE_SEETINGS
  )}${releaseNumber}`;
  const reqPayload = {
    startDate,
    endDate,
    goLive: true
  };
  const reqOptions = {
    ...postHeaders(),
    body: reqPayload
  };
  const response = await axiosFetch(reqOptions, apiPath);
  return response.data;
};
