/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Link } from '@mui/material';
import { LockIcon } from '../../custom/icons/LockIcon';
import Header from '../Header/Header';
import Constants from '../../constants';
import './ForbiddenPage.style.scss';
import { replaceAllString } from '../../utils/utils';

const DeprecatedPage = () => {
  const {
    BROWSER_TITLE,
    DEPRECATED_HEADING,
    DEPRECATED_DESCRIPTION,
    DEPRECATED_CLICK_HERE
  } = Constants;

  const location = useLocation();

  const baseUrl = window.location.origin;
  const paceUrl = replaceAllString(baseUrl, {
    [Constants.STG_URL]: Constants.STG_PACE_URL,
    [Constants.PERF_URL]: Constants.PERF_PACE_URL,
    paf: Constants.PACEURL
  });

  const baseUrlDomain = new URL(baseUrl).hostname;
  const paceUrlDomain = new URL(paceUrl).hostname;

  const openPaceUrl = () => {
    const prevPath = location.state?.prevUrl?.pathname || '';
    const search = location.state?.prevUrl?.search || '';
    const redirectUrl = `${paceUrl}/#${prevPath}${search}`;
    window.location.href = redirectUrl;
  };

  return (
    <Fragment>
      <Helmet>
        <title>{BROWSER_TITLE}</title>
      </Helmet>
      <Header showUserDetail={false} />
      <div className="forbidden-page-wrapper">
        <div>
          <p className="depracated-heading" data-testid="welcome-text">
            {DEPRECATED_HEADING}
          </p>
          <p>{DEPRECATED_DESCRIPTION(baseUrlDomain, paceUrlDomain)}</p>
          <p>
            <Link onClick={openPaceUrl} className="pace-url">
              {DEPRECATED_CLICK_HERE}
            </Link>
          </p>
        </div>
        <div className="forbidden-lock-image">
          <LockIcon fontSize="large" />
        </div>
      </div>
    </Fragment>
  );
};

export default DeprecatedPage;
