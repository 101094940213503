/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */
/* eslint-disable no-param-reassign */

import { types } from 'mobx-state-tree';
import sortBy from 'lodash/sortBy';
import constants from '../constants';

export const Platform = types.model('Platform', {
  id: types.string,
  label: types.string,
  checked: types.boolean,
  visible: types.boolean
});

export const TableModeColumn = types.model('TableModeColumn', {
  id: types.string,
  label: types.string,
  visible: types.boolean,
  draggable: types.boolean,
  columnOrder: types.number,
  mandatory: types.boolean,
  sortOn: types.string
});

export default types
  .model('UserPreference', {
    filter: types.maybeNull(types.string),
    layout: types.maybeNull(types.string),
    platforms: types.maybeNull(types.array(types.string)),
    status: types.maybeNull(types.string),
    tableModeColumns: types.maybeNull(types.array(TableModeColumn))
  })
  .actions(self => ({
    setUserPreference(data, masterData) {
      self.layout = data.layout || constants.GRID_VIEW_MODE;
      self.filter = data.filter || 'default';
      self.status = data.status || 'default';

      if (data.platforms) {
        self.platforms = [];
        data.platforms.forEach(item => {
          self.platforms.push(item);
        });
      } else {
        self.platforms = ['default'];
      }

      if (data.tableModeColumns) {
        const columns = masterData.tableModeColumns.map(column => {
          const matchedColumn = data.tableModeColumns.find(
            col => col.id === column.id
          );
          return {
            ...column,
            visible: matchedColumn.visible,
            columnOrder: matchedColumn.columnOrder
          };
        });
        self.tableModeColumns = sortBy(columns, ['columnOrder']);
      }
    }
  }));
