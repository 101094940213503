/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import { types } from 'mobx-state-tree';

const ResourceAction = types.model('ResourceAction', {
  type: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  placement: types.maybeNull(types.string),
  target: types.maybeNull(types.string)
});

export default types.model('Resource', {
  heading: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  thumbnailUrl: types.maybeNull(types.string),
  videoUrl: types.maybeNull(types.string),
  resourceId: types.maybeNull(types.string),
  resourceType: types.maybeNull(types.string),
  webUrl: types.maybeNull(types.string),
  autoHideDrawer: types.maybeNull(types.boolean),
  webUrlTarget: types.maybeNull(types.string),
  action: types.array(ResourceAction)
});
