/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import { AppBar, Toolbar } from '@mui/material';
import PropTypes from 'prop-types';

import PearsonLogo from './PearsonLogo';
import ProjectTitle from '../../containers/Header/ProjectTitle';
import ProjectVersionTitle from '../../containers/Header/ProjectVersionTitle';
import UserDetailBtn from '../../containers/Header/UserDetailBtn';
import ForbiddenUserDetail from '../../containers/Header/ForbiddenUserDetail';
import './Header.style.scss';

const Header = props => {
  const {
    children,
    className = '',
    showReleaseVersion = false,
    isIconDisable,
    isUnAuthorized,
    hideUserDetail,
    appName,
    showUserDetail = true
  } = props;
  // if version number is not required in header and no custom css changes then apply no_version_header css
  const versionClassName =
    !showReleaseVersion && !className ? 'no_version_header' : className;

  const renderUserDetail = () => {
    if (hideUserDetail) {
      return null;
    }
    return isUnAuthorized ? <ForbiddenUserDetail /> : <UserDetailBtn />;
  };

  return (
    <AppBar
      data-testid="headeContainer"
      position="fixed"
      className={`appBar-Header  ${versionClassName}`}
    >
      <Toolbar className="toolbar-header">
        <PearsonLogo isIconDisable={isIconDisable} />
        <ProjectTitle appName={appName} />
        {showReleaseVersion && <ProjectVersionTitle />}
        {children}
        {showUserDetail && renderUserDetail()}
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  showReleaseVersion: PropTypes.bool.isRequired,
  isIconDisable: PropTypes.bool,
  isUnAuthorized: PropTypes.bool,
  hideUserDetail: PropTypes.bool,
  appName: PropTypes.string,
  showUserDetail: PropTypes.bool
};

export default Header;
