/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import VideoDialog from '../../containers/video-tutorial/VideoTutorial';
import Constants from '../../constants/index';

const WatchVideo = props => {
  const { videoLink, handleClose = null, name = '' } = props;
  const [open, setOpen] = useState(false);

  const handleVideoDialogOpen = () => {
    setOpen(true);
  };

  const handleVideoDialogClose = () => {
    setOpen(false);
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <div className="watch-video-action">
      <a
        className="watch-video-Link"
        role="link"
        target="_blank"
        onClick={handleVideoDialogOpen}
        onKeyDown={handleVideoDialogOpen}
        tabIndex={0}
        data-testid="videoOpenlink"
        aria-label={`${name}-watch-video-Link`}
      >
        ({Constants.WATCH_VIDEO})
      </a>
      {open && (
        <VideoDialog
          videoLink={videoLink}
          handleClose={handleVideoDialogClose}
          name={name}
        />
      )}
    </div>
  );
};

WatchVideo.propTypes = {
  videoLink: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  name: PropTypes.string.isRequired
};

export default WatchVideo;
