/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState } from 'react';
import { CustomDialog, DialogHeader, DialogContent } from '../common/Dialog';
import { UserManagerIcon } from '../../custom/icons/UserManagerIcon';
import Constants from '../../constants';
import SpecialPermissionUsers from './special-permissions/SpecialPermissionUsers';
import './UserManager.style.scss';

const UserManager = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const openUserManagerDialog = () => {
    setOpenDialog(true);
  };

  const closeUserManagerDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <button
        className="user-detail__user-manager"
        type="button"
        onClick={openUserManagerDialog}
      >
        <UserManagerIcon />
        {Constants.USER_MANAGER}
      </button>
      <CustomDialog
        onClose={closeUserManagerDialog}
        open={openDialog}
        className="user-manager-dialog"
        maxWidth={false}
        disableBackdropClick
      >
        <DialogHeader
          onClose={closeUserManagerDialog}
          className="user-manager-title"
          title={Constants.USER_MANAGER}
        />
        <DialogContent
          className="user-manager-content"
          data-testid="userManagerDialogContent"
        >
          <SpecialPermissionUsers closeDialog={closeUserManagerDialog} />
        </DialogContent>
      </CustomDialog>
    </>
  );
};

UserManager.propTypes = {};

export default UserManager;
