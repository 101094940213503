/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { getNTData } from '../services/getNTData';

export default types
  .model('NTData', {
    notifications: types.frozen(),
    tutorials: types.frozen(),
    getDataOnce: true,
    resetData: false
  })
  .actions(self => ({
    async getNTData() {
      const res = await getNTData();
      const { notifications, tutorials } = res;
      self.setNotifications(notifications);
      self.setTutorials(tutorials);
      self.setResetData(true);
    },
    setNotifications(val) {
      self.notifications = val;
    },
    setTutorials(val) {
      self.tutorials = val;
    },
    setGetDataOnce(val) {
      self.getDataOnce = val;
    },
    setResetData(val) {
      self.resetData = val;
    }
  }));
