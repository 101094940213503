/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import { axiosFetch } from './axiosFetch';
import { getHeaders } from '../utils';
import API_CONSTANTS from '../constants/apiConstants';

export const checkPartnerHealth = async checkForC4sm => {
  const apiPath = checkForC4sm
    ? API_CONSTANTS.CHECK_C4SM_HEALTH
    : API_CONSTANTS.CHECK_PARTNER_HEALTH;

  const reqOptions = {
    ...getHeaders()
  };

  // handling Gateway timeout and Service unavailable errors
  const errorHandlingProps = {
    skipError: true,
    codes: [503, 504]
  };

  // adding Timestamp in params so that result is not cached
  const timestamp = new Date().toISOString();

  const response = await axiosFetch(
    reqOptions,
    apiPath,
    { timestamp },
    errorHandlingProps
  );

  return {
    isServiceDown: response.status === 503 || response.status === 504,
    info: response.data.message || ''
  };
};
