/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { getFavoriteProjects } from '../services/getFavoriteProjects';

export default types
  .model('FavoriteProjects', {
    favoriteEntities: types.array(types.maybeNull(types.string)),
    isFavouriteLoaded: false
  })
  .actions(self => ({
    updateFavouriteLoaded(value) {
      self.isFavouriteLoaded = value;
    },
    /**
     * @function - Getting favorite entity urns
     */
    async getFavoriteEntityUrns() {
      const favoriteData = await getFavoriteProjects();
      this.resetFavoriteEntities();
      this.setFavoriteEntityUrnsData(favoriteData);
      this.updateFavouriteLoaded(true);
    },

    isProjectFavourite(entityUrn) {
      return self.favoriteEntities.indexOf(entityUrn) >= 0;
    },

    /**
     * @function - Saving favorite project entity urns in self object
     * @param {config}
     */
    setFavoriteEntityUrnsData(favoriteData) {
      favoriteData.forEach(item => {
        self.favoriteEntities.push(item);
      });
    },

    /**
     * Adding/Removing entity urn based on toggle favorite icon.
     * If selectedProjectEntityUrn doesn't exist in favorite entity array, specific entity urn will add up.
     * If selectedProjectEntityUrn exist in favorite entity array, specific entitty urn will remove from array.
     */

    updateFavorites(selectedProjectEntityUrn) {
      const index = self.favoriteEntities.indexOf(selectedProjectEntityUrn);
      if (index >= 0) {
        // if entity urn is in the a);y and will remove from array.
        self.favoriteEntities.splice(index, 1);
      } else {
        // if entity urn is not in the array and will add.
        self.favoriteEntities.push(selectedProjectEntityUrn);
      }
    },

    resetFavoriteEntities() {
      self.favoriteEntities = [];
    }
  }));
