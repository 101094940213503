/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import moment from 'moment';
import Constants from '../constants/preflightToolKit';

export const formateDate = (date = new Date()) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  const yy = date.getFullYear().toString().substring(2, 4);
  dd = dd < 10 ? `0${dd}` : dd;
  mm = mm < 10 ? `0${mm}` : mm;
  return `${mm}${dd}${yy}`;
};

/**
 * Format table headers
 * If the table header is something like [ "error_id", "chapter"],
 * this should return, ["Error Id", "Chapter"]
 *
 * @param {*} headers
 */
const formatTableHeader = headers => {
  const newHeader = [];
  // eslint-disable-next-line array-callback-return
  headers.map(header => {
    const id = header;
    const label = header
      .split('_')
      .map(value => value.charAt(0).toUpperCase() + value.slice(1))
      .join(' ');
    // Addidng visibility and mandatory property
    const visible = true;
    let mandatory = false;
    if (id === 'chapter' || id === 'severity') {
      mandatory = true;
    }
    newHeader.push({ id, label, visible, mandatory });
  });
  return newHeader;
};

/**
 * Format Report in such a way so that making table out of it will be easy
 * @param {*} preflightReport
 */
export const formatReportResult = preflightResponse => {
  const { preflightReport, preflightStatus } = preflightResponse;
  // if no report available, the return empty object
  if (!preflightReport) {
    return {
      preflightStatus
    };
  }

  const { status: reportErrorStatus, productModel } = preflightReport;
  const tableData = { headers: [], body: [] };
  if (preflightReport.preflight.errors.length > 0) {
    tableData.headers = formatTableHeader(
      Object.keys(preflightReport.preflight.errors[0])
    );
    tableData.body = preflightReport.preflight.errors;
  }

  return {
    preflightStatus,
    reportErrorStatus,
    tableData,
    productModel,
    totalReportedErrors: preflightReport.preflight.errorCnt,
    severity: preflightReport.preflight && preflightReport.preflight.severity
  };
};

export const dateFormater = (timeStamp, format = 'lll') => {
  return timeStamp ? moment.unix(timeStamp).format(format) : '';
};

/**
 * parse date time from string and returns Moment obj
 * default format is "MM/DD/YY h:mm:ssa"
 * Ex. 03/25/22 9:55:34am
 * @param {*} dateString
 * @param {*} format
 * @returns Moment object
 */
export const parseDateTimeInPreflight = (
  dateString,
  format = Constants.DEFAULT_PREFLIGHT_RESPONSE_DATE_FORMAT
) => {
  return moment(dateString, format, true);
};

/**
 * parse date time in UTC timezone from string and returns date time
 * in local timezone
 * default format is "MM/DD/YY h:mm:ssa"
 * Ex. 03/25/22 9:55:34am
 * and default format of local datetime is "MMMM Do YYYY, h:mm:ss a"
 * Ex. March 25th 2022, 3:25:34 pm
 * @param {string} dateString
 * @param {string} format
 * @param {string} displayFormat
 * @returns
 */
export const convertToLocalTime = (
  dateString,
  format = Constants.DEFAULT_PREFLIGHT_RESPONSE_DATE_FORMAT,
  displayFormat = Constants.REPORTS_DROPDOWN_DATE_FORMAT
) => {
  const momentObj = moment.utc(dateString, format, true);
  const localTime = momentObj.local();
  return localTime.format(displayFormat);
};

export const convertDateAndTime = (
  dateString,
  displayFormat = Constants.REPORTS_DROPDOWN_DATE_FORMAT
) => {
  const momentObj = moment.utc(dateString).toDate();
  const localTime = moment(momentObj);
  return localTime.format(displayFormat);
};

/* Function to check whether filter passed in query param is valid or not */
export const isQueryFilterValid = filter => {
  if (filter === null) {
    return false;
  }
  const filterToValidate = filter.split('_').join(' ').toUpperCase();
  const validFilters = [
    Constants.PREFLIGHT_PROV_MUST_FIX_FILTER.toUpperCase(),
    Constants.PREFLIGHT_MUST_FIX_FILTER.toUpperCase(),
    Constants.PREFLIGHT_SHOULD_FIX_FILTER.toUpperCase(),
    Constants.PREFLIGHT_NEED_ANALYSIS_FILTER.toUpperCase(),
    Constants.PREFLIGHT_BETA_UAT_FILTER.toUpperCase()
  ];
  const isValid = validFilters.includes(filterToValidate);
  return isValid && filterToValidate;
};

export const getUpdateApproxEndTime = (startedOnISOString, minutesToCheck) => {
  const timer = minutesToCheck || 5;
  // we assume that update will be completed in 5 minutes approx
  const currentTime = moment();
  let momentObj = currentTime;
  if (startedOnISOString) {
    momentObj = moment(startedOnISOString);
  }
  const minutesSinceUpdateStarted = currentTime.diff(momentObj, 'minutes');
  return [
    momentObj.add(timer, 'minutes').format('hh:mma'),
    minutesSinceUpdateStarted >= timer
  ];
};

export const getTimeLabel = date => {
  let label = 'a moment';
  if (!date) {
    return label;
  }
  const currentDate = new Date();
  const seconds = Math.floor((currentDate - date) / 1000);

  // check if last refreshed hours ago
  let interval = Math.floor(seconds / 3600);
  if (interval === 1) {
    label = '1 hour';
  } else if (interval > 1) {
    label = interval + ' hours';
  } else {
    // check if last refreshed minutes ago
    interval = Math.floor(seconds / 60);
    if (interval === 1) {
      label = '1 minute';
    } else if (interval > 1) {
      label = interval + ' minutes';
    }
  }
  return label;
};
