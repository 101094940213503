/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Tabs, Tab } from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import PropTypes from 'prop-types';
import {
  CustomDialog,
  DialogHeader,
  DialogContent
} from '../../components/common/Dialog';
import Constants from '../../constants';
import featureConstants from '../../constants/featureConstants';
import LobMetaManager from './LobMetaManager';
import CypressRulesAndWorkFlow from './CypressRulesAndWorkFlow';
import ContentMetadata from './ContentMetadata';
import { getAllLobMeta } from '../../services/lobMeta';
import './LobManagerTabs.style.scss';

const TABS_KEY = {
  CYPRESS_RULES: 'cypressRules',
  WORKFLOW_RULES: 'workflowRules',
  METALINK: 'projectMetalink',
  METADATA: 'projectMetadata',
  CONTENT_METADATA: 'projectContentMetadata'
  // THEMES: 'themes'
};

const createTabItem = (key, getComponent, label, testId) => {
  return {
    key,
    getComponent,
    label,
    testId
  };
};

const LobManagerTabs = props => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  const {
    store: {
      LineOfBusiness: { getLineOfBusinessList, lobList },
      CypressRules: { getCypressRulesForLob, cypressRulesForLob },
      LobWorkflow: { getWorkFlowRolesForLob, workFlowRolesForLob },
      LobMeta: { setLobMeta },
      FeatureManager: { isFeatureEnabled }
      // ThemeData: { themeList, setThemeList, getThemesForLob, themesForLob }
    }
  } = props;

  const shouldDisplayWorkFlowRoles = isFeatureEnabled(
    featureConstants.CYPRESS_WORKFLOW_ROLES
  );
  const shouldDisplayContentMetadata = isFeatureEnabled(
    featureConstants.SHOW_CONTENT_METADATA_TAB
  );

  useEffect(() => {
    const fetchLobManagerList = async () => {
      if (!openDialog) {
        return;
      }
      // if (!themeList.length) {
      //   const themesList = await getThemeList();
      //   setThemeList(themesList);
      // }
      const response = await Promise.all([
        getAllLobMeta(),
        !lobList.length && getLineOfBusinessList(),
        getCypressRulesForLob(),
        shouldDisplayWorkFlowRoles && getWorkFlowRolesForLob()
        /* Theme Tab would be enabled in future release */
        // !themesForLob.length && getThemesForLob()
      ]);
      setLobMeta(response[0]);
    };
    fetchLobManagerList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const openLobManagerDialog = () => {
    setOpenDialog(true);
  };

  const closeLobManagerDialog = () => {
    setSelectedTab(0);
    setOpenDialog(false);
  };

  const tabs = [
    createTabItem(
      TABS_KEY.CYPRESS_RULES,
      () => {
        return (
          !!cypressRulesForLob.length && (
            <CypressRulesAndWorkFlow
              closeLobManagerDialog={closeLobManagerDialog}
              selectedTab={0}
            />
          )
        );
      },
      Constants.CYPRESS_RULES,
      'cypress-rules'
    ),
    ...(shouldDisplayWorkFlowRoles
      ? [
          createTabItem(
            TABS_KEY.WORKFLOW_RULES,
            () => {
              return (
                !!workFlowRolesForLob.length && (
                  <CypressRulesAndWorkFlow
                    closeLobManagerDialog={closeLobManagerDialog}
                    selectedTab={1}
                  />
                )
              );
            },
            Constants.WORK_FLOW_ROLES,
            'workflow-roles'
          )
        ]
      : []),
    createTabItem(
      TABS_KEY.METADATA,
      () => {
        return (
          <LobMetaManager
            type={Constants.LOB_META_TYPE_METADATA}
            closeDialog={closeLobManagerDialog}
          />
        );
      },
      Constants.PROJECT_METADATA,
      'project-metadata'
    ),
    createTabItem(
      TABS_KEY.METALINK,
      () => {
        return (
          <LobMetaManager
            type={Constants.LOB_META_TYPE_METALINK}
            closeDialog={closeLobManagerDialog}
          />
        );
      },
      Constants.PROJECT_METALINK,
      'project-metalink'
    ),
    ...(shouldDisplayContentMetadata
      ? [
          createTabItem(
            TABS_KEY.CONTENT_METADATA,
            () => {
              return <ContentMetadata />;
            },
            Constants.PROJECT_CONTENT_METADATA,
            'project-content-metadata'
          )
        ]
      : [])
    // createTabItem(
    //   TABS_KEY.THEMES,
    //   () => {
    //     return <Themes />;
    //   },
    //   Constants.THEMES,
    //   'themes'
    // )
  ];

  const renderTabModules = () => {
    return tabs[selectedTab].getComponent();
  };

  const body = (
    <div>
      <div className="header">
        <Tabs value={selectedTab} onChange={handleChange}>
          {tabs.map(tab => (
            <Tab key={tab.key} data-testid={tab.testId} label={tab.label} />
          ))}
        </Tabs>
      </div>
      {renderTabModules()}
    </div>
  );
  return (
    <>
      <div className="lob-manager-wrapper">
        <button
          className="lob-manager-btn"
          type="button"
          onClick={openLobManagerDialog}
          data-testid="lob-manager-btn"
        >
          <BuildIcon />
          {Constants.LOB_MANAGER}
        </button>
      </div>
      <CustomDialog
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            closeLobManagerDialog();
          }
        }}
        open={openDialog}
        className="lob-manager-dialog"
        maxWidth={false}
        disableBackdropClick
      >
        <DialogHeader
          onClose={closeLobManagerDialog}
          title={Constants.LOB_MANAGER}
        />
        <DialogContent
          className="lob-manager-content"
          data-testid="lobWorkFlowDialogContent"
        >
          {body}
        </DialogContent>
      </CustomDialog>
    </>
  );
};

LobManagerTabs.propTypes = {
  store: PropTypes.shape({
    LineOfBusiness: PropTypes.shape({
      lobList: PropTypes.arrayOf(
        PropTypes.shape({
          lineOfBussiness: PropTypes.string
        })
      ),
      getLineOfBusinessList: PropTypes.func
    }),
    // ThemeData: PropTypes.shape({
    //   themeList: PropTypes.arrayOf(PropTypes.object),
    //   setThemeList: PropTypes.func,
    //   getThemesForLob: PropTypes.func,
    //   themesForLob: PropTypes.arrayOf(PropTypes.object)
    // }),
    CypressRules: PropTypes.shape({
      getCypressRulesForLob: PropTypes.func,
      cypressRulesForLob: PropTypes.arrayOf(
        PropTypes.shape({
          lobId: PropTypes.string,
          elementPermissions: PropTypes.objectOf(PropTypes.string)
        })
      )
    }),
    LobWorkflow: PropTypes.shape({
      workFlowRolesForLob: PropTypes.arrayOf(
        PropTypes.shape({ lobId: PropTypes.string })
      ),
      getWorkFlowRolesForLob: PropTypes.func
    }),
    FeatureManager: PropTypes.shape({
      isFeatureEnabled: PropTypes.func.isRequired
    }),
    LobMeta: PropTypes.shape({
      setLobMeta: PropTypes.func.isRequired
    })
  })
};

export default inject('store')(observer(LobManagerTabs));
