/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';

import {
  getWorkFlowRoles,
  updateWorkFlowRoleForLob
} from '../services/workFlowRoles';

export default types
  .model('LobWorkflow', {
    workFlowRolesForLob: types.array(types.frozen())
  })
  .actions(self => ({
    async getWorkFlowRolesForLob() {
      const workflowRoles = await getWorkFlowRoles();
      this.setWorkFlowRolesForLob(workflowRoles);
    },
    setWorkFlowRolesForLob(workflowRoles) {
      self.workFlowRolesForLob = workflowRoles;
    },
    async updateWorkFlowRolesForLob(workflowRoles, lob) {
      const response = await updateWorkFlowRoleForLob(lob, workflowRoles);
      const updatedRoles = [];
      self.workFlowRolesForLob.forEach(data => {
        if (data.lobId === lob) {
          updatedRoles.push({
            lobId: lob,
            workflow: response
          });
        } else {
          updatedRoles.push(data);
        }
      });

      this.setWorkFlowRolesForLob(updatedRoles);
    }
  }));
