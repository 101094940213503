/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

export default {
  USER_ACCOUNTS: 'user_accounts',
  PREFERENCES: 'preferences',
  CREATE_DUPLICATE: 'create_duplicate',
  ADVANCED_SETTINGS: 'advanced_setting',
  CREATE_PROJECT: 'create_project',
  EPUB: 'epub',
  PEER_REVIEW: 'peer_review',
  IMPORT_OPTION: 'import_option',
  HIDE_PROJECT: 'hide_project',
  STATISTICS_OPTION: 'statistics_option',
  GLOSSARY_OPTION: 'glossary_option',
  INDESIGN_EXPORT: 'indesign_export',
  PREFLIGHT_TOOLKIT: 'preflight_toolkit',
  CYPRESS_AUTHORING: 'cypress_authoring',
  POD_OUTPUT: 'pod_output',
  SUBSCRIPTION: 'subscription',
  CYPRESS_PLUS: 'cypress_plus',
  PROJECT_CATEGORY_FILTER: 'project_category_filter',
  CYPRESS_WORKFLOW_ROLES: 'cypress_workflow_roles',
  AUTO_NUMBERING: 'auto_numbering_visible',
  AUTO_NUMBERING_ENABLED_ON_PROJECT_CREATION:
    'auto_numbering_flag_enabled_during_project_creation',
  AUTO_NUMBERING_VISIBLE_ON_PROJECT_CREATION:
    'auto_numbering_toggle_visible_during_project_creation',
  LEARNOSITY_SELECTION_ON_PROJECT_CREATION:
    'learnosity_selection_on_project_creation',
  AUTO_NUMBERING_TOGGLE_VISIBLE_ON_SETTINGS_DRAWER:
    'auto_numbering_toggle_visible_on_settings_drawer',
  REVEL_AUDIO_FLAG_OVERRIDE: 'revel_audio_flag_override',
  LOB_FILTER: 'lob_filter',
  ADD_ADDITIONAL_WILLOW_FRAMEWORK: 'add_additional_willow_framework',
  ENABLE_PRODUCT_MODEL_SELECTION_FOR_PREFLIGHT:
    'enable_product_model_selection_for_preflight',
  DELETE_ASSOCIATED_WILLOW_FRAMEWORK: 'delete_associated_willow_framework',
  DISTRIBUTION_VIA_UDB_FOR_GPS: 'distribution_via_udb_for_gps',
  DISTRIBUTION_VIA_UDB_FOR_CITE: 'distribution_via_udb_for_cite',
  DISTRIBUTION_VIA_UDB_FOR_PCX: 'distribution_via_udb_for_pcx',
  DISTRIBUTION_VIA_UDB_FOR_PLP: 'distribution_via_udb_for_plp',
  DISTRIBUTION_FOR_PRIZM: 'distribution_for_prizm',
  ENABLE_POD_DOWNLOAD: 'enable_pod_download',
  CYPRESS_PLUS_NAVIGATION: 'cypress_plus_navigation',
  SHOW_CONTENT_METADATA_TAB: 'show_content_meta_tab_in_lob_manager',
  VALIDATE_OWNERS_TRACK_CHANGES_FOR_SUBSCRIBER:
    'validate_owners_track_changes_for_subscriber',
  SHOW_ASSESSMENTS_AND_INTERACTIVES_TAB:
    'show_assessments_and_interactives_tab',
  SHOW_ELM_PENDING_APPROVALS_SECTION: 'show_elm_pending_approvals_section',
  SHOW_CYPRESS_PENDING_UPDATES_SECTION: 'show_cypress_pending_updates_section',
  SHOW_UPDATE_ALL_ASSESSMENT: 'show_update_all_assessment',
  ALLOW_RC_ASSESSMENTS_UPDATE: 'allow_rc_assessments_update',
  SHOW_UPDATE_ALL_IN_CYPRESS_BUTTON: 'show_update_all_in_cypress_button',
  SHOW_EPUB_ACCESSIBILITY_TAB: 'show_epub_accessibility_tab',
  DOWNLOAD_EPUB_ACCESSIBILITY_REPORT: 'download_epub_accessibility_report',
  ALLOW_EDITING_OF_TCC_PROJECTS: 'allow_editing_of_tcc_projects',
  SHOW_FILTER_FOR_TCC_PROJECTS: 'show_filter_for_tcc_projects',
  ALLOW_EDIT_WITH_ELM_FOR_TCC_PROJECTS: 'allow_edit_with_elm_for_tcc_projects',
  HIDE_OFFICIAL_STANDARDS_FROM_LEARNING_FRAMEWORKS:
    'hide_official_standards_from_learning_frameworks',
  ENABLE_UPDF_EXPORT: 'enable_updf_export',
  DISTRIBUTION_FOR_BAU: 'distribution_for_bau',
  DISTRIBUTION_FOR_GPS: 'distribution_for_gps',
  DISTRIBUTION_FOR_PLP: 'distribution_for_plp',
  DISTRIBUTION_FOR_VIRTUAL_SCHOOLS: 'distribution_for_virtual_schools',
  ENABLE_AI_LINKS: 'enable_ai_links'
};
