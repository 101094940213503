/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './PearsonLogo.style.scss';

const PearsonLogo = props => {
  const { isIconDisable } = props;
  const className = isIconDisable ? 'disabled-link' : '';
  return (
    <Link to="/" className={className} aria-label="pearson-logo">
      <img
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAD6ElEQVR42q1Xa0hUURA+lvawhxWVBdWPIsg/UgQR1b9C6EF/oj/Rvx4ERQ8QTMHdY0/LlDTRjMq6967V2svKUjTDrHysj55maatCSVK+V9fX7jTn7u7t7u5d92w2MFy4c858M3PmzMwhhId2GicS/c1IohNiCRWfEL34BbkH2Y7cj/9a8PsSv4lEL0URmhVKxkXUOAkV7UaF1U4Q4OR23HeOUGFJ4KB6wxpUYAoATIt7CZV0hGZP4UCEINxwCNk6TlA1l5G4nHA/oEJCgGHl5Xr0fIE2rk484gt0ApVgxaU8OPC4ElIrPsPtDy2Q+6kVDO/NkPT6E+zNK4elFx9AEB0LXCqS88aN4qX1KBz0XBx6Kgf2oNLqtg4YtdlhLBq22aC0pR023Sz2Da4TDqqyF9OfSh89F0UJxWDu7FMU2+126BgYgho0osT8Ewqb2uBF809o7Oh1M8qG69IrGyDkhEEL/AeJFqY5M1g4rBaycB19ZoIRp7JO6xCkvKmHVZn5EJwgaXqyLPUhpFU0wODIqGLA9dom+Yg0Qr7LeVfFb2rBjjulMIpWM9h79a2wKPkedxJtkUrAMjSieL7NUKKxTjBgQkkbPQXJ6B3zNrqwBiZ6WByZ8QQOPzXJEfFlUHzJW8VrZrhmhuPZnvEUsLOZe87otWExAg2P2hSlGVVfNIGXY9hZxBi1dlsgJMHjrKk4gOcrlvKGMSL9kVsWX6n+qrluYdJdjJjDwB+9AzD5ZI73OmeB5wJmoVXTZZM28FY8VxfV4g0I8k6wESK7zQkcnpTrBpxp8g41Cyu7ai6KK67z1sWcDaTshZ/PBXUJSSz76CYPO3MbxHdmRc5qwKyzd7R0PSdyP+UEns+AVcix6A27p0tS7sOxgmo5kVzUbrHC6sv5PqqXGM+AzbzAcxKNSlFh1NJlgeauPrCqigaTslAvT8vzpQd7gbQ2oKyegaFUgzjKqKNGm9GAq7WNsPFGkdfd9ygen8n+rBDiGFf4gKdiw3BVJUYH86vQs4fyf+5c0QnHXK1wKzcw3seewWEFeMO1gkB7chs5bpj9tzPJM5L/jew+Ztd9g4ZfPXJZ9apI/phKRz2GOuEC72bWuaafvuWj64zJJu8hgE2DVOzmUXC8qFau16wXB/N73EVOChG+xp4YHiVV338r14aNOhx7rOjUdt9zXhamuF4q8Kdow7VCbA6NsO9ROQ9oPzq0w/9kG2MMw8Wv/s9UKTWjp+sCeD1IM3FTNm62/SPoEBaJK4TmzP2HpwudgJPJZjTiDfeMTUULfkVCDSvJuIk91pgiKlK5o7B25gBgY/Av5DrkLPl9FXt9XiCq/wCvBAwBX1+BUwAAAABJRU5ErkJggg=="
        className="pearson-logo"
        alt="Pearson"
      />
    </Link>
  );
};

PearsonLogo.propTypes = {
  isIconDisable: PropTypes.bool
};

export default PearsonLogo;
