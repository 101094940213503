/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */

import { types } from 'mobx-state-tree';
import { getNotifications } from '../services/getNotifications';
import { checkNotificationAlert } from '../services/checkNotificationAlert';
import { removeUserNotificationAlert } from '../services/removeUserNotificationAlert';
import Resource from './Resource';

export default types
  .model('NotificationDrawer', {
    hasNotificationAlert: false,
    isNotificationDrawerOpen: false,
    isLoaded: false,
    notifications: types.array(Resource),
    tutorials: types.array(Resource)
  })
  .actions(self => ({
    async fetchResources() {
      const result = await getNotifications();
      self.updateResouces(result);
    },
    async checkNotificationAlert() {
      const { hasNotificationAlert } = await checkNotificationAlert();
      self.updateNotificationAlert(hasNotificationAlert === 'true');
    },
    updateNotificationAlert(value) {
      self.hasNotificationAlert = value;
    },
    async removeNotificationAlert() {
      await removeUserNotificationAlert();
      self.updateNotificationAlert(false);
    },
    updateResouces(result) {
      self.notifications = result.notifications || [];
      self.tutorials = result.tutorials || [];
      self.isLoaded = true;
    },
    openNotificationDrawer() {
      self.isNotificationDrawerOpen = true;
    },
    closeNotificationDrawer() {
      self.isNotificationDrawerOpen = false;
    }
  }));
