/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { isEmpty } from 'lodash';
import PublishPlatformOption from './PublishPlatformOption';
import {
  getDistributionOptions as DistributionConstants,
  statusOptions
} from '../constants/projectStatusAndPlatformConstants';
import Constants from '../constants';

export default types
  .model('publishPlatformOptions', {
    publishPlatformOptions: types.optional(types.array(PublishPlatformOption), [
      {
        id: DistributionConstants.COL_ETEXT_BAU_ID,
        label: DistributionConstants.COL_ETEXT_BAU_LABEL,
        checked: true,
        visible: true
      },
      {
        id: DistributionConstants.COL_ILP_REVEL_ID,
        label: DistributionConstants.COL_ILP_REVEL_LABEL,
        checked: true,
        visible: true
      },
      {
        id: DistributionConstants.COL_PLP_REVEL_ID,
        label: DistributionConstants.COL_PLP_REVEL_LABEL,
        checked: true,
        visible: true
      },
      {
        id: DistributionConstants.COL_PLP_OZ_ID,
        label: DistributionConstants.COL_PLP_OZ_LABEL,
        checked: true,
        visible: true
      },
      {
        id: DistributionConstants.COL_GPS_ID,
        label: DistributionConstants.COL_GPS_LABEL,
        checked: true,
        visible: true
      },
      {
        id: DistributionConstants.COL_VIRTUALSCHOOLS_ID,
        label: DistributionConstants.COL_VIRTUALSCHOOLS_LABEL,
        checked: true,
        visible: true
      }
    ]),
    // Needed this property to rerender components
    selectionChangeCount: 0
  })
  .actions(self => ({
    getPublishPlatformOptions() {
      return self.publishPlatformOptions;
    },
    updateColumnVisibility(id, value) {
      const column = self.publishPlatformOptions.find(el => el.id === id);
      column.setSelection(value);
    },
    getShowAllValue(publishStatus) {
      self.selectionChangeCount += 1;
      if (
        publishStatus &&
        (publishStatus === statusOptions.IN_REVIEW ||
          publishStatus === statusOptions.PUBLISHED_LIVE)
      ) {
        let length = 0;
        self.publishPlatformOptions.forEach(column => {
          if (column.checked && column.visible) {
            length += 1;
          }
        });
        // here we are skipping GPS option for In review and publish live statusses
        // in case of In reveiw and publish Live check if the length of selected value is one two(for GPS & virtual schools) then equal to length of all Options then return true
        if (length === self.publishPlatformOptions.length - 2) {
          return true;
        }
        return false;
      }
      return self.publishPlatformOptions.every(option => option.checked);
    },
    updateAllColumnsVisibility(value) {
      self.publishPlatformOptions.forEach(column => {
        column.setSelection(value);
      });
    },
    getAllcheckedOptions(publishStatus) {
      const checkedOption = [];
      // retrive all selcted options and in newArray
      self.publishPlatformOptions.forEach(column => {
        if (column.checked && column.visible) {
          checkedOption.push(column.label);
        }
      });
      // check if the length of all Options is equal to length of new checkedOption Array if equal return default
      // in case of In reveiw and publish Live check if the length of new checkedOption Array is one two(for GPS & virtual schools) then equal to length of all Options then return default
      if (
        checkedOption.length === self.publishPlatformOptions.length ||
        (publishStatus &&
          (publishStatus === statusOptions.IN_REVIEW ||
            publishStatus === statusOptions.PUBLISHED_LIVE) &&
          checkedOption.length === self.publishPlatformOptions.length - 2)
      ) {
        return Constants.STATUS_FILTER_DEFAULT;
      }
      if (isEmpty(checkedOption)) {
        return Constants.STATUS_FILTER_NONE;
      }
      return checkedOption.join(',');
    },
    updateColumnVisibilityByUserPreference(value, publishStatus) {
      self.updatePlatformOptionVisibility(publishStatus);
      if (
        value &&
        value !== Constants.STATUS_FILTER_DEFAULT &&
        value !== Constants.STATUS_FILTER_NONE
      ) {
        const valArr = value.split(',');
        self.publishPlatformOptions.forEach(column => {
          const platform = valArr.find(val => val === column.label);
          if (platform) {
            column.setSelection(true);
          } else {
            column.setSelection(false);
          }
        });
      }
      // If no filter is selected set the visibility for all options as false
      else if (value === Constants.STATUS_FILTER_NONE) {
        self.updateAllColumnsVisibility(false);
      } else {
        self.updateAllColumnsVisibility(true);
      }
    },
    // update GPS and virtual schools option visibility
    updatePlatformOptionVisibility(publishStatus) {
      // if status is in review or published live set GPS option and virtual schools visible as false
      const gpsOption = self.publishPlatformOptions.find(
        option => option.id === DistributionConstants.COL_GPS_ID
      );
      const virtualSchoolsOption = self.publishPlatformOptions.find(
        option => option.id === DistributionConstants.COL_VIRTUALSCHOOLS_ID
      );
      if (
        publishStatus &&
        (publishStatus === statusOptions.IN_REVIEW ||
          publishStatus === statusOptions.PUBLISHED_LIVE)
      ) {
        gpsOption.setVisible(false);
        virtualSchoolsOption.setVisible(false);
      } else {
        gpsOption.setVisible(true);
        virtualSchoolsOption.setVisible(true);
      }
    },

    /**
     *
     * @param {*} userPreferencesData - User preferences data after modified in desired format
     * @param {*} userFilter - belongs to cookie data if any data is stored in cookie or not
     */
    updatePlatformOptionWithUserPreferences(userPreferencesData, userFilter) {
      self.publishPlatformOptions.forEach((item, index) => {
        if (
          userPreferencesData[item.id] &&
          userPreferencesData[item.id].locked
        ) {
          // if filter value locked for user, will deselect and make it invisible
          self.publishPlatformOptions[index].setSelection(false);
          self.publishPlatformOptions[index].setVisible(false);
        } else if (
          (!userPreferencesData[item.id] && !userFilter) ||
          (userPreferencesData[item.id] &&
            !userFilter &&
            !userPreferencesData[item.id].active)
        ) {
          // if no data is stored in cookie and platform filter values doesn't match with user preferences or user is not active but not locked, will deselect respective value
          self.publishPlatformOptions[index].setSelection(false);
        } else {
          // do nothing
        }
      });
    }
  }));
