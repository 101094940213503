/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem
} from '@mui/material';
import Constants from '../../constants';

export const ForbiddenUserDetailPopper = props => {
  const { TransitionProps, handleLogout, handleClose, open, firstName, email } =
    props;

  return (
    <Grow
      {...TransitionProps}
      style={{
        marginTop: '3%'
      }}
    >
      <Paper>
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList
            autoFocusItem={open}
            id="menu-list-grow"
            className="forbidden-user-detail"
          >
            {firstName && email && (
              <span>
                <MenuItem className="menuItem-login">
                  <span className="menu-item-user" title={firstName}>
                    <b>{firstName}</b>
                  </span>
                </MenuItem>
                <MenuItem className="menuItem-login">
                  <span className="menu-item-email" title={email}>
                    {email}
                  </span>
                </MenuItem>
              </span>
            )}
            <MenuItem className="menuItem-login">
              <Button
                variant="contained"
                color="primary"
                onClick={handleLogout}
                className="button-login"
                aria-label="user-details-sign-out-btn"
              >
                {Constants.LOGOUT_TEXT}
              </Button>
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Grow>
  );
};

ForbiddenUserDetailPopper.propTypes = {
  TransitionProps: PropTypes.element,
  placement: PropTypes.string.isRequired,
  handleLogout: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
};
