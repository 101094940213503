/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { validateProductId } from '../services/validateProductId';
import constants from '../constants';

export default types
  .model('Product', {
    productId: types.maybeNull(types.string),
    productModel: types.maybeNull(types.string),
    productPlatform: types.maybeNull(types.string),
    productValidationMessage: types.maybeNull(types.string),
    valid: true
  })
  .actions(self => ({
    async validateProductId(productIdValue, entityUrn) {
      const res = await validateProductId(productIdValue, entityUrn);
      if (res.productId) {
        const { productId, productModel, productPlatform, valid } = res;
        self.updateProductModel(
          productId,
          productModel,
          productPlatform,
          valid
        );
        self.setValidationMessage(constants.VALID_PRODUCT_ID);
      } else {
        self.productIdInvaild();
        if (res.status === 422) {
          self.setValidationMessage(constants.DUPLICATE_PRODUCT_ID);
        } else {
          self.setValidationMessage(constants.INVALID_PRODUCT_ID);
        }
      }
    },

    updateProductModel(productId, productModel, productPlatform, valid) {
      self.productId = productId;
      self.productModel = productModel;
      self.productPlatform = productPlatform;
      self.valid = valid;
    },

    productIdInvaild() {
      self.valid = false;
      self.productModel = null;
      self.productPlatform = null;
    },

    resetProductModel() {
      self.productId = null;
      self.productModel = null;
      self.productPlatform = null;
      self.productValidationMessage = null;
      self.valid = true;
    },

    setValidationMessage(message) {
      self.productValidationMessage = message;
    }
  }));
