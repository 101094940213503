/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { getSpecialPermissions } from '../services/getPermissionsData';

const SpecialPermission = types.model('Permission', {
  permissionId: types.maybeNull(types.string),
  users: types.maybeNull(types.array(types.string)),
  label: types.maybeNull(types.string),
  isAssignable: types.maybeNull(types.boolean)
});

export default types
  .model('SpecialPermissions', {
    specialPermissions: types.array(SpecialPermission)
  })
  .actions(self => ({
    async getPermissions() {
      const permissions = await getSpecialPermissions();
      this.setPermission(permissions);
    },

    setPermission(permissions) {
      self.specialPermissions = [];
      permissions.forEach(permission =>
        self.specialPermissions.push(permission)
      );
    },

    isPermissionExist(permission) {
      return self.specialPermissions.find(
        value => value.permissionId === permission
      );
    },

    isUserHasPermission(permissionId, userId) {
      const permission = self.isPermissionExist(permissionId);
      return permission ? permission.users.includes(userId) : false;
    }
  }));
