/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import {
  Button,
  IconButton,
  TextField,
  Tabs,
  Tab,
  FormControlLabel,
  FormControl,
  InputLabel,
  Checkbox,
  MenuItem,
  FormLabel,
  RadioGroup,
  Radio,
  Select
} from '@mui/material';
import './NotificationAndTutorialMgmt.style.scss';
import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import Constants from '../../constants';
import NotificationAndTutorialCardBox from './NotificationAndTutorialCardBox';
import {
  CustomDialog,
  DialogHeader,
  DialogContent,
  DialogActions
} from '../common/Dialog';
import { createNotification } from '../../services/createNotification';
import { removeNTData } from '../../services/removeNTData';
import { updateNTData } from '../../services/updateNTData';
import { resetUserNotificationAlert } from '../../services/resetUserNotificationAlert';
import { reOrderResources } from '../../services/reOrderResources';
import {
  isValidURL,
  handleDragStart,
  handleDragOver,
  handleDrop
} from '../../utils/utils';
import { startFileUplaodInChunks } from '../../utils/fileUpload';

const buttonAlignmentOptions = [
  { label: 'Left', value: 'left' },
  { label: 'Right', value: 'right' },
  { label: 'Center', value: 'center' }
];

const buttonActionOptions = [
  { label: 'Button', value: 'button' },
  { label: 'Link', value: 'link' }
];

const buttonTargetOptions = [
  { label: 'Web URL', value: 'url' },
  { label: 'Video', value: 'video' },
  { label: 'Tutorial', value: 'tutorial' }
];

const webUrlTargetOptions = [
  { label: 'New Tab', value: 'tab' },
  { label: 'New Window', value: 'window' }
];

const addButtonTargetData = (
  data,
  image,
  video,
  videoUrl,
  webUrl,
  webUrlTarget
) => {
  const newData = data;

  if (image) {
    newData.thumbnailUrl = image;
  }

  // If target is video, removing weburl data if added before
  if (video && newData.actionTarget === 'video') {
    newData.videoUrl = video;
    newData.webUrl = '';
    newData.webUrlTarget = '';
  }

  // If target is url, removing video data if added before
  if (webUrl && newData.actionTarget === 'url') {
    newData.webUrl = webUrl;
    newData.webUrlTarget = webUrlTarget;
    if (videoUrl) {
      newData.removeVideo = true;
    }
  }

  // If target is tutorial, removing video and url data if added before
  if (newData.actionTarget === 'tutorial' || !newData.actionTarget) {
    newData.webUrl = '';
    newData.webUrlTarget = '';
    if (videoUrl) {
      newData.removeVideo = true;
    }
  }

  return newData;
};

const swapBoxes = props => {
  const {
    fromBox,
    toBox,
    orderNotifications,
    setOrderNotifications,
    orderTutorials,
    setOrderTutorials,
    setReorderValue,
    tabValue,
    reOrderValue
  } = props;
  const reOrderSteps = [];
  let nTData;
  let setNTData;
  if (tabValue === 0) {
    nTData = orderNotifications;
    setNTData = setOrderNotifications;
  } else {
    nTData = orderTutorials;
    setNTData = setOrderTutorials;
  }
  const boxes = nTData.slice();
  let fromIndex = -1;
  let toIndex = -1;
  for (let i = 0; i < boxes.length; i += 1) {
    if (boxes[i].resourceId === fromBox.id) {
      fromIndex = i;
    }
    if (boxes[i].resourceId === toBox.id) {
      toIndex = i;
    }
  }
  if (fromIndex !== -1 && toIndex !== -1) {
    const { fromId, ...fromRest } = boxes[fromIndex];
    const { toId, ...toRest } = boxes[toIndex];
    boxes[fromIndex] = { id: fromBox.id, ...toRest };
    boxes[toIndex] = { id: toBox.id, ...fromRest };
    if (fromBox.id !== toBox.id) {
      reOrderSteps.push(...reOrderValue, {
        fromResourceId: fromBox.id,
        toResourceId: toBox.id
      });
      setReorderValue(reOrderSteps);
    }
    setNTData(boxes);
  }
};

// check if required target data is available
const checkButtonTarget = (
  buttonTarget,
  webUrl,
  video,
  videoUrl,
  setWebUrlError,
  setVideoError
) => {
  let hasError = false;
  let errorUrl = false;
  let errorVideo = false;

  if (buttonTarget === 'url' && !webUrl) {
    setWebUrlError(Constants.WEB_URL_REQUIRED);
    errorUrl = true;
  }

  if (buttonTarget === 'video' && !video && !videoUrl) {
    setVideoError('Video is required');
    errorVideo = true;
  }

  if (!!webUrl && !isValidURL(webUrl)) {
    setWebUrlError(Constants.INVALID_URL);
    errorUrl = true;
  }

  hasError = errorVideo || errorUrl;
  return hasError;
};

const NotificationAndTutorialMgmt = props => {
  const {
    store: {
      NTData: {
        notifications,
        tutorials,
        getNTData,
        getDataOnce,
        setGetDataOnce,
        resetData,
        setResetData
      }
    }
  } = props;
  const [alignment, setAlignment] = useState('');
  const [buttonAction, setAction] = useState('');
  const [buttonTarget, setTarget] = useState('');
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [header, setHeader] = useState('');
  const [title, setTitle] = useState('');
  const [label, setLabel] = useState('');
  const [description, setDescription] = useState('');
  const [webUrl, setWebUrl] = useState('');
  const [webUrlError, setWebUrlError] = useState('');
  const [webUrlTarget, setWebUrlTarget] = useState('tab');
  const [descriptionError, setDescriptionError] = useState('');
  const [videoError, setVideoError] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [deleteConfimation, setDeleteConfimation] = useState(false);
  const [removeThumbnail, setRemoveThumbnail] = useState(false);
  const [removeVideo, setRemoveVideo] = useState(false);
  const [editData, setEditData] = useState(null);
  const [getData, setGetData] = useState(false);
  const [orderNotifications, setOrderNotifications] = useState(notifications);
  const [orderTutorials, setOrderTutorials] = useState(tutorials);
  const [deleteData, setDeleteData] = useState(null);
  const [featureType, setFeatureType] = useState('notification');
  const [reOrderValue, setReorderValue] = useState([]);
  const [labelError, setLabelError] = useState('');
  const [videoUrl, setVideoUrl] = useState('');

  const handleFeatureChange = event => {
    setFeatureType(event.target.value);
  };

  const handleAlignmentChange = e => {
    setAlignment(e.target.value);
  };
  const handleButtonAction = e => {
    setAction(e.target.value);
  };
  const handleButtonTarget = e => {
    setTarget(e.target.value);
    setWebUrlError('');
    setVideoError('');
    setLabelError('');
  };
  const handlewebUrlTarget = e => {
    setWebUrlTarget(e.target.value);
  };

  const uploadImageFile = async event => {
    event.preventDefault();
    setImage(event.target.files && event.target.files[0]);
  };

  const uploadVideoFile = event => {
    setVideo(event.target.files && event.target.files[0]);
    setVideoError('');
  };

  const handleTabChange = (e, val) => {
    setTabValue(val);
  };

  const handleDelete = data => {
    setDeleteData(data);
    setDeleteConfimation(true);
  };

  const resetErrors = () => {
    setVideoError('');
    setLabelError('');
    setDescriptionError('');
    setWebUrlError('');
  };

  const resetStates = () => {
    setHeader('');
    setTitle('');
    setLabel('');
    setAlignment('');
    setAction('');
    setDescription('');
    setImage(null);
    setVideo(null);
    setEditData(null);
    setIsEdit(false);
    setRemoveThumbnail(false);
    setRemoveVideo(false);
    setWebUrlError('');
    setWebUrl('');
    setWebUrlTarget('tab');
    setTarget('');
    setVideoError('');
    setLabelError('');
    setDescriptionError('');
  };

  const handleEdit = data => {
    resetErrors();
    setHeader(data.heading || '');
    setTitle(data.title || '');
    const { placement, type, target, ...rest } = data.action[0];
    setLabel(rest.label || '');
    setAlignment(placement || '');
    setAction(type || '');
    setDescription(data.description);
    setIsEdit(true);
    setEditData(data);
    setFeatureType(data.resourceType === 'N' ? 'notification' : 'tutorial');
    setWebUrl(data.webUrl || '');
    setTarget(target || '');
    setWebUrlTarget(data.webUrlTarget || 'tab');
    setVideoUrl(data.videoUrl);
  };

  const updateHeader = e => {
    const { value } = e.target;
    setHeader(value);
  };

  const updateTitle = e => {
    const { value } = e.target;
    setTitle(value);
  };

  const updateLabel = e => {
    const { value } = e.target;
    setLabel(value);
    setLabelError(
      buttonTarget && value.trim().length === 0 ? Constants.LABEL_REQUIRED : ''
    );
  };

  const updateDescription = e => {
    const { value } = e.target;
    setDescription(value);
    setDescriptionError(
      value.trim().length === 0 ? Constants.DESCRIPTION_REQUIRED : ''
    );
  };

  const updateWebURL = e => {
    const { value } = e.target;
    setWebUrl(value);
    let hasError = '';

    if (value.trim().length) {
      hasError = isValidURL(value) ? '' : Constants.INVALID_URL;
    } else {
      hasError = Constants.WEB_URL_REQUIRED;
    }
    setWebUrlError(hasError);
  };

  const handleClear = () => {
    /* istanbul ignore else  */
    if (isEdit) {
      setIsEdit(false);
    }
    resetStates();
  };

  const handleDeleteConfimationClose = () => {
    setDeleteData(null);
    setDeleteConfimation(false);
  };

  const handleRemoveThumbnail = () => {
    setRemoveThumbnail(!removeThumbnail);
  };

  const handleRemoveVideo = () => {
    setRemoveVideo(!removeVideo);
  };

  const handleCloseThumbnail = () => {
    setImage(null);
    document.getElementById('imageFileInput').value = '';
  };

  const handleCloseVideo = () => {
    setVideo(null);
    document.getElementById('videoFileInput').value = '';
  };

  const handleConfirmedDelete = async () => {
    const data = { resourceIds: [deleteData.resourceId] };
    const res = await removeNTData(data);

    /* istanbul ignore else  */
    if (res.resourceIds[0].message === 'Removed') {
      setGetData(true);
      resetStates();
      setDeleteData(null);
      setDeleteConfimation(false);
    }
  };

  const handleServiceCall = async data => {
    let res = {};
    const newData = data;
    const service = isEdit ? updateNTData : createNotification;
    const checkIsEdit = isEdit;
    const formData = new FormData();
    const fileSize = 9000000;

    // if file size is more then 9 MB then upload files in chunks
    if (data.videoUrl && data.videoUrl.size > fileSize) {
      const s3VideoLocation = await startFileUplaodInChunks(data.videoUrl);
      newData.videoUrl = '';
      newData.s3VideoLocation = s3VideoLocation;
    }

    Object.keys(newData).forEach(key => {
      if (newData[key] !== null) {
        formData.append(key, newData[key]);
      }
    });

    res = await service(formData);

    if (res.position) {
      setGetData(true);
      if (!checkIsEdit) {
        // Reset user notification alert after creating new notification
        await resetUserNotificationAlert();
      }
      resetStates();
    }
  };

  // check whether all data required are available before save
  const checkError = () => {
    let hasError = false;
    let errorDesc = false;
    let buttonTargetError = false;
    let errorLabel = false;

    if (description === '') {
      setDescriptionError(Constants.DESCRIPTION_REQUIRED);
      errorDesc = true;
    }

    if (buttonTarget) {
      buttonTargetError = checkButtonTarget(
        buttonTarget,
        webUrl,
        video,
        videoUrl,
        setWebUrlError,
        setVideoError
      );

      if (!label && !removeVideo) {
        setLabelError(Constants.LABEL_REQUIRED);
        errorLabel = true;
      }
    }

    hasError = errorDesc || buttonTargetError || errorLabel;

    return hasError;
  };

  // Save and Update functionality handled by handleSave
  const handleSave = async () => {
    if (checkError()) {
      return;
    }

    const actionItems = {
      actionLabel: label,
      actionPlacement: alignment,
      actionType: buttonAction,
      actionTarget: buttonTarget
    };
    let data = {
      title,
      description,
      heading: header,
      resourceType: featureType === 'notification' ? 'N' : 'T',
      resourceId: isEdit ? editData.resourceId : null
    };

    // if button Target is available then adding the action data in payload
    if (buttonTarget && !removeVideo) {
      data = { ...data, ...actionItems };
    }

    /* istanbul ignore else  */
    if (removeThumbnail) {
      data.removeThumbnail = true;
    }

    /* istanbul ignore else  */
    if (removeVideo) {
      data.removeVideo = true;
    }

    // Adding button Target data to payload
    const formattedData = addButtonTargetData(
      data,
      image,
      video,
      videoUrl,
      webUrl,
      webUrlTarget
    );

    handleServiceCall(formattedData);
  };

  // Save new order of N/T cards
  const handleSaveOrder = async () => {
    for (let i = 0; i < reOrderValue.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      await reOrderResources([reOrderValue[i]]);
    }
    setReorderValue([]);
    setGetData(true);
  };

  useEffect(() => {
    if (isEdit) {
      handleClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  useEffect(() => {
    resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureType, tabValue]);

  // if remove video check box is checked then removing errors
  useEffect(() => {
    if (removeVideo) {
      setVideoError('');
      setLabelError('');
    }
  }, [removeVideo]);

  useEffect(() => {
    // if button target is none, removing other action data
    if (!buttonTarget) {
      setLabelError('');
      setLabel('');
      setAlignment('');
      setAction('');
    }

    // default values added for Alingnment and Button type
    if (buttonTarget) {
      if (!alignment) {
        setAlignment('left');
      }
      if (!buttonAction) {
        setAction('button');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonTarget]);

  useEffect(() => {
    const fetchData = async () => {
      await getNTData();
    };

    if (getDataOnce || getData) {
      fetchData();
      setGetDataOnce(false);
      setGetData(false);
    }

    if (resetData) {
      setOrderNotifications(notifications);
      setOrderTutorials(tutorials);
      setResetData(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getDataOnce,
    setGetDataOnce,
    getData,
    setGetData,
    notifications,
    resetData
  ]);

  const renderOptions = (options, item) => {
    const menuItems = options.map((option, index) => {
      const i = index + 1;
      const { label: name, value: optionValue } = option;
      return (
        <MenuItem
          key={`option_${i}`}
          name={label}
          value={optionValue}
          className="select-menu-item button-alignment__menu-item"
          aria-label={`NT-tool-${item}-${name}`}
        >
          {name}
        </MenuItem>
      );
    });
    if (item === 'button-target-options') {
      return [
        <MenuItem
          value=""
          key="option_none"
          className="select-menu-item button-alignment__menu-item"
          aria-label={`NT-tool-${item}-none`}
          name="none"
        >
          {Constants.NONE}
        </MenuItem>,
        menuItems
      ];
    }
    return menuItems;
  };

  const removeAudioOrVideo = () => (
    <>
      {editData && editData.thumbnailUrl && (
        <FormControlLabel
          control={
            <Checkbox
              checked={removeThumbnail}
              onChange={handleRemoveThumbnail}
              color="primary"
              inputProps={{
                'aria-label': 'NT-tool-remove-thumbnail-checkbox'
              }}
            />
          }
          label={Constants.REMOVE_THUMBNAIL}
        />
      )}
      {editData && editData.videoUrl && buttonTarget === 'video' && (
        <FormControlLabel
          control={
            <Checkbox
              checked={removeVideo}
              onChange={handleRemoveVideo}
              color="primary"
              inputProps={{
                'aria-label': 'NT-tool-remove-video-checkbox'
              }}
            />
          }
          label={Constants.REMOVE_VIDEO}
        />
      )}
    </>
  );

  const renderNTCards = nTData => {
    return (
      nTData &&
      nTData.length > 0 &&
      nTData.map(card => (
        <NotificationAndTutorialCardBox
          data={card}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          key={uuidv4()}
          draggable="true"
          onDragStart={data => handleDragStart(data, 'dragContent')}
          onDragOver={handleDragOver}
          onDrop={data =>
            handleDrop(data, 'dragContent', (fromBox, toBox) =>
              swapBoxes({
                fromBox,
                toBox,
                orderNotifications,
                setOrderNotifications,
                orderTutorials,
                setOrderTutorials,
                setReorderValue,
                tabValue,
                reOrderValue
              })
            )
          }
        />
      ))
    );
  };

  const body = (
    <div className="nt-content" id="nt-content" data-testid="nt-content">
      <form>
        <div className="settings">
          <div>
            <TextField
              id="header"
              autoComplete="off"
              autoFocus
              label="Header"
              type="text"
              onChange={updateHeader}
              value={header}
              variant="outlined"
              data-testid="header"
              inputProps={{
                'aria-label': 'NT-tool-header'
              }}
            />
          </div>
          <div>
            <TextField
              id="title"
              autoComplete="off"
              autoFocus
              label="Title"
              type="text"
              className="title"
              onChange={updateTitle}
              value={title}
              variant="outlined"
              data-testid="title"
              inputProps={{
                'aria-label': 'NT-tool-title'
              }}
            />
          </div>
          <div>
            <TextField
              id="description"
              label="Description *"
              className="description"
              rows={4}
              multiple
              value={description}
              onChange={updateDescription}
              variant="outlined"
              data-testid="description"
              inputProps={{
                'aria-label': 'NT-tool-description'
              }}
            />

            <div className="description-error">{descriptionError}</div>
          </div>
          <div className="choose-files-section">
            <div className="upload-thumbnail-image">
              <div>
                <input
                  type="file"
                  required
                  id="imageFileInput"
                  className="file-input"
                  accept="image/*"
                  data-testid="imageFileInput"
                  onChange={e => uploadImageFile(e)}
                  aria-label="NT-tool-choose-thumbnail-input"
                />

                <label htmlFor="imageFileInput">
                  <IconButton
                    color="primary"
                    component="span"
                    aria-label="NT-tool-choose-thumbnail-icon"
                    size="large"
                  >
                    <ImageIcon fontSize="large" />
                  </IconButton>
                  {Constants.CHOOSE_THUMBNAIL}
                </label>
              </div>
              <div className="file-name">
                {image && (
                  <>
                    {image.name}
                    <span>
                      <IconButton
                        aria-label="NT-tool-selected-thumbnail-close"
                        className="close-thumbnail-icon"
                        onClick={handleCloseThumbnail}
                        data-testid="closeImageIcon"
                        size="large"
                      >
                        <CloseIcon />
                      </IconButton>
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
          <FormControl
            variant="standard"
            component="fieldset"
            className="notifications"
          >
            <FormLabel component="legend">{Constants.FEATURE_TYPE}</FormLabel>
            <RadioGroup
              row
              aria-label="featuretype"
              name="featuretype"
              value={featureType}
              onChange={handleFeatureChange}
              data-testid="featuretype"
            >
              <FormControlLabel
                value="notification"
                control={
                  <Radio
                    color="primary"
                    inputProps={{
                      'aria-label': 'NT-tool-notification-radio-btn'
                    }}
                  />
                }
                label="Notification"
                data-testid="featuretype-notification"
              />
              <FormControlLabel
                value="tutorial"
                control={
                  <Radio
                    color="primary"
                    inputProps={{
                      'aria-label': 'NT-tool-tutorial-radio-btn'
                    }}
                  />
                }
                label="Tutorial"
                data-testid="featuretype-tutorial"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <br />
        <div className="actions-wrapper">
          <div className="button-container">
            <fieldset>
              <legend>{Constants.ACTION_SETTINGS}</legend>
              <div className="action-container">
                <div>
                  <TextField
                    autoComplete="off"
                    autoFocus
                    id="Label"
                    label="Label"
                    type="text"
                    data-testid="label"
                    onChange={updateLabel}
                    value={label}
                    inputProps={{
                      'aria-label': 'NT-tool-label'
                    }}
                  />
                  <div className="general-error">{labelError}</div>
                </div>
                <div className="action-elements">
                  <FormControl>
                    <InputLabel htmlFor="alignment">
                      {Constants.ALIGNMENT}
                    </InputLabel>
                    <Select
                      id="alignment"
                      value={alignment}
                      onChange={handleAlignmentChange}
                      label={Constants.ALIGNMENT}
                      inputProps={{
                        'data-testid': 'alignment',
                        'aria-label': 'NT-tool-alignment',
                        'aria-required': 'true'
                      }}
                    >
                      {renderOptions(
                        buttonAlignmentOptions,
                        'button-alignment-options'
                      )}
                    </Select>
                  </FormControl>
                  <FormControl>
                    {' '}
                    <InputLabel htmlFor="button-type">
                      {Constants.TYPE}
                    </InputLabel>
                    <Select
                      id="button-type"
                      label="Type"
                      value={buttonAction}
                      onChange={handleButtonAction}
                      inputProps={{
                        'data-testid': 'button-type',
                        'aria-label': 'NT-tool-type'
                      }}
                    >
                      {renderOptions(
                        buttonActionOptions,
                        'button-type-options'
                      )}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="button-target">
                      {Constants.TARGET}
                    </InputLabel>
                    <Select
                      id="button-target"
                      label="Target"
                      value={buttonTarget}
                      onChange={handleButtonTarget}
                      inputProps={{
                        'data-testid': 'button-target',
                        'aria-label': 'NT-tool-target'
                      }}
                    >
                      {renderOptions(
                        buttonTargetOptions,
                        'button-target-options'
                      )}
                    </Select>
                  </FormControl>
                </div>
                {buttonTarget === 'url' && (
                  <div className="action-elements">
                    <FormControl>
                      <InputLabel htmlFor="button-target-open">
                        {Constants.TARGET_OPEN}
                      </InputLabel>
                      <Select
                        id="button-target-open"
                        label="Open in"
                        value={webUrlTarget}
                        onChange={handlewebUrlTarget}
                        inputProps={{
                          'data-testid': 'button-target-open',
                          'aria-label': 'NT-tool-target-open-in'
                        }}
                      >
                        {renderOptions(
                          webUrlTargetOptions,
                          'target-open-in-options'
                        )}
                      </Select>
                    </FormControl>
                    <div>
                      <TextField
                        id="webUrl"
                        label="Web URL"
                        className="web-url"
                        multiple
                        value={webUrl}
                        onChange={updateWebURL}
                        variant="outlined"
                        inputProps={{
                          'data-testid': 'webUrl',
                          'aria-label': 'NT-tool-webUrl'
                        }}
                      />
                      <div className="general-error">{webUrlError}</div>
                    </div>
                  </div>
                )}
                {buttonTarget === 'video' && (
                  <div className="choose-files-section action-elements">
                    <div className="upload-video">
                      <input
                        type="file"
                        required
                        id="videoFileInput"
                        className="file-input"
                        multiple
                        accept="video/*"
                        data-testid="videoFileInput"
                        onChange={e => uploadVideoFile(e)}
                        aria-label="NT-tool-choose-video-input"
                      />
                      <label htmlFor="videoFileInput">
                        <IconButton
                          color="primary"
                          component="span"
                          aria-label="NT-tool-choose-video-icon"
                          size="large"
                        >
                          <OndemandVideoIcon fontSize="large" />
                        </IconButton>
                        {Constants.CHOOSE_VIDEO}
                      </label>
                      <div className="video-name">
                        {video && (
                          <>
                            {video.name}
                            <span>
                              <IconButton
                                aria-label="NT-tool-selected-video-close"
                                className="close-video-icon"
                                onClick={handleCloseVideo}
                                data-testid="closeVideoIcon"
                                size="large"
                              >
                                <CloseIcon />
                              </IconButton>
                            </span>
                          </>
                        )}
                        <div className="general-error">{videoError}</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </fieldset>
          </div>
        </div>
        <div className="check-box-style">
          {isEdit && <>{removeAudioOrVideo()}</>}
        </div>
        <div className="footer">
          <Button
            autoFocus
            onClick={handleClear}
            className="btn-cancel"
            data-testid="btn-clear"
            aria-label="NT-tool-clear"
          >
            {Constants.CLEAR}
          </Button>
          <Button
            type="button"
            className="btn-save"
            onClick={handleSave}
            data-testid="btn-save"
            aria-label="NT-tool-save"
          >
            {isEdit ? Constants.UPDATE : Constants.SAVE}
          </Button>
        </div>
        <div className="tabs-container">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
          >
            <Tab
              label="Notifications"
              className="notification_and_tutorial_tab"
              data-testid="notification_tab"
              aria-label="NT-tool-notifications-tab"
            />
            <Tab
              label="Tutorials"
              className="notification_and_tutorial_tab"
              data-testid="tutorial_tab"
              aria-label="NT-tool-tutorials-tab"
            />
          </Tabs>
        </div>
        <div className="image-wrapper">
          {renderNTCards(tabValue === 0 ? orderNotifications : orderTutorials)}
        </div>
        <div className="footer">
          <Button
            autoFocus
            className="btn-order-save"
            data-testid="btn-order-save"
            onClick={handleSaveOrder}
            aria-label="NT-tool-save-order"
          >
            {Constants.SAVE_ORDER}
          </Button>
        </div>
      </form>
      <CustomDialog
        onClose={handleDeleteConfimationClose}
        open={deleteConfimation}
        className="nt-delete-confirmation-dialog"
      >
        <DialogHeader
          onClose={handleDeleteConfimationClose}
          title="Delete Confirmation"
        />
        <DialogContent>
          <div className="nt-delete-confirmation">
            {Constants.DELETE_CONFIRMATION}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className="btnCancel"
            data-testid="cancel-del-btn"
            onClick={handleDeleteConfimationClose}
            aria-label="NT-tool-delete-confirmation-dialog-cancel"
          >
            {Constants.CANCEL}
          </Button>
          <Button
            className="btnSave"
            data-testid="confirm-del-btn"
            onClick={handleConfirmedDelete}
            aria-label="NT-tool-delete-confirmation-dialog-confirm"
          >
            {Constants.OK}
          </Button>
        </DialogActions>
      </CustomDialog>
    </div>
  );

  return (
    <div
      id="ntManagement-wrapper"
      className="ntManagement-wrapper"
      data-testid="ntManagement-wrapper"
    >
      {body}
    </div>
  );
};

NotificationAndTutorialMgmt.propTypes = {
  store: PropTypes.shape({
    NTData: PropTypes.shape({
      notifications: PropTypes.arrayof(PropTypes.string),
      tutorials: PropTypes.arrayof(PropTypes.string),
      getNTData: PropTypes.func.isRequired,
      getDataOnce: PropTypes.func.isRequired,
      setGetDataOnce: PropTypes.func.isRequired,
      resetData: PropTypes.func.isRequired,
      setResetData: PropTypes.func.isRequired
    })
  })
};

export default inject('store')(observer(NotificationAndTutorialMgmt));
