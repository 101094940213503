/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import { Delete, PlayArrow, Publish } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { getSlides } from '../../services/getSlides';
import { updateSlideVideoUrl } from '../../services/updateSlideVideoUrl';
import VideoDialog from '../../containers/video-tutorial/VideoTutorial';
import {
  CustomDialog,
  DialogHeader,
  DialogContent,
  DialogActions
} from '../common/Dialog';
import Constants from '../../constants';
import { startFileUplaodInChunks } from '../../utils/fileUpload';

import './PageTourSlideMgmt.style.scss';

export const PageTourSlideMgmt = () => {
  const [slides, setSlides] = useState([]);
  const [playVideo, setPlayVideo] = useState(null);
  const [deleteVideoConfimation, setDeleteVideoConfimation] = useState(null);

  useEffect(() => {
    async function fetchAllSlides() {
      const allSlides = await getSlides();
      setSlides(allSlides);
    }
    fetchAllSlides();
  }, []);

  const onClickDeleteVideo = slideId => {
    setDeleteVideoConfimation(slideId);
  };

  const onClickDeleteVideoConfirm = async () => {
    await updateSlideVideoUrl(deleteVideoConfimation, '');
    const allSlides = await getSlides();
    setSlides(allSlides);
    setDeleteVideoConfimation(null);
  };

  const closeVidelDialog = () => {
    setPlayVideo(null);
  };

  const openVideoDialog = videoUrl => {
    setPlayVideo(videoUrl);
  };

  const handledeleteVideoConfimationClose = () => {
    setDeleteVideoConfimation(null);
  };

  const onVideoUploadHandler = async (e, slideId) => {
    const videoS3Url = await startFileUplaodInChunks(e.target.files[0]);
    await updateSlideVideoUrl(slideId, videoS3Url);
    const allSlides = await getSlides();
    setSlides(allSlides);
  };

  const cards = ({ slideId, videoUrl }) => {
    return (
      <div
        key={slideId}
        className={`card-container ${videoUrl ? 'has-video' : ''}`}
      >
        <Card variant="outlined">
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              {slideId}
            </Typography>
            <div>
              {videoUrl && (
                <>
                  <Tooltip title="Preview Video">
                    <IconButton
                      onClick={() => openVideoDialog(videoUrl)}
                      size="large"
                    >
                      <PlayArrow />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Video">
                    <IconButton
                      onClick={() => onClickDeleteVideo(slideId)}
                      size="large"
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              <div className="fancy-file-upload">
                <input
                  type="file"
                  required
                  id={slideId}
                  className="file-input"
                  multiple
                  accept="video/*"
                  data-testid="videoFileInput"
                  aria-label="NT-tool-choose-video-input"
                  onChange={e => onVideoUploadHandler(e, slideId)}
                />
                <label htmlFor={slideId}>
                  <Tooltip title="Upload Video">
                    <IconButton
                      class="upload-video-button"
                      onClick={() => {
                        document.getElementById(slideId).click();
                      }}
                      size="large"
                    >
                      <Publish />
                    </IconButton>
                  </Tooltip>
                </label>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };

  if (slides.length === 0) {
    return null;
  }

  return (
    <div className="page-tour-slide-wrapper">
      <div className="page-tour-slide-container">
        {slides.map(slide => cards(slide))}
      </div>
      {playVideo && (
        <VideoDialog videoLink={playVideo} handleClose={closeVidelDialog} />
      )}
      <CustomDialog
        onClose={handledeleteVideoConfimationClose}
        open={deleteVideoConfimation}
        className="nt-delete-confirmation-dialog"
      >
        <DialogHeader
          onClose={handledeleteVideoConfimationClose}
          title="Delete Confirmation"
        />
        <DialogContent>
          <div className="nt-delete-confirmation">
            {Constants.DELETE_SLIDE_VIDEO_CONFIRMATION}{' '}
            <b>{deleteVideoConfimation}</b>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className="btnCancel"
            data-testid="cancel-del-btn"
            onClick={handledeleteVideoConfimationClose}
            aria-label="NT-tool-delete-confirmation-dialog-cancel"
          >
            {Constants.CANCEL}
          </Button>
          <Button
            className="btnSave"
            data-testid="confirm-del-btn"
            onClick={onClickDeleteVideoConfirm}
            aria-label="NT-tool-delete-confirmation-dialog-confirm"
          >
            {Constants.OK}
          </Button>
        </DialogActions>
      </CustomDialog>
    </div>
  );
};
