/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */
/* eslint-disable react/no-danger */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import {
  SharpArrow,
  SnakeArrow,
  DefaultArrow
} from '../../custom/icons/CustomArrows';

import './PageTour.style.scss';
import WatchVideo from './WatchVideo';
import gaEventConstants from '../../constants/gaEventConstants';
import { gaTriggerGTMCustomEvents } from '../../../ga';
import constants from '../../constants';

/**
 * ***************************************
 * Start: Helper functions
 * ***************************************
 */
const getArrow = step => {
  const { arrowSVG, arrowShape: type } = step;
  if (arrowSVG) {
    return arrowSVG;
  }
  if (type === 'sharpArrow') {
    return SharpArrow;
  }
  if (type === 'snakeArrow') {
    return SnakeArrow;
  }
  return DefaultArrow;
};

const getContentPosition = (step, elem) => {
  if (step.contentPosition) {
    return step.contentPosition;
  }

  let position = elem.top < window.innerHeight / 2 ? 'bottom' : 'top';
  position += elem.left < window.innerWidth / 2 ? '-right' : '-left';

  return position;
};

const getNextBtnText = step => (step.nextBtnText ? step.nextBtnText : 'Next');
const getSkipBtnText = step => (step.skipBtnText ? step.skipBtnText : 'Skip');
const getElementProps = (step, elem) => {
  return {
    width: step.width || elem.width,
    elemHeight: step.height || elem.height,
    leftOff: step.leftOff || 0,
    contentTopOff: step.contentTopOff || 0,
    contentLeftOff: step.contentLeftOff || 0
  };
};
// Get element position with height and width
const offset = el => {
  if (!el) {
    return {};
  }
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
    width: el.clientWidth,
    height: el.clientHeight
  };
};

const setContentWrapperPosition = (
  top,
  left,
  stepContentPos,
  elemHeight,
  contentEl
) => {
  if (stepContentPos === 'bottom-right') {
    return {
      top: top + elemHeight,
      left
    };
  }

  if (stepContentPos === 'bottom-left') {
    return {
      top: top + elemHeight,
      left: left - contentEl.current.clientWidth
    };
  }

  if (stepContentPos === 'top-right') {
    return {
      top: top - contentEl.current.clientHeight,
      left
    };
  }

  if (stepContentPos === 'top-left') {
    return {
      top: top - contentEl.current.clientHeight,
      left: left - contentEl.current.clientWidth
    };
  }

  return { top, left };
};
/**
 * ***************************************
 * End: Helper functions
 * ***************************************
 */

/**
 * PageTour Component
 */
const PageTour = props => {
  const contentEl = useRef(null);
  const { onStopTour, steps, start, user } = props;

  // Internal states
  const [currentStep, setCurrentStep] = useState(0);
  const [contentPosition, setContentPosition] = useState({});
  const [elementPosition, setElementPosition] = useState({});
  const [windowWidth, setWindowWidth] = useState(0);

  /**
   * On window resize auto re-render
   */
  const setWindowWidthDebounce = debounce(() => {
    setWindowWidth(window.innerWidth);
  }, 500);

  useEffect(() => {
    window.addEventListener('resize', setWindowWidthDebounce);

    return () => {
      window.removeEventListener('resize', setWindowWidthDebounce);
    };
  }, [setWindowWidthDebounce]);

  // Hide scrollbar while showing tutorial
  const body = document.querySelector('body');
  body.className = 'hide-scrollbar';

  const triggerGTMForNotification = action => {
    const gtmData = {
      event: gaEventConstants.NOTIFICATION_TUTORIAL_ACTION_CLICKED,
      notificationAction: action,
      userId: user
    };
    gaTriggerGTMCustomEvents(gtmData);
  };

  /**
   * Go to next slide on click of next button
   */
  const onClickNext = () => {
    if (currentStep === 0) {
      triggerGTMForNotification(constants.START_TOUR);
    }
    setCurrentStep((currentStep + 1) % steps.length);
  };

  /**
   * Hide Tutorial on click of skip button
   */
  const onClickStop = () => {
    setCurrentStep(0);
    body.className = '';
    onStopTour();
    triggerGTMForNotification(constants.STOP_TOUR);
  };

  const step = steps[currentStep];
  const nextStep = document.querySelector(step.selector);

  // Due to sticky head we have to adjust top height by 150px
  const topAdjust = -150;
  let stepContentPos = '';

  // button text
  const nextBtnText = getNextBtnText(step);
  const skipBtnText = getSkipBtnText(step);

  const contentTextClass = `page-tour-content-text ${step.contentClass || ''}`;
  const ElemSelectContentArrow = getArrow(step);

  const elem = offset(nextStep);
  const { width, elemHeight, leftOff, contentTopOff, contentLeftOff } =
    getElementProps(step, elem);

  stepContentPos = getContentPosition(step, elem);

  let elemSelectContentClass = nextStep
    ? `${stepContentPos} page-tour-content`
    : 'center page-tour-content';

  // If arrow svg image is comming from config no need to scale it
  if (step.arrowSVG) {
    elemSelectContentClass = `no-scale ${elemSelectContentClass}`;
  }

  useEffect(() => {
    function setPositions() {
      if (start) {
        if (nextStep) {
          const top = elem.top + topAdjust + contentTopOff;
          const left = elem.left + width / 2 + leftOff + contentLeftOff;

          const contentPositionFind = setContentWrapperPosition(
            top,
            left,
            stepContentPos,
            elemHeight,
            contentEl
          );

          setContentPosition({
            top: `${contentPositionFind.top}px`,
            left:
              contentPositionFind.left >= 0
                ? `${contentPositionFind.left}px`
                : `0px`
          });

          /**
           * Set element highlighter position and shape
           */

          if (step.shape === 'circle') {
            setElementPosition({
              left: `${elem.left + elem.width / 2 - width / 2 + leftOff}px`,
              width: `${width}px`,
              top: `${elem.top - width / 2 + elem.height / 2}px`,
              height: `${width}px`,
              borderRadius: '50%'
            });
          } else {
            setElementPosition({
              left: `${elem.left + elem.width / 2 - width / 2 + leftOff}px`,
              width: `${width}px`,
              top: `${elem.top - elemHeight / 2 + elem.height / 2}px`,
              height: `${elemHeight}px`,
              borderRadius: `${step.radius}px`
            });
          }
        } else {
          setContentPosition({
            top: `${
              window.innerHeight / 2 - contentEl.current.clientHeight / 2 - 150
            }px`,
            left: `${
              window.innerWidth / 2 - contentEl.current.clientWidth / 2
            }px`
          });

          setElementPosition({
            left: 0,
            width: 0,
            top: 0,
            height: 0,
            borderRadius: 0
          });
        }
      }
    }
    setPositions();
  }, [
    start,
    contentTopOff,
    contentLeftOff,
    currentStep,
    elem.height,
    elem.left,
    elem.top,
    elem.width,
    elemHeight,
    leftOff,
    nextStep,
    step.radius,
    step.shape,
    stepContentPos,
    topAdjust,
    width,
    windowWidth
  ]);

  // Don't render anything if start is false
  if (!start) {
    return null;
  }

  const renderWatchVideo = videoLink => (
    <>
      {videoLink && <WatchVideo videoLink={step.videoLink} name="page-tour" />}
    </>
  );

  return (
    <>
      <div className="page-tour-overlay">
        <div className="page-tour-element" style={elementPosition}></div>
      </div>
      <div
        className={elemSelectContentClass}
        style={contentPosition}
        ref={contentEl}
      >
        <div className={contentTextClass} style={step.contentStyle}>
          <div
            style={step.contentWrapperStyle}
            className="page-tour-content-text-wrapper"
            dangerouslySetInnerHTML={{
              __html: step.content
            }}
          />
          {currentStep < steps.length - 1 ? (
            <div className="page-tour-action">
              <button
                type="button"
                onClick={onClickNext}
                className="page-tour-next-btn"
                aria-label="page-tour-next-btn"
              >
                {nextBtnText}
              </button>
              {currentStep === 0 && (
                <button
                  type="button"
                  onClick={onClickStop}
                  className="page-tour-skip-btn"
                  aria-label="page-tour-skip-btn"
                >
                  {skipBtnText}
                </button>
              )}
              {renderWatchVideo(step.videoLink)}
            </div>
          ) : (
            <div className="page-tour-action">
              <button
                type="button"
                onClick={onClickStop}
                className="page-tour-skip-btn"
                aria-label="page-tour-skip-btn"
              >
                {skipBtnText}
              </button>
              {renderWatchVideo(step.videoLink)}
            </div>
          )}
        </div>
        <div style={step.arrowWrapperStyle} className="page-tour-content-arrow">
          <ElemSelectContentArrow />
        </div>
      </div>
    </>
  );
};

PageTour.propTypes = {
  onStopTour: PropTypes.func.isRequired,
  steps: PropTypes.shape({
    selector: PropTypes.string,
    content: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    leftOff: PropTypes.number.isRequired,
    radius: PropTypes.number.isRequired,
    arrowShape: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    length: PropTypes.number.isRequired
  }),
  start: PropTypes.bool.isRequired,
  user: PropTypes.string
};

export default PageTour;
