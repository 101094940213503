/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import lobConstants from '../constants/lobConstants';
import { getTaxonomies } from '../services/getTaxonomiesService';

const SubjectItem = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.string)
});

export default types
  .model('Subject', {
    subjectList: types.array(SubjectItem)
  })
  .actions(self => ({
    getLabelByValue(value) {
      if (value) {
        const subjectVal = self.subjectList.find(
          subject => subject.value === value
        );
        if (subjectVal) {
          return subjectVal.label;
        }
      }
      return '';
    },
    /**
     * @function - Saving all the subject data in self object
     * @param {Subject Data}
     */
    setSubjectItems(items) {
      self.subjectList = [];
      items.forEach(item => {
        self.subjectList.push(item);
      });
    },
    // To fetch master list from API
    async getSubjectItems() {
      if (self.subjectList.length === 0) {
        const subjectList = await getTaxonomies(
          lobConstants.UK_SCHOOLS,
          'subject'
        );
        this.setSubjectItems(subjectList);
      }
    }
  }));
