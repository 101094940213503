/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import './CarouselContent.style.scss';

/**
 * SliderContent component contains all the slides of carousel
 * @param {*} props
 */
const CarouselContent = props => {
  const {
    translate: translateValue,
    transition: transitionValue,
    width,
    children
  } = props;

  // Style object
  // dynamically fetching the transition, translate and width values
  const styleObj = {
    transform: `translateX(-${translateValue}px)`,
    transition: `transform ease-out ${transitionValue}s`,
    width: `${width}px`
  };

  return (
    <div className="carousel-content" style={styleObj}>
      {children}
    </div>
  );
};

CarouselContent.propTypes = {
  translate: PropTypes.number.isRequired,
  transition: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
};

export default CarouselContent;
