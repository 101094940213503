/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { isEmpty } from 'lodash';
import Constants from '../constants';

const projectTypeFilterModel = types.model('projectTypeFilterModel', {
  id: types.string,
  label: types.string,
  checked: types.boolean,
  value: types.string,
  hidden: types.boolean
});

export default types
  .model('ProjectTypeFilterOptions', {
    projectTypeFilters: types.optional(types.array(projectTypeFilterModel), [
      {
        id: 'standard',
        label: 'Standard',
        value: 'Standard',
        checked: false,
        hidden: true
      },
      {
        id: 'development',
        label: 'Development',
        value: 'Developer',
        checked: false,
        hidden: false
      },
      {
        id: 'testing',
        label: 'Testing',
        value: 'Testing',
        checked: false,
        hidden: false
      },
      {
        id: 'internal',
        label: 'Internal',
        value: 'Internal',
        checked: false,
        hidden: false
      }
    ]),
    isDevModeOn: false
  })
  .views(self => ({
    get allTypesSelected() {
      return self.projectTypeFilters.every(filter => filter.checked);
    }
  }))
  .actions(self => ({
    updateProjectTypeFilter(id, checkedVal) {
      const prodFilter = self.projectTypeFilters.find(
        projectTypeFilter => projectTypeFilter.id === id
      );
      prodFilter.checked = checkedVal;
    },
    updateDevMode(value) {
      self.isDevModeOn = value;
      // make all the checkbox checked if DevMode = ON
      // make all the checkbox unchecked if DevMode = OFF
      self.projectTypeFilters.forEach(filter => {
        filter.checked = filter.hidden === true ? false : value;
      });
    },
    getAllcheckedOptions() {
      const checkedOption = [];
      // retrive all selected options and in newArray
      self.projectTypeFilters.forEach(column => {
        if (column.checked) {
          checkedOption.push(column.value);
        }
      });
      if (isEmpty(checkedOption)) {
        return Constants.STATUS_FILTER_DEFAULT;
      }
      return checkedOption.join(',');
    }
  }));
