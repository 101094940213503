/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import { axiosFetch } from './axiosFetch';
import apiConstants from '../constants/apiConstants';
import { urlModifier, getHeaders, parseURLParams, postHeaders } from '../utils';

export const getAllLobMeta = async () => {
  const apiPath = urlModifier(apiConstants.GET_ALL_LOB_META);

  const reqOptions = {
    ...getHeaders()
  };

  const { data } = await axiosFetch(reqOptions, apiPath);
  return data;
};

export const updateLobMeta = async (metaType, lobId, metaToUpdate) => {
  const replacement = {
    '%lobId%': lobId
  };
  const url = parseURLParams(replacement, apiConstants.UPDATE_LOB_META);
  const apiPath = urlModifier(url);

  const reqOptions = {
    ...postHeaders(),
    body: {
      [metaType]: metaToUpdate
    }
  };

  const { data } = await axiosFetch(reqOptions, apiPath);
  return data;
};

export const getMetaMasterData = async () => {
  const apiPath = urlModifier(apiConstants.GET_META_MASTER_DATA);

  const reqOptions = {
    ...getHeaders()
  };

  const { data } = await axiosFetch(reqOptions, apiPath);
  return data;
};
