/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import ThemeItem from './ThemeItems';
import { themesForLobList } from '../../__mocks__/data/themes';

export default types
  .model('Theme', {
    themeList: types.array(ThemeItem),
    themesForLob: types.array(types.frozen())
  })
  .actions(self => ({
    /**
     * @function - Saving all the theme data in self object
     * @param {learning framework}
     */
    setThemeList(items) {
      self.themeList = [];
      items.forEach(item => {
        self.themeList.push(item);
      });
    },
    getThemesForLob() {
      this.setThemesForLob(themesForLobList);
    },
    setThemesForLob(themes) {
      self.themesForLob = themes;
    }
  }));
