/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { getAlfrescoSiteIdByNodeRef } from '../services/getAlfrescoSiteIdByNodeRef';

export default types
  .model('Alfresco', {
    // nodeRef - uniquie id of site in Alfresco
    nodeRef: types.maybeNull(types.string),

    folderNodeRef: types.maybeNull(types.string),

    // repositoryFolder is the Site Name (Label)
    repositoryFolder: types.maybeNull(types.string),

    // siteId is the id of the site(backend name of site) in alfresco
    siteId: types.maybeNull(types.string),

    // repositoryUrl is the url of alfresco repo
    repositoryUrl: types.maybeNull(types.string),

    // repositoryName is the name of repo
    repositoryName: types.maybeNull(types.string),

    visibility: types.maybeNull(types.string),

    folderPath: types.maybeNull(types.string)
  })
  .actions(self => ({
    async getAlfrescoSiteIdByNodeRef() {
      const { siteId } = await getAlfrescoSiteIdByNodeRef(
        self.folderNodeRef || self.nodeRef
      );

      if (self.folderNodeRef) {
        self.folderPath = siteId;
      } else {
        self.setAlfrescoSiteId(siteId);
      }
      return siteId;
    },

    setAlfrescoSiteId(siteId) {
      self.siteId = siteId;
    }
  }));
