/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

export const getStatusOptions = showDistributableStatus => {
  return [
    { label: 'Show all status', value: 'default' },
    { label: 'In Progress', value: 'IN-PROGRESS' },
    ...(showDistributableStatus
      ? [{ label: 'In Review', value: 'In Review' }]
      : []),
    ...(showDistributableStatus
      ? [{ label: 'Published Live', value: 'Published Live' }]
      : []),
    { label: 'Work In Progress', value: 'WIP' },
    { label: 'Completed', value: 'DELIVERED' },
    { label: 'Failed', value: 'FAILURE' }
  ];
};

export const statusOptions = {
  IN_REVIEW: 'In Review',
  PUBLISHED_LIVE: 'Published Live'
};

export const getDistributionOptions = {
  COL_ETEXT_BAU_ID: 'EText_BAU',
  COL_ETEXT_BAU_LABEL: 'eText BAU',
  COL_ILP_REVEL_ID: 'ILP_Revel',
  COL_ILP_REVEL_LABEL: 'ILP Revel',
  COL_PLP_REVEL_ID: 'PLP_Revel',
  COL_PLP_REVEL_LABEL: 'PLP Revel',
  COL_PLP_OZ_ID: 'GLP_Oz',
  COL_PLP_OZ_LABEL: 'PLP Oz',
  COL_GPS_ID: 'GPS',
  COL_GPS_LABEL: 'GPS',
  COL_VIRTUALSCHOOLS_ID: 'Virtual_Schools',
  COL_VIRTUALSCHOOLS_LABEL: 'Virtual Schools',
  SHOW_ALL_ID: 'Show_All',
  SHOW_ALL_LABEL: 'Show All'
};

export const BUAffiliationOrder = [
  'EText_BAU',
  'ILP_Revel',
  'PLP_Revel',
  'GPS',
  'GLP_Oz',
  'ETEXT_Bronte',
  'virtual_schools',
  'All'
];
