/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import RootStore from '../models/RootStore';

/**
 * Accessing the MobX store to populate the error message
 * @param message Notification message to be displayed
 */
export const showErrorPopup = (message, title) => {
  const {
    MessageDialog: { showErrorMessageDialog }
  } = RootStore;
  showErrorMessageDialog(message, title);
};

/**
 * Accessing the MobX store to show loading spinner
 * @param status takes boolean value for adding loader and removing loader
 */
export const hideLoader = () => {
  const {
    Loader: { disableLoader }
  } = RootStore;
  disableLoader();
};

/**
 * Accessing the MobX store to show loading spinner
 * @param status takes boolean value for adding loader and removing loader
 */
export const showLoader = () => {
  const {
    Loader: { enableLoader }
  } = RootStore;
  enableLoader();
};
