/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { loadUrlInBlankTab, loadUrlInBlankWindow } from '../../utils/utils';

import './NotificationsCard.style.scss';
import { gaTriggerGTMCustomEvents } from '../../../ga';
import gaEventConstants from '../../constants/gaEventConstants';

const NotificationsCard = props => {
  const {
    heading,
    playVideoLink,
    title,
    description,
    thumbnailUrl,
    videoUrl,
    action,
    webUrlTarget,
    webUrl,
    closeNotificationDrawer,
    startTour,
    userId
  } = props;

  const navigate = useNavigate();

  const actionBtn = action ? action[0] : {};

  const triggerGTMForNotification = (type, url) => {
    const gtmData = {
      event: gaEventConstants.NOTIFICATION_BUTTON_CLICKED,
      notificationType: type,
      notificationUrl: url || '',
      userId
    };
    gaTriggerGTMCustomEvents(gtmData);
  };

  const playVideo = () => {
    playVideoLink(videoUrl);
    triggerGTMForNotification('video', videoUrl);
  };

  const openWebUrl = () => {
    if (webUrlTarget === 'tab') {
      loadUrlInBlankTab(webUrl);
    } else {
      loadUrlInBlankWindow(webUrl);
    }
    triggerGTMForNotification('url', webUrl);
  };

  const openTutorial = () => {
    navigate('/');
    closeNotificationDrawer();
    startTour();
    triggerGTMForNotification('tutorial');
  };

  const handleOnClickFunc = {
    video: playVideo,
    url: openWebUrl,
    tutorial: openTutorial
  };

  return (
    <div className="notification-card">
      {heading && <div className="notification-card__head">{heading}</div>}
      {thumbnailUrl && (
        <div className="notification-card__thumbnail">
          <img src={thumbnailUrl} alt={thumbnailUrl} />
        </div>
      )}
      <section className="notification-card__content">
        <Typography variant="h3">{title}</Typography>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </section>
      {actionBtn.label && actionBtn.target && (
        <div
          className="notification-card__action"
          style={{ textAlign: `${actionBtn.placement}` }}
        >
          <button
            className={actionBtn.type}
            type="button"
            onClick={handleOnClickFunc[actionBtn.target]}
            aria-label={`notification-card-${actionBtn.type}`}
          >
            {actionBtn.label}
          </button>
        </div>
      )}
    </div>
  );
};

NotificationsCard.propTypes = {
  heading: PropTypes.string,
  playVideoLink: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  webUrlTarget: PropTypes.string,
  webUrl: PropTypes.string,
  closeNotificationDrawer: PropTypes.func.isRequired,
  startTour: PropTypes.func.isRequired,
  action: PropTypes.shape({
    label: PropTypes.string
  }),
  userId: PropTypes.string.isRequired
};

export default NotificationsCard;
