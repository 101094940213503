/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import axios from 'axios';
import { counts } from '../services/axiosFetch';
import handleErrorCodes from './interceptorUtility';
import { showLoader, hideLoader } from './helper';

/**
 * disable loader based on the pending or executing parallel jobs
 * https://www.npmjs.com/package/bottleneck
 */
const disableLoader = () => {
  const count = counts();
  // if bottleneck finished executing the last job, then disable the loader
  if (count.EXECUTING === 1) {
    hideLoader();
  }
};

/**
 * Handling response error status codes using axios interceptor
 */
function interceptor() {
  axios.interceptors.request.use(request => {
    if (!request.config.backgroundProcess) {
      showLoader();
    }
    return request;
  });
  axios.interceptors.response.use(
    response => {
      if (!response.config.config.backgroundProcess) {
        disableLoader();
      }
      return response;
    },
    error => {
      // if error, hide the loader right away
      hideLoader();
      const {
        response: { status, data = {}, config = {} }
      } = error;
      let correlationId = '';
      if (config && config.headers) {
        correlationId = config.headers['x-correlation-id'];
      }
      // handling HTTP error codes with appropriate error messages
      handleErrorCodes(status, data.message, correlationId);
      return Promise.reject(error);
    }
  );
}

export default interceptor;
