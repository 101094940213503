/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import PageTour from '../../components/page-tour/PageTour';
import config from './config';
import { loadCookie } from '../../utils';
import constants from '../../constants';
import { getSlides } from '../../services/getSlides';

const PageTourWrapper = props => {
  const {
    store: {
      PageTour: { isTourStarted, stopTour, startTour, autoStart },
      UserInfo: { firstName, isAdmin, userId },
      ProjectGridView: {
        hasMoreResults,
        projects,
        loadProjectsCount: gridViewLoadProjectsCount
      },
      ProjectListView: { loadProjectsCount: listViewLoadProjectsCount },
      ProjectTableView: { loadProjectsCount: tableViewLoadProjectsCount },
      Filters: {
        showNewReleaseContent,
        sortCriteria,
        projectPublishStatus,
        projectPublishPlatform,
        searchTerm,
        mode
      }
    }
  } = props;

  const [hadProjects, setHadProjects] = useState(false);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    async function fetchAllSlides() {
      const allSlides = await getSlides();
      setSlides(allSlides);
    }
    if (isTourStarted && !slides.length) {
      fetchAllSlides();
    }
  }, [isTourStarted, slides]);

  // If user has already seen the tutorial don't start it automatically
  const alreadyShownTutorial = loadCookie(`${constants.TUTORIAL_FOR}${userId}`);

  const totalLoadProjectsAPICall =
    gridViewLoadProjectsCount +
    listViewLoadProjectsCount +
    tableViewLoadProjectsCount;

  if (
    totalLoadProjectsAPICall === 1 &&
    !hadProjects &&
    !alreadyShownTutorial &&
    autoStart &&
    !showNewReleaseContent &&
    sortCriteria === constants.SORT_DEFAULT_CRITERIA &&
    projectPublishStatus === constants.SORT_DEFAULT_CRITERIA &&
    projectPublishPlatform === constants.SORT_DEFAULT_CRITERIA &&
    searchTerm === '' &&
    hasMoreResults === false &&
    mode === constants.GRID_VIEW_MODE &&
    projects.length === 0
  ) {
    startTour();
  }

  /**
   * Onload if user has projects then save this info in state
   * so that if due to any reason like filter or search the result is 0 projects
   * we still know that user had projects.
   */
  if (!hadProjects && projects.length > 0) {
    setHadProjects(true);
  }

  const onStopTour = () => {
    stopTour(userId);
  };

  if (!isTourStarted) {
    return null;
  }

  if (!slides.length) {
    return null;
  }

  return (
    <PageTour
      start={isTourStarted}
      onStopTour={onStopTour}
      steps={config(slides, firstName, isAdmin)}
      user={userId}
    />
  );
};

PageTourWrapper.propTypes = {
  store: PropTypes.shape({
    PageTour: PropTypes.shape({
      isTourStarted: PropTypes.bool.isRequired,
      stopTour: PropTypes.func.isRequired,
      startTour: PropTypes.func.isRequired,
      autoStart: PropTypes.bool.isRequired
    }),
    UserInfo: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      isAdmin: PropTypes.bool.isRequired,
      userId: PropTypes.string.isRequired
    }),
    ProjectGridView: PropTypes.shape({
      hasMoreResults: PropTypes.bool.isRequired,
      projects: PropTypes.shape({
        length: PropTypes.number.isRequired
      }),
      loadProjectsCount: PropTypes.number.isRequired
    }),
    ProjectListView: PropTypes.shape({
      loadProjectsCount: PropTypes.number.isRequired
    }),
    ProjectTableView: PropTypes.shape({
      loadProjectsCount: PropTypes.number.isRequired
    }),
    Filters: PropTypes.shape({
      showNewReleaseContent: PropTypes.bool.isRequired,
      sortCriteria: PropTypes.string.isRequired,
      projectPublishStatus: PropTypes.string.isRequired,
      projectPublishPlatform: PropTypes.string.isRequired,
      searchTerm: PropTypes.string.isRequired,
      mode: PropTypes.string.isRequired
    })
  })
};

export default inject('store')(observer(PageTourWrapper));
