/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';

import Constants from '../constants';

const {
  DIALOG_TITLE_ERROR,
  DIALOG_TITLE_WARNING,
  DIALOG_TITLE_MESSAGE,
  DIALOG_TYPE_DEFAULT,
  DIALOG_TYPE_ERROR,
  DIALOG_TYPE_WARNING
} = Constants;

export default types
  .model('MessageDialog', {
    title: types.maybeNull(types.string),
    message: types.maybeNull(types.string),
    type: DIALOG_TYPE_DEFAULT,
    showButton: true,
    open: false
  })
  .actions(self => ({
    showMessageDialog(message, title = DIALOG_TITLE_MESSAGE) {
      self.title = title;
      self.message = message;
      self.open = true;
      self.type = DIALOG_TYPE_DEFAULT;
      self.showButton = true;
    },

    showErrorMessageDialog(message, title = DIALOG_TITLE_ERROR) {
      self.title = title;
      self.message = message;
      self.open = true;
      self.type = DIALOG_TYPE_ERROR;
      self.showButton = false;
    },

    showWarningMessageDialog(message, title = DIALOG_TITLE_WARNING) {
      self.title = title;
      self.message = message;
      self.open = true;
      self.type = DIALOG_TYPE_WARNING;
      self.showButton = false;
    },

    closeMessageDialog() {
      self.open = false;
    }
  }));
