/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { toJS } from 'mobx';
import { types } from 'mobx-state-tree';

const metaModel = {
  id: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  value: false
};

export const LobMetaItemValue = types
  .model('LobMetaItemValue', metaModel)
  .actions(self => ({
    updateValue: newValue => {
      self.value = newValue;
    }
  }));

export default types
  .model('LobMetaManager', {
    lob: types.maybeNull(types.string),
    meta: types.array(LobMetaItemValue)
  })
  .views(self => ({
    getAllCheckedMetaId() {
      const data = self.meta.filter(item => item.value);
      return data.map(item => item.id);
    }
  }))
  .actions(self => ({
    updateLob(value) {
      self.lob = value;
    },
    updateMeta(meta, masterData) {
      let data = toJS(masterData);
      data = data.map(item => ({ ...item, value: meta.includes(item.id) }));
      self.meta = data;
    },
    resetMeta() {
      self.lob = '';
      self.meta = [];
    }
  }));
