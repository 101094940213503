/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import lobConstants from '../constants/lobConstants';
import { getTaxonomies } from '../services/getTaxonomiesService';

const KeyStageItem = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.string)
});

export default types
  .model('KeyStage', {
    keyStageList: types.array(KeyStageItem)
  })
  .actions(self => ({
    getLabelByValue(value) {
      if (value) {
        const keyStageVal = self.keyStageList.find(
          keyStage => keyStage.value === value
        );
        if (keyStageVal) {
          return keyStageVal.label;
        }
      }
      return '';
    },
    /**
     * @function - Saving all the keyStage data in self object
     * @param {learning framework}
     */
    setKeyStageItems(items) {
      self.keyStageList = [];
      items.forEach(item => {
        self.keyStageList.push(item);
      });
    },
    // To fetch master list from API
    async getKeyStageItems() {
      if (self.keyStageList.length === 0) {
        const keyStageList = await getTaxonomies(
          lobConstants.UK_SCHOOLS,
          'keystage'
        );
        this.setKeyStageItems(keyStageList);
      }
    }
  }));
