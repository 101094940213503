/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { getAllBusinessUnits } from '../services/getUserBUAffiliation';

export const BusinessUnit = types.model('BusinessUnit', {
  id: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  productModel: types.maybeNull(types.string),
  destination: types.maybeNull(types.string)
});
export default types
  .model('BusinessUnits', {
    allBusinessUnits: types.array(BusinessUnit)
  })
  .actions(self => ({
    async getAllBusinessUnitList() {
      const allBusinessUnitList = await getAllBusinessUnits();
      this.setAllBusinessUnits(allBusinessUnitList);
    },

    setAllBusinessUnits(allBusinessUnitList) {
      self.allBusinessUnits = [];
      allBusinessUnitList.forEach(item => {
        self.allBusinessUnits.push(item);
      });
    }
  }));
