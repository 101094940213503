/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import Constants from '../constants';
import { redirectToLogin } from '../utils/utils';
import { showErrorPopup } from './helper';

const {
  ERROR_CODE_403_MESSAGE,
  DIALOG_TITLE_FORBIDDEN,
  ERROR_CODE_404_MESSAGE,
  DIALOG_TITLE_NOT_FOUND,
  ERROR_CODE_5XX_MESSAGE,
  DIALOG_TITLE_ERROR,
  ERROR_MESSAGE_NO_PROJECT_ACCESS,
  ACCESS_DENIED,
  ENGLISH_LOB_PERMISSION_TIMEOUT
} = Constants;

/**
 * Handling status codes and calling method to update the status in
 * @param status HTTP Status code
 */
const handleErrorCodes = (status, message, correlationId) => {
  switch (status) {
    case 401: {
      redirectToLogin();
      break;
    }
    case 403: {
      if (message === ERROR_MESSAGE_NO_PROJECT_ACCESS) {
        showErrorPopup(message, ACCESS_DENIED);
        setTimeout(async () => {
          window.location.href = '/';
        }, ENGLISH_LOB_PERMISSION_TIMEOUT);
      } else {
        showErrorPopup(
          `${ERROR_CODE_403_MESSAGE}
correlationId: ${correlationId}`,
          DIALOG_TITLE_FORBIDDEN
        );
      }
      break;
    }
    case 404: {
      showErrorPopup(
        `${ERROR_CODE_404_MESSAGE}
correlationId: ${correlationId}`,
        DIALOG_TITLE_NOT_FOUND
      );
      break;
    }
    case 500:
    case 400:
    default: {
      showErrorPopup(
        `${message || ERROR_CODE_5XX_MESSAGE}
correlationId: ${correlationId}`,
        DIALOG_TITLE_ERROR
      );
      break;
    }
  }
};

export default handleErrorCodes;
