/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

export default {
  SERVICE_DOWN_TITLE: 'Service Temporarily Unavailable',
  SERVICE_DOWN_MESSAGE1:
    'The Unified Dashboard is temporarily down for maintenance in this environment. Please check back later.',
  SERVICE_DOWN_MESSAGE2: 'Additional information:',
  SERVICE_DOWN_DEFAULT_MESSAGE: 'Dashboard API server is down'
};
