/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { getLineOfBusinessList as getLineOfBusinessService } from '../services/getLineOfBusinessList';
import LineOfBusinessItem from './LineOfBusinessItem';

export default types
  .model('LineOfBusiness', {
    lobList: types.array(LineOfBusinessItem)
  })
  .actions(self => ({
    getLabelByValue(value) {
      if (value) {
        const lobVal = self.lobList.find(
          lobItem => lobItem.aliasToLOB === value
        );
        if (lobVal) {
          return lobVal.label;
        }
      }
      return '';
    },
    /**
     * @function - Fetching LOB  data using getLineOfBusiness service
     */
    async getLineOfBusinessList() {
      if (self.lobList.length === 0) {
        const lobList = await getLineOfBusinessService();
        this.setLineOfBusiness(lobList);
      }
    },

    /**
     * @function - Saving all the lob data in self object
     * @param {lob}
     */
    setLineOfBusiness(items) {
      self.lobList = [];
      items.forEach(item => {
        self.lobList.push(item);
      });
    },

    /**
     * this will return lineOfBussiness field for given aliasToLOB
     * @param alias
     * @returns
     */
    getLobByAlias(alias) {
      const lobObject = self.lobList.find(item => item.aliasToLOB === alias);
      return lobObject ? lobObject.lineOfBusiness : '';
    }
  }));
