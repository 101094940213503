export const themesList = [
  {
    themeUrn: 'urn:pearson:theme:1b1987e5-44e6-463c-a449-3b716dd18e23',
    name: 'Gravity'
  },
  {
    themeUrn: 'urn:pearson:theme:785ed818-0efb-4959-ae80-f5481a2760a9',
    name: 'Oz'
  },
  {
    themeUrn: 'urn:pearson:theme:a4961c6e-9849-4630-b707-acb40862647f',
    name: 'GCSE Maths'
  },
  {
    themeUrn: 'urn:pearson:theme:0e94074f-a92d-4e6d-8b00-0505ffd852d8',
    name: 'GCSE English'
  },
  {
    themeUrn: 'urn:pearson:theme:f1fddd74-539b-4c57-9c14-780a9dcbfafc',
    name: 'GCSE Science'
  },
  {
    themeUrn: 'urn:pearson:theme:72a821e3-34a7-4a99-9a34-7b18df6f9f0d',
    name: 'KS1-Numeracy'
  },
  {
    themeUrn: 'urn:pearson:theme:3edafc95-c13c-4366-b2e2-6da174d83e18',
    name: 'KS1-Literacy'
  },
  {
    themeUrn: 'urn:pearson:theme:94a51d3a-92b6-440a-b665-194b10b4e784',
    name: 'KS1-Other'
  }
];

const themeValues = [
  'urn:pearson:theme:1b1987e5-44e6-463c-a449-3b716dd18e23',
  'urn:pearson:theme:785ed818-0efb-4959-ae80-f5481a2760a9'
];

export const themesForLobList = [
  { id: 'onlineblendedlearning', themes: themeValues },
  { id: 'professional', themes: [] },
  { id: 'highernationalonline', themes: [] },
  { id: 'english', themes: [] },
  { id: 'ukschools', themes: [] },
  { id: 'highereducation', themes: [] },
  { id: 'onlineenglishproficiency', themes: [] },
  { id: 'schools', themes: [] }
];
