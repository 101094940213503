/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Typography } from '@mui/material';
import {
  CustomDialog,
  DialogHeader,
  DialogContent,
  DialogActions
} from '../../components/common/Dialog';
import Constants from '../../constants';
import './ContentMetadata.style.scss';

const ContentMetadataEditBox = props => {
  const {
    open,
    closeEditBox,
    handleAddTaxonomy,
    handleEditTaxonomy,
    isAddNewBox,
    currentValue,
    selectedTaxonomyType
  } = props;

  const { CANCEL, ADD, SAVE, ADD_TAXONOMY_WARNING } = Constants;
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [textBoxValue, setTextBoxValue] = useState(currentValue);

  const resetState = () => {
    setIsSaveEnabled(false);
    setTextBoxValue('');
  };

  // reset states on unmounting
  useEffect(() => {
    return resetState;
  }, []);

  useEffect(() => {
    setTextBoxValue(currentValue);
  }, [currentValue]);

  const handleTextBoxValueChange = event => {
    const { value } = event.target;
    setTextBoxValue(value);
    if (value.trim().length === 0 || value === currentValue) {
      setIsSaveEnabled(false);
    } else {
      setIsSaveEnabled(true);
    }
  };

  /* this functions close this box and reset states */
  const cancel = () => {
    closeEditBox();
    resetState();
  };

  const handleSave = async () => {
    if (isAddNewBox) {
      await handleAddTaxonomy(textBoxValue);
    } else {
      await handleEditTaxonomy(textBoxValue);
    }
    cancel();
  };

  const dialogTitlePrefix = isAddNewBox ? 'Add New' : 'Edit';

  return (
    <CustomDialog
      onClose={cancel}
      open={open}
      className="taxonomy-edit-box-wrapper"
    >
      <DialogHeader
        onClose={cancel}
        title={`${dialogTitlePrefix} ${selectedTaxonomyType}`}
      />
      <DialogContent>
        <div className="taxonomy-edit__container">
          {isAddNewBox && (
            <Typography className="taxonomy-edit__warning">
              {ADD_TAXONOMY_WARNING}
            </Typography>
          )}
          <div className="taxonomy-edit__textbox">
            <TextField
              label="value"
              value={textBoxValue}
              onChange={handleTextBoxValueChange}
              margin="dense"
              variant="filled"
              size="small"
              fullWidth
              autoFocus
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="btn-cancel"
          className="buttonCancel"
          onClick={cancel}
          aria-label="cancel"
        >
          {CANCEL}
        </Button>
        <Button
          className="btnSave"
          data-testid="btn-save"
          disabled={!isSaveEnabled}
          onClick={handleSave}
          aria-label={isAddNewBox ? 'Add taxonomy' : 'Save taxonomy'}
        >
          {isAddNewBox ? ADD : SAVE}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

ContentMetadataEditBox.propTypes = {
  open: PropTypes.bool.isRequired,
  closeEditBox: PropTypes.func.isRequired,
  handleAddTaxonomy: PropTypes.func.isRequired,
  handleEditTaxonomy: PropTypes.func.isRequired,
  currentValue: PropTypes.string,
  selectedTaxonomyType: PropTypes.string,
  isAddNewBox: PropTypes.bool
};

export default ContentMetadataEditBox;
