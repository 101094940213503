/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

export default {
  /** ***********PREFLIGHT TOOLKIT************ */
  LAST_REPORT: 'Last report',
  REPORTS_RUN: 'Reports run',
  DOWNLOAD: 'Download',
  ACTION: 'Action',
  DOWNLOAD_REPORT: 'Download report',
  RUN_REPORT: 'RUN REPORT',
  TOTAL_REPORTED_ERRORS: 'Total reported errors',
  NO_REPORT_GENERATED: 'No report generated.',
  REPORT_GENERATION_IS_IN_PROGRESS:
    'Preflight report is being generated. <br/> Click the button above to refresh report status.',
  NO_REPORT: 'No report',
  NO_ERRORS: 'Looking good!  No errors reported.',
  NO_FILTERS_SELECTED: 'No data to be displayed. Please select any filter(s)',
  REFRESH: 'refresh_icon',
  HUGE_REPORT_GENERATED: errCntToDisplay =>
    `Preflight Report has more than ${errCntToDisplay} records. <br/> Click <b>Download Report</b> button to download and review the report.`,
  NO_REPORT_MESSAGE: 'Click <b><i>Run Report</i></b> to see results.',
  ERROR_OCCURRED_GENERATING_REPORT: 'Error occurred in generating the report',
  REPORT_TITLE: (projectTitle, date, productModel) =>
    `${projectTitle}_preflight_report_${date}_${productModel}.csv`,
  REPORT_FALIURE_MESSAGE: 'Note: Last report request was unsuccessful.',
  REPORT_CANCELLED_MESSAGE: 'Note: This report request was cancelled.',
  RERUN_REPORT_MESSAGE: 'Click <b><i>Run Report</i></b> to request a new one.',
  STATUS_COMPLETED: 'COMPLETED',
  STATUS_PENDING: 'PENDING',
  STATUS_FAILURE: 'FAILURE',
  STATUS_QUEUED: 'QUEUED',
  STATUS_CANCELLED: 'CANCELLED',
  STATUS_RUNNING: 'RUNNING',
  STATUS_INPROGRESS: 'INPROGRESS',
  STATUS_FAILED: 'FAILED',
  STATUS_ALFRESCO_ERROR: 'ERROR',
  REPORT_IN_PROGRESS: 'IN PROGRESS...',
  NOT_APPLIABLE: 'N/A',
  NO_REPORT_TIMESTAMP: 'Not Available',
  NOT_FOUND_STATUS: 'NOT_FOUND',
  STATUS_DISPLAY_VALUE_MAP: {
    INPROGRESS: 'IN PROGRESS...',
    QUEUED: 'IN QUEUE...',
    PENDING: 'PENDING...'
  },
  STATUS_DISPLAY_VALUE_MAP_FOR_UPDATE_ALL_ASSESSMENT: {
    INPROGRESS: 'in Progress',
    QUEUED: 'in Queue',
    PENDING: 'Pending'
  },
  UPDATE_ALL_IN_PROGRESS_STATUS: 'in Progress',
  UPDATE_ALL_IN_Queue_STATUS: 'in Queue',
  UPDATE_ALL_IN_Pending_STATUS: 'Pending',
  REFRESH_REPORT_HELPER_TEXT: 'Click button to refresh the status',
  EPUB_ACCESSIBILITY_REPORT: 'ePub Accessibility Report',
  /** ******************* Preflight Constants ********************** */
  DEFAULT_PREFLIGHT_RESPONSE_DATE_FORMAT: 'MM/DD/YY h:mm:ssa',
  REPORTS_DROPDOWN_DATE_FORMAT: 'lll',
  PREFLIGHT_DIALOG_HEADER_IN_PROGRESS: 'Preflight Checklist update in progress',
  PREFLIGHT_DIALOG_HEADER_REQUESTED: 'Preflight Checklist update requested',
  PREFLIGHT_REPORTS_DROPDOWN_HELPER_TEXT:
    'Previous reports are unavailable when new request is in progress.',
  PREFLIGHT_STATUS_MESSAGE: projectTitle =>
    `The Preflight Checklist for ${projectTitle} is currently in progress and will take a few minutes to complete. Please check back here to access the report.`,
  PREFLIGHT_DIALOG_BUTTON_TEXT: 'OK',
  PREFLIGHT_CHECKLIST_STATUS: 'Preflight Checklist Status',
  PREFLIGHT_CHECKLIST_RESULT: 'Preflight Checklist Results',
  PREFLIGHT_TAB_LABEL: 'PREFLIGHT CHECKLIST',
  PREFLIGHT_TAB: 'preflight',
  SHOW_HIDE_TABLE_COLUMNS: 'Show/Hide Table Columns',
  PREFLIGHT_HELP: 'Preflight Help',
  SHOW_ALL_ID: 'ShowAll',
  SHOW_ALL_LABEL: 'Show All',
  PREFLIGHT_NO_DATA_FOR_FILTER: 'No data available for selected filter(s)',
  PREFLIGHT_MUST_FIX_FILTER: 'Must Fix',
  PREFLIGHT_SHOULD_FIX_FILTER: 'Should Fix',
  PREFLIGHT_NEED_ANALYSIS_FILTER: 'Needs Analysis',
  PREFLIGHT_BETA_UAT_FILTER: 'Beta-UAT',
  PREFLIGHT_PROV_MUST_FIX_FILTER: 'Provision Must Fix',
  OF: 'of',
  SORT_OR_DRAG_LABEL: 'Click to sort or Drag to reorder',
  SEVERITY_LABEL: 'Severity',
  MATHML_TAB_LABEL: 'MATHML SCRIPT',
  ALFRESCO_TAB_LABEL: 'ALFRESCO SCRIPT',
  ASSESSMENTS_TAB_LABEL: 'ASSESSMENTS & INTERACTIVES',
  EPUB_ACCESSIBILITY_TAB_LABEL: 'ePub ACCESSIBILITY',
  WARNING: 'Warning',
  REQUEST_NOT_SUBMITTED_BC_REQUEST_IN_PROGRESS:
    'Your request was not submitted because there is already a request in progress.',

  /** ******************* Math ML Constants ********************** */
  MATHML_CHECKLIST_STATUS: 'MathML Script Status',
  MATHML_Request: 'Last Script Requested',
  MATHML_Complete: 'Last Script Completed',
  MATHML_Status: 'Status',
  MATHML_RUN_REPORT: 'RUN SCRIPT',
  MATHML_IN_PROGRESS_MESSAGE:
    '<b>DO NOT EDIT</b> version when script is in progress!',
  MATHML_RUN_SCRIPT_BUTTON_TEXT: 'CONTINUE AND RUN SCRIPT',
  MATHML_RUN_SCRIPT_HEADER: 'DO NOT EDIT version when script is in progress!',
  MATHML_DIALOG_CONTENT: `Make sure that all edits are completed before running MathML script.<br />
  Check that users are not editing while the script is running.<br /> <br />
  <b>Edits made while the script is running could result in lost data!</b>`,
  MATHML_SCRIPT_IN_PROGRESS: 'IN PROGRESS...',
  MATHML_NO_REPORT: 'No script run...',
  MATHML_TAB: 'mathML',

  /** ******************* Alfresco Script Constants ********************** */
  Alfresco_CHECKLIST_STATUS: 'Alfresco Script Status',
  ALFRESCO_TAB: 'alfrescoScript',
  ALFRESCO_SCRIPT_Request: 'Last Script Requested',
  ALFRESCO_SCRIPT_Complete: 'Last Script Completed',
  ALFRESCO_SCRIPT_Status: 'Status',
  ALFRESCO_SCRIPT_NO_REPORT: 'No script run...',
  ALFRESCO_SCRIPT_RUN_REPORT: 'RUN SCRIPT',
  ALFRESCO_SCRIPT_IN_PROGRESS_MESSAGE:
    '<b>DO NOT EDIT</b> version when script is in progress!',
  ALFRESCO_SCRIPT_RUN_SCRIPT_BUTTON_TEXT: 'CONTINUE AND RUN SCRIPT',
  ALFRESCO_SCRIPT_RUN_SCRIPT_HEADER:
    'DO NOT EDIT version when script is in progress!',
  ALFRESCO_SCRIPT_DIALOG_CONTENT: `Make sure that all edits are completed before running the Alfresco script.<br />
  Check that users are not editing while the script is running.<br /> <br />
  <b>Edits made while the script is running could result in lost data!</b>`,
  ALFRESCO_SCRIPT_IN_PROGRESS: 'IN PROGRESS...',

  /** ******************** Assessments Tab Constants ************************ */
  ASSESSMENTS_RETERIVING_DATA_ERROR:
    'We are facing problem retrieving the data. Please refresh the page to try again.',
  UNAPPROVED_ASSESSMENTS_RETRIEVAL_TAKING_LONG_TIME_MESSAGE:
    'Assessments & Interactives list is taking longer than expected to load. Please wait for some time and then click the refresh button above.',
  PENDING_UPDATE_ASSESSMENTS_RETRIEVAL_TAKING_LONG_TIME_MESSAGE:
    'Assessments list is taking longer than expected to load. Please wait for some time and then click the refresh button above.',
  NO_PENDING_APPROVAL_MESSAGE: 'You have no pending approvals',
  NO_PENDING_UPDATES_MESSAGE: 'You have no pending updates',
  UPDATE_ALL: 'Update All in Cypress',

  /** **************************EPUB_REPORT_ACCESSIBILITY TAB***************************************** */
  EPUB_ACCESSIBILITY_STATUS_MESSAGE: projectTitle =>
    `The ePub Accessibility Report for ${projectTitle} is currently in progress and will take a few minutes to complete. Please check back here to access the report.`,
  EPUB_ACCESSIBILITY_DIALOG_HEADER_REQUESTED:
    'ePub Accessibility Checklist update requested',
  EPUB_REPORT_PROCESSING_STATUS: 'processing',
  REPORT_DROPDWON_HELPER_TEXT:
    'Please select latest report from the list to check its status.'
};
