/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

export default {
  COL_TITLE_ID: 'name',
  COL_TITLE_LABEL: 'Title',
  COL_COVER_IMAGE_ID: 'thumbnail',
  COL_COVER_IMAGE_LABEL: 'Thumbnail',
  COL_AUTHOR_ID: 'authorName',
  COL_AUTHOR_LABEL: 'Author',
  COL_USERS_ID: 'userCount',
  COL_USERS_LABEL: 'Users',
  COL_CREATED_ON_ID: 'dateCreated',
  COL_CREATED_ON_LABEL: 'Created On',
  COL_STATUS_ID: 'status',
  COL_STATUS_SORT_ON_PROP: 'statusDisplayText',
  COL_STATUS_LABEL: 'Status',
  COL_DISTRIBUTION_ID: 'publishInfo',
  COL_DISTRIBUTION_SORT_ON_PROP: 'distributionDisplayText',
  COL_DISTRIBUTION_LABEL: 'Delivery Platform',
  COL_DELIVERY_PLATFORM_LABEL: 'Delivery Platform',
  COL_PUBLISHED_ON_LABEL: 'Published On',
  COL_PUBLISHED_BY_LABEL: 'Published By',
  COL_SUBMITTED_ON_ID: 'transactionDate',
  COL_SUBMITTED_ON_SORT_ON_PROP: 'submittedOnDisplayText',
  COL_SUBMITTED_ON_LABEL: 'Published On',
  COL_SUBMITTED_BY_ID: 'userApproverFirstName',
  COL_SUBMITTED_BY_LABEL: 'Published By',
  COL_PRODUCT_ID: 'productId',
  COL_PRODUCT_LABEL: 'Product ID',
  COL_DEEP_LINKS_ID: 'deepLinks',
  COL_DEEP_LINKS_LABEL: 'Deep Links',
  COL_STATISTICS_ID: 'statistics',
  COL_STATISTICS_LABEL: 'Statistics',
  COL_URN_ID: 'id',
  COL_URN_LABEL: 'URN',
  COL_SETTINGS: 'Settings',
  SHOW_ALL_ID: 'ShowAll',
  SHOW_ALL_LABEL: 'Show All',
  COL_MY_ROLE_ID: 'myRole',
  COL_MY_ROLE_LABEL: 'My Role',
  COL_MY_ROLE_CHECK_BOX_LABEL: 'my role',
  COL_DETAILS_ID: 'details',
  COL_DETAILS_LABEL: 'Details',
  DEFAULT_SORT_DIRECTION: 'asc',
  INVERSE_SORT_DIRECTION: 'desc',
  SELECT_PROJECTS_LABEL: 'select projects',
  SELECT_PROJECTS: 'selectProjects'
};
