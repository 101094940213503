/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';

export const LockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="389px"
    height="322px"
    viewBox="0 0 389 322"
    version="1.1"
  >
    <title>Image</title>
    <g
      id="UDB-4475---Not-Authorized-users-SPA"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="UDB-4475---Not-Authorized-users-SPA-001"
        transform="translate(-811.000000, -195.000000)"
      >
        <g id="Group" transform="translate(173.000000, 186.000000)">
          <g id="Image" transform="translate(638.000000, 9.000000)">
            <circle
              id="Oval"
              fillOpacity="0.06"
              fill="#007FA3"
              cx="161"
              cy="161"
              r="161"
            />
            <path
              d="M211.488802,44.6754979 C214.229607,44.0328261 217.215584,44.4357366 219.797091,46.0365591 C221.238906,46.9306457 222.454375,48.1461146 223.348462,49.58793 L223.348462,49.58793 L323.567806,211.202909 C325.168629,213.784416 325.571539,216.770393 324.928868,219.511198 C324.286196,222.252004 322.597942,224.747639 320.016435,226.348462 C318.275543,227.42801 316.267791,228 314.219345,228 L314.219345,228 L113.780655,228 C110.743089,228 107.993089,226.768783 106.002481,224.778175 C104.011872,222.787566 102.780655,220.037566 102.780655,217 C102.780655,214.951554 103.352646,212.943802 104.432194,211.202909 L104.432194,211.202909 L204.651538,49.58793 C206.252361,47.0064236 208.747996,45.3181696 211.488802,44.6754979 Z"
              id="Triangle"
              strokeOpacity="0.2"
              stroke="#007FA3"
              strokeWidth="10"
              fillOpacity="0.2"
              fill="#007FA3"
            />
            <path
              d="M269.488802,15.6754979 C272.229607,15.0328261 275.215584,15.4357366 277.797091,17.0365591 C279.238906,17.9306457 280.454375,19.1461146 281.348462,20.58793 L281.348462,20.58793 L381.567806,182.202909 C383.168629,184.784416 383.571539,187.770393 382.928868,190.511198 C382.286196,193.252004 380.597942,195.747639 378.016435,197.348462 C376.275543,198.42801 374.267791,199 372.219345,199 L372.219345,199 L171.780655,199 C168.743089,199 165.993089,197.768783 164.002481,195.778175 C162.011872,193.787566 160.780655,191.037566 160.780655,188 C160.780655,185.951554 161.352646,183.943802 162.432194,182.202909 L162.432194,182.202909 L262.651538,20.58793 C264.252361,18.0064236 266.747996,16.3181696 269.488802,15.6754979 Z"
              id="Triangle"
              stroke="#007FA3"
              strokeWidth="10"
              fill="#FFFFFF"
            />
            <path
              d="M272.295455,156 C276.324623,156 279.590909,159.266286 279.590909,163.295455 C279.590909,167.324623 276.324623,170.590909 272.295455,170.590909 C268.266286,170.590909 265,167.324623 265,163.295455 C265,159.266286 268.266286,156 272.295455,156 Z M272.119318,64 C276.89319,64 280.763178,67.8699878 280.763178,72.6438597 C280.763178,72.7980761 280.759051,72.9522649 280.750801,73.1062605 L276.922895,144.560506 C276.786122,147.113596 274.676069,149.113636 272.119318,149.113636 C269.562567,149.113636 267.452514,147.113596 267.315741,144.560506 L263.487835,73.1062605 C263.232458,68.3392242 266.889881,64.2677538 271.656917,64.0123769 C271.810913,64.0041271 271.965102,64 272.119318,64 Z"
              id="Rectangle-2"
              fill="#007FA3"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
