/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import MuiDialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip } from '@mui/material';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import Typography from '@mui/material/Typography';
import Constants from '../../../constants';
import './Dialog.style.scss';

/**
 * Render Dialog DialogHeader.
 * onClose - Callback function to close the dialog
 */
const DialogHeader = props => {
  const {
    onClose,
    title,
    showBeta,
    toolTip,
    children,
    downloadPdf,
    ...otherProps
  } = props;
  return (
    <MuiDialogTitle onClose={onClose} {...otherProps}>
      <Typography className="dialog-heading" title={toolTip}>
        {title}
        {showBeta && <sup>{Constants.BETA}</sup>}
      </Typography>
      {downloadPdf && (
        <Tooltip title={Constants.PRINTER_FRIENDLY_VERSION}>
          <IconButton
            data-testid="download-pdf-icon"
            aria-label="download-icon"
            className="download-icon"
            onClick={downloadPdf}
            size="large"
          >
            <PrintOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
      <div className="btn-close">
        {children}
        <IconButton
          data-testid="btn-close"
          aria-label="dialog-close"
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </div>
    </MuiDialogTitle>
  );
};

DialogHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  showBeta: PropTypes.bool,
  children: PropTypes.node.isRequired,
  toolTip: PropTypes.string,
  downloadPdf: PropTypes.func
};

export default DialogHeader;
