/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { getItemBanksList } from '../services/getItemBank';
import { getAssociatedItemBank } from '../services/getAssociatedItemBank';
import { addItemBank } from '../services/addItemBank';
import ItemBank from './ItemBank';

export default types
  .model('ItemBanks', {
    selectedItemBank: '',
    selectedItemBankName: '',
    itemBanks: types.array(ItemBank)
  })
  .actions(self => ({
    updateSelectedItemBank(itemBankId, itemBankName) {
      self.selectedItemBank = itemBankId;
      self.selectedItemBankName = itemBankName;
    },
    /**
     * @function - Fetching Item Banks  data using getItemBanks service
     */
    async getItemBanks() {
      const items = await getItemBanksList();
      this.setItemBanks(items);
    },

    async getProjectAssociatedItemBankService(entityUrn) {
      const associatedItemBank = await getAssociatedItemBank(entityUrn);
      return associatedItemBank[0];
    },

    async addItemBankToProject(payload) {
      return addItemBank(payload);
    },

    /**
     * @function - Saving all the ItemBank data in self object
     * @param {items}
     */
    setItemBanks(items) {
      items.forEach(item => {
        self.itemBanks.push(item);
      });
    }
  }));
