/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import { axiosFetch } from './axiosFetch';
import API_CONSTANTS from '../constants/apiConstants';
import { getHeaders, parseURLParams, urlModifier } from '../utils';

export const getAssociatedLearningFrameworkList = async dURN => {
  const replaceDURN = { '%dURN%': dURN };
  const url = parseURLParams(
    replaceDURN,
    API_CONSTANTS.GET_ASSOCIATED_LEARNING_FRAMEWORK_ON_PROJECT
  );
  const apiPath = urlModifier(url);
  const reqOptions = {
    ...getHeaders()
  };

  const response = await axiosFetch(reqOptions, apiPath);
  return response.data;
};
