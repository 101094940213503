/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import DialogHeader from './DialogHeader';
import DialogContent from './DialogContent';
import DialogActions from './DialogActions';
import constants from '../../../constants';

/**
 * Render Dialog with sent children.
 */
const CustomDialog = props => {
  const { onClose, open, children, className, ...otherProps } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === constants.BACK_DROP_CLICK) {
      return;
    }

    onClose();
  };

  return (
    <Dialog
      data-testid="dialog"
      onClose={handleClose}
      open={open || false}
      className={`dialog-wrapper ${className}`}
      {...otherProps}
    >
      {children}
    </Dialog>
  );
};

CustomDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export { CustomDialog, DialogHeader, DialogContent, DialogActions };
