/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import { axiosFetch } from './axiosFetch';
import {
  urlModifier,
  getHeaders,
  parseURLParams,
  postHeaders,
  patchHeaders
} from '../utils';
import API_CONSTANTS from '../constants/apiConstants';

export const getTaxonomies = async (lob, taxonomyType, useV2 = false) => {
  const paramsToReplace = {
    '%lobId%': lob,
    '%taxonomyType%': taxonomyType
  };

  const rawUrl = useV2
    ? API_CONSTANTS.GET_TAXONOMIES_V2
    : API_CONSTANTS.GET_TAXONOMIES;

  const url = parseURLParams(paramsToReplace, rawUrl);
  const apiPath = urlModifier(url);

  const reqOptions = {
    ...getHeaders()
  };

  const response = await axiosFetch(reqOptions, apiPath);
  return response.data;
};

export const createNewTaxonomy = async (lob, taxonomyType, context, label) => {
  const paramsToReplace = {
    '%lobId%': lob,
    '%taxonomyType%': taxonomyType,
    '%context%': context
  };

  const url = parseURLParams(
    paramsToReplace,
    API_CONSTANTS.CREATE_NEW_TAXONOMY
  );
  const apiPath = urlModifier(url);

  const reqOptions = {
    ...postHeaders(),
    body: {
      label
    }
  };

  const response = await axiosFetch(reqOptions, apiPath);
  return response.data;
};

export const updateTaxonomy = async (lob, taxonomyType, context, body) => {
  const paramsToReplace = {
    '%lobId%': lob,
    '%taxonomyType%': taxonomyType,
    '%context%': context
  };

  const url = parseURLParams(
    paramsToReplace,
    API_CONSTANTS.CREATE_NEW_TAXONOMY
  );
  const apiPath = urlModifier(url);

  const reqOptions = {
    ...patchHeaders(),
    body
  };

  const response = await axiosFetch(reqOptions, apiPath);
  return response.data;
};

export const getTaxonomiesTypes = async lob => {
  const paramsToReplace = {
    '%lobId%': lob
  };
  const url = parseURLParams(
    paramsToReplace,
    API_CONSTANTS.GET_TAXONOMIES_TYPES
  );
  const apiPath = urlModifier(url);

  const reqOptions = {
    ...getHeaders()
  };

  const response = await axiosFetch(reqOptions, apiPath);
  return response.data;
};
