/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */
/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import LearningFrameworkItem from './LearningFrameworkItem';
import { getAssociatedLearningFrameworkList } from '../services/getAssociatedLearningFramework';

export default types
  .model('LearningFramework', {
    frameworkList: types.array(LearningFrameworkItem),
    associatedLearningFrameworks: types.array(LearningFrameworkItem)
  })
  .actions(self => ({
    /**
     * @function - Saving all the framework data in self object
     * @param {learning framework}
     */
    setLearningFramework(items) {
      items.forEach(item => {
        self.frameworkList.push(item);
      });
    },

    // get Associated LF items list
    async getAssociatedLearningFrameworkList(dURN) {
      const associatedLFList = await getAssociatedLearningFrameworkList(dURN);
      this.updatedAssociatedLearningFramework(associatedLFList);
    },
    // update label value of associated LF item
    clearLearningFramework() {
      self.frameworkList = [];
    },

    updatedAssociatedLearningFramework(learningFrameworkItems) {
      self.associatedLearningFrameworks = [];
      learningFrameworkItems.forEach(item => {
        const newItem = { label: item.label, id: item.urn || item.id };
        self.associatedLearningFrameworks.push(newItem);
      });
    },

    removeAssociatedLearningFramework(learningFrameworkUrn) {
      const updatedLFs = self.associatedLearningFrameworks.filter(
        item => item.id !== learningFrameworkUrn
      );
      self.associatedLearningFrameworks = updatedLFs;
      return updatedLFs;
    }
  }));
