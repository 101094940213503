/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import { axiosFetch } from './axiosFetch';
import { urlModifier, getHeaders, postHeaders } from '../utils';
import apiConstants from '../constants/apiConstants';

export const getUserPreferenceMasterData = async () => {
  const apiPath = urlModifier(apiConstants.GET_MASTER_DATA_FOR_PREFERENCES);
  const reqOptions = {
    ...getHeaders()
  };

  const { data } = await axiosFetch(reqOptions, apiPath);
  return data;
};

export const getUserPreference = async () => {
  const apiPath = urlModifier(apiConstants.GET_USER_PREFERENCE);
  const reqOptions = {
    ...getHeaders()
  };

  const { data } = await axiosFetch(reqOptions, apiPath);
  return data;
};

export const saveUserPrefernce = async preference => {
  const apiPath = urlModifier(apiConstants.SAVE_USER_PREFERENCE);
  const reqOptions = {
    ...postHeaders(),
    body: { ...preference }
  };

  const { data } = await axiosFetch(reqOptions, apiPath);
  return data;
};
