/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { getUserInfo as getUserInfoService } from '../services/getUserInfo';
import { getUserBUAffiliation } from '../services/getUserBUAffiliation';
import Constants from '../constants';

export const userBusinessUnitPreference = types.model(
  'userBusinessUnitPreference',
  {
    id: types.maybeNull(types.string),
    label: types.maybeNull(types.string),
    active: types.maybeNull(types.boolean),
    locked: types.maybeNull(types.boolean)
  }
);

export default types
  .model('UserInfo', {
    adGroup: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    lastModifiedBy: types.maybeNull(types.string),
    lastModifiedDate: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    roleId: types.maybeNull(types.string),
    userId: types.maybeNull(types.string),
    uuid: types.maybeNull(types.string),
    isAuthorized: true,
    userBusinessUnitsPreferences: types.array(userBusinessUnitPreference)
  })
  .views(self => ({
    get fullName() {
      return `${self.firstName} ${self.lastName}`;
    },
    get isAdmin() {
      return self.roleId && self.roleId.toLowerCase() === Constants.ROLE_ADMIN;
    },
    get userPreferencesData() {
      const preferencesFormatedData = {};
      self.userBusinessUnitsPreferences.forEach(v => {
        preferencesFormatedData[v.id] = v;
      });
      return preferencesFormatedData;
    }
  }))
  .actions(self => ({
    async getUserInfo() {
      const {
        status,
        headers,
        data: userInfo = {}
      } = await getUserInfoService();
      if (status === 403) {
        self.setAuth(false);
        const userDetails =
          headers &&
          headers['x-prsn-user-detail'] &&
          JSON.parse(headers['x-prsn-user-detail']);
        if (userDetails) {
          self.setFirstName(userDetails.givenName);
          self.setEmail(userDetails.mail);
        }
      }
      this.setUserInfo(userInfo);
      return { status, headers, data: userInfo };
    },

    setAuth(value) {
      self.isAuthorized = value;
    },

    setUserInfo(userInfo) {
      const userInfoKeys = Object.keys(userInfo);
      userInfoKeys.forEach(key => {
        self[key] = userInfo[key];
      });
    },

    setFirstName(name) {
      self.firstName = name;
    },

    setEmail(email) {
      self.email = email;
    },

    async getUserBUAffiliation() {
      const userBUPreference = await getUserBUAffiliation(self.userId);
      this.setUserBUAffiliation(userBUPreference);
    },

    setUserBUAffiliation(preferences) {
      self.userBusinessUnitsPreferences = [];
      preferences.forEach(item => {
        self.userBusinessUnitsPreferences.push(item);
      });
    }
  }));
