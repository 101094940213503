/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { getPermissions } from '../services/getPermissionsData';
import { sortByPropAndDirection } from '../utils';

const Permission = types.model('Permission', {
  permissionId: types.maybeNull(types.string),
  label: types.maybeNull(types.string)
});

export default types
  .model('Permissions', {
    permissions: types.array(Permission)
  })
  .actions(self => ({
    async getPermissions() {
      const permissions = await getPermissions();

      this.setPermission(
        permissions.sort(sortByPropAndDirection('label', 'asc'))
      );
    },

    setPermission(permissions) {
      permissions.forEach(permission => self.permissions.push(permission));
    },

    isPermissionExist(permission) {
      return self.permissions.find(value => value.permissionId === permission);
    }
  }));
