/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState } from 'react';
import { Typography, IconButton, Grid, Paper, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Link from '@mui/icons-material/Link';
import { Edit, Delete } from '@mui/icons-material';
import { PropTypes } from 'mobx-react';
import VideoDialog from '../../containers/video-tutorial/VideoTutorial';
import ImageDialog from './ImageDialog';
import constants from '../../constants';
import NoImageFound from '../../../public/images/no_image_available.jpg';
import { loadUrlInBlankTab, loadUrlInBlankWindow } from '../../utils/utils';

const NotificationAndTutorialCardBox = props => {
  const {
    data,
    handleEdit,
    handleDelete,
    draggable,
    onDragStart,
    onDragOver,
    onDrop
  } = props;

  const {
    resourceId,
    thumbnailUrl,
    heading = '',
    title = '',
    description
  } = data;

  const [openVideoDialog, setOpenVideoDialog] = useState(false);

  const handlePreviewVideo = () => {
    setOpenVideoDialog(true);
  };

  const handleVideoDialogClose = () => {
    setOpenVideoDialog(false);
  };

  const handlePreviewLink = () => {
    if (data.webUrlTarget === 'tab') {
      loadUrlInBlankTab(data.webUrl);
    } else {
      loadUrlInBlankWindow(data.webUrl);
    }
  };

  const { target } = data.action[0];
  return (
    <div
      className="card-container"
      key={resourceId}
      draggable={draggable}
      onDragStart={onDragStart({ id: resourceId })}
      onDragOver={onDragOver({ id: resourceId })}
      onDrop={onDrop({ id: resourceId })}
      data-testid="card-container"
    >
      <Paper className="paper-container">
        <Grid item container xs={12} spacing={2} id="notificationCard">
          <Grid item className="NT-image" container direction="column">
            <ImageDialog
              thumbnailUrl={thumbnailUrl}
              NoImageFound={NoImageFound}
            />
          </Grid>
          <Grid item xs={5} container direction="column" className="wrap-text">
            <Grid item xs className="NT-description">
              <Typography gutterBottom variant="body2" className="head">
                {heading}
              </Typography>
              <Typography gutterBottom variant="body2" className="title">
                {title}
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                className="description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Grid>
          </Grid>
          <Grid item xs={1} container direction="column">
            <IconButton
              aria-label="NT-card-edit"
              size="small"
              className="btn-edit"
              data-testid="btn-edit"
              onClick={() => handleEdit(data)}
            >
              <Edit />
            </IconButton>
          </Grid>
          <Grid item xs={1} container direction="column">
            <IconButton
              aria-label="NT-card-delete"
              size="small"
              data-testid="btn-delete"
              onClick={() => handleDelete(data)}
            >
              <Delete />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item container xs={12}>
          <Grid item container xs={10.5} direction="column" />
          <Grid item container xs={1} direction="column">
            {target === 'video' && data.videoUrl && (
              <Tooltip title={constants.PREVIEW_VIDEO}>
                <IconButton
                  aria-label="NT-card-preview-video"
                  size="small"
                  onClick={handlePreviewVideo}
                  data-testid="preview-video"
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            )}

            {target === 'url' && data.webUrl && (
              <Tooltip title={constants.PREVIEW_LINK}>
                <IconButton
                  aria-label="NT-card-preview-link"
                  size="small"
                  onClick={handlePreviewLink}
                  data-testid="preview-link"
                >
                  <Link />
                </IconButton>
              </Tooltip>
            )}

            {target === 'tutorial' && (
              <span className="tutorial-notification">T</span>
            )}
          </Grid>
        </Grid>
      </Paper>
      {openVideoDialog && (
        <VideoDialog
          videoLink={data.videoUrl}
          handleClose={handleVideoDialogClose}
          name="notification-card"
        />
      )}
    </div>
  );
};

NotificationAndTutorialCardBox.propTypes = {
  data: PropTypes.objectOf(PropTypes.string),
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  draggable: PropTypes.boolean,
  onDragStart: PropTypes.func,
  onDragOver: PropTypes.func,
  onDrop: PropTypes.func
};

export default NotificationAndTutorialCardBox;
