/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import { axiosFetch } from './axiosFetch';
import API_CONSTANTS from '../constants/apiConstants';
import { urlModifier, getHeaders, parseURLParams } from '../utils';

export const getAssociatedItemBank = async entityUrn => {
  const replaceEntityUrn = { '%entityurn%': entityUrn };
  const url = parseURLParams(
    replaceEntityUrn,
    API_CONSTANTS.ASSOCIATED_ITEM_BANK
  );

  const apiPath = urlModifier(url);
  const reqOptions = {
    ...getHeaders()
  };

  // don't show error if 404 is received from api- 404 would indicate that no Item bank is attached to a project
  const errorHandlingProps = {
    skipError: true,
    codes: [404]
  };

  // adding Timestamp in params so that result is not cached
  const timestamp = new Date().toISOString();

  const response = await axiosFetch(
    reqOptions,
    apiPath,
    { timestamp },
    errorHandlingProps
  );
  return response.data;
};
