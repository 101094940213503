/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { getFileUrls } from '../services/getFileUrls';

export default types
  .model('FileUrls', {
    REQUEST_AD_GROUP_PDF: types.maybeNull(types.string),
    RULES_FOR_CYPRESS_EDITING: types.maybeNull(types.string)
  })
  .views(self => ({
    get getKeys() {
      return Object.keys(self).map(key => {
        return { label: key, value: key };
      });
    }
  }))
  .actions(self => ({
    async loadFileUrls() {
      const data = await getFileUrls();
      self.updateFileUrls(data);
    },
    updateFileUrls(data) {
      data.forEach(url => {
        self[url.key] = url.value;
      });
    }
  }));
