/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Drawer } from '@mui/material';

import UserDetail from '../user-detail/UserDetail';
import NotificationsWrapper from './NotificationsWrapper';

import './NotificationDrawer.style.scss';

const NotificationDrawer = props => {
  const {
    store: {
      NotificationDrawer: { closeNotificationDrawer, isNotificationDrawerOpen }
    }
  } = props;

  return (
    <Drawer
      open={isNotificationDrawerOpen}
      onClose={closeNotificationDrawer}
      anchor="right"
      ModalProps={{
        BackdropProps: {
          invisible: true
        },
        disableEnforceFocus: true
      }}
    >
      <div className="notification-drawer">
        <UserDetail />
        <NotificationsWrapper />
      </div>
    </Drawer>
  );
};

NotificationDrawer.propTypes = {
  store: PropTypes.shape({
    NotificationDrawer: PropTypes.shape({
      closeNotificationDrawer: PropTypes.func.isRequired,
      isNotificationDrawerOpen: PropTypes.bool.isRequired
    })
  })
};

export default inject('store')(observer(NotificationDrawer));
