/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  List,
  MenuItem,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch
} from '@mui/material';
import Constants from '../../constants';

const CypressRulesAndWorkFlow = props => {
  const {
    store: {
      LineOfBusiness: { lobList },
      CypressRules: { cypressRulesForLob, updateCypressRulesForLob },
      LobWorkflow: { workFlowRolesForLob, updateWorkFlowRolesForLob }
    },
    closeLobManagerDialog,
    selectedTab
  } = props;

  const [lob, setLob] = useState('');
  const [rulesForSelectedLob, setRulesForSelectedLob] = useState([]);
  const [rulesProp, setRulesProp] = useState('');
  const [rulesForSelectedTab, setRulesForSelectedTab] = useState({});
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const resetStates = () => {
    setLob('');
    setRulesForSelectedLob([]);
    setIsSaveEnabled(false);
  };

  // According to the selected tab setting the rules to display
  useEffect(() => {
    resetStates();
    if (selectedTab === 1) {
      setRulesForSelectedTab(workFlowRolesForLob);
      setRulesProp('workflow');
    } else {
      setRulesForSelectedTab(cypressRulesForLob);
      setRulesProp('elementPermissions');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const handleToggle = (event, id) => {
    const permission = {};
    Object.keys(rulesForSelectedLob).map(item => {
      if (item === id) {
        permission[item] = event.target.checked;
      } else {
        permission[item] = rulesForSelectedLob[item];
      }
      return permission;
    });

    let ruleAfterUpdate;
    // rulesAfterUpdate for cypressrules/ workflowroles
    if (selectedTab === 1) {
      ruleAfterUpdate = {
        lobId: lob,
        workflow: permission
      };
    } else {
      ruleAfterUpdate = {
        lobId: lob,
        elementPermissions: permission
      };
    }
    const ruleBeforeUpdate = rulesForSelectedTab.filter(
      rule => rule.lobId === lob
    );

    setIsSaveEnabled(
      JSON.stringify(ruleBeforeUpdate[0]) !== JSON.stringify(ruleAfterUpdate)
    );

    setRulesForSelectedLob(ruleAfterUpdate[rulesProp]);
  };
  // rendering cypress rules/workflow roles list
  const renderRules = () => {
    // setting default rule for cypressrules/workflowr taking the properties of first item in the list
    const defaultRule = {};
    const rules =
      selectedTab === 1
        ? workFlowRolesForLob[0].workflow
        : cypressRulesForLob[0].elementPermissions;

    Object.keys(rules).forEach(item => {
      defaultRule[item] = false;
    });

    const rulesToRender = lob ? rulesForSelectedLob : defaultRule;
    return (
      rulesToRender &&
      Object.keys(rulesToRender).map(item => {
        const id = item;
        const val = rulesToRender[item];
        let label = item;

        // insert a space before all caps
        label = label.replace(/([A-Z])/g, ' $1');
        // uppercase the first character
        // eslint-disable-next-line func-names
        label = label.replace(/^./, function (str) {
          return str.toUpperCase();
        });

        return (
          <ListItem key={id}>
            <ListItemText
              primary={label}
              className={!lob ? 'work-flow-text' : ''}
            />
            <ListItemSecondaryAction>
              <Switch
                edge="end"
                onChange={event => handleToggle(event, id)}
                checked={val}
                data-testid={label}
                disabled={!lob}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })
    );
  };

  const handleCancel = () => {
    resetStates();
    closeLobManagerDialog();
  };

  const handleLobChange = event => {
    const selectedLob = event.target.value;
    setLob(selectedLob);
    const rules = rulesForSelectedTab.filter(rule => {
      return rule.lobId === selectedLob;
    });
    if (rules && rules[0]) {
      setRulesForSelectedLob(rules[0][rulesProp]);
    } else {
      setRulesForSelectedLob([]);
    }
    setIsSaveEnabled(false);
  };

  const handleSave = async () => {
    // setting API call for cypressrules/workflow
    const updateRules =
      selectedTab === 1 ? updateWorkFlowRolesForLob : updateCypressRulesForLob;
    await updateRules(rulesForSelectedLob, lob);
    setIsSaveEnabled(false);
  };

  const {
    SAVE,
    CANCEL,
    SELECT_LOB,
    NO_WORKFLOW_ROLE_TEXT,
    NO_CYPRESS_RULE_TEXT
  } = Constants;

  // setting no rule available text for cypressrules/ workflow
  const noRulesText =
    selectedTab === 1 ? NO_WORKFLOW_ROLE_TEXT : NO_CYPRESS_RULE_TEXT;

  return (
    <div className="lob-modules-wrapper">
      <div>
        <div>
          <TextField
            select
            inputProps={{
              'data-testid': 'cypress-rules-input',
              'aria-label': 'cypress-rules-input'
            }}
            label={SELECT_LOB}
            value={lob}
            onChange={handleLobChange}
            margin="dense"
            variant="filled"
            size="small"
            SelectProps={{
              SelectDisplayProps: {
                'data-testid': 'cypress-rules-dropdown-select'
              },
              MenuProps: {
                container: this,
                className: 'workflow-dropdown-menu'
              }
            }}
          >
            {lobList &&
              lobList.map(opt => (
                <MenuItem
                  key={opt.label}
                  value={opt.aliasToLOB}
                  name={opt.label}
                >
                  {opt.label}
                </MenuItem>
              ))}
          </TextField>
        </div>
        {rulesForSelectedLob.length === 0 && lob && (
          <div className="no-rules">{noRulesText}</div>
        )}
        <List className="lob-module-list">{renderRules()}</List>
      </div>

      <div className="lob-module-action">
        <div>
          <Button
            className="btnCancel"
            data-testid="btn-cancel"
            onClick={handleCancel}
          >
            {CANCEL}
          </Button>

          <Button
            onClick={handleSave}
            className="btnSave"
            data-testid="btn-save"
            disabled={!isSaveEnabled}
          >
            {SAVE}
          </Button>
        </div>
      </div>
    </div>
  );
};

CypressRulesAndWorkFlow.propTypes = {
  store: PropTypes.shape({
    LineOfBusiness: PropTypes.shape({
      getLineOfBusinessList: PropTypes.func,
      lobList: PropTypes.arrayOf(
        PropTypes.shape({
          lineOfBussiness: PropTypes.string
        })
      )
    }),
    CypressRules: PropTypes.shape({
      cypressRules: PropTypes.shape({
        cypressRuleId: PropTypes.string,
        label: PropTypes.string
      }),
      cypressRulesForLob: PropTypes.arrayOf(
        PropTypes.shape({
          lobId: PropTypes.string,
          elementPermissions: PropTypes.objectOf(PropTypes.string)
        })
      ),
      updateCypressRulesForLob: PropTypes.func
    }),
    LobWorkflow: PropTypes.shape({
      workFlowRolesForLob: PropTypes.arrayOf(
        PropTypes.shape({
          lobId: PropTypes.string,
          workflow: PropTypes.objectOf(PropTypes.string)
        })
      ),
      updateWorkFlowRolesForLob: PropTypes.func
    })
  }),
  closeLobManagerDialog: PropTypes.func,
  selectedTab: PropTypes.number
};

export default inject('store')(observer(CypressRulesAndWorkFlow));
