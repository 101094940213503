/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';

const CustomInifiniteScroll = props => {
  const {
    children,
    loadMore,
    hasMore,
    pageIndex,
    getScrollParent,
    useWindow,
    element,
    threshold
  } = props;

  /**
   * Loading projects till the time scrollbar is not visible
   */
  useEffect(() => {
    const scrollbarVisible = el => {
      if (el === 'body') {
        return document.body.clientHeight + threshold > window.innerHeight;
      }
      return el.scrollHeight > el.clientHeight + threshold;
    };

    /* istanbul ignore else  */
    if (pageIndex > 1) {
      const el = getScrollParent ? getScrollParent() : 'body';
      /* istanbul ignore else  */
      if (!scrollbarVisible(el) && hasMore) {
        loadMore();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  return (
    <InfiniteScroll
      data-testid="infiniteScroll"
      className="infinite-scroll-container"
      loadMore={loadMore}
      hasMore={hasMore}
      useWindow={useWindow}
      element={element}
      getScrollParent={getScrollParent}
      threshold={threshold}
    >
      {children}
    </InfiniteScroll>
  );
};

CustomInifiniteScroll.propTypes = {
  children: PropTypes.node.isRequired,
  loadMore: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  getScrollParent: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  useWindow: PropTypes.bool.isRequired,
  element: PropTypes.node.isRequired,
  threshold: PropTypes.number.isRequired
};

export default CustomInifiniteScroll;
