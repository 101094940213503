/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import { axiosFetch } from './axiosFetch';
import API_CONSTANTS from '../constants/apiConstants';
import { urlModifier, getHeaders, parseURLParams } from '../utils';

/**
 * Retrive project and its versions
 * @param {*} distributableUrn
 * @param {*} entityUrn
 */
export const getProjectWithVersionInfo = async (
  distributableUrn,
  entityUrn
) => {
  const replaceDisributableUrnVal = {
    '%dURN%': distributableUrn,
    '%eURN%': entityUrn
  };
  const url = parseURLParams(
    replaceDisributableUrnVal,
    API_CONSTANTS.GET_PROJECT_BY_DURN_ENTITYURN
  );
  const apiPath = urlModifier(url);
  const reqOptions = {
    ...getHeaders()
  };

  const response = await axiosFetch(reqOptions, apiPath);
  return response.data;
};

/**
 * Retrieve the project details using distributable urn
 * @param {*} distributableUrn
 */
export const getProjectByDurn = async distributableUrn => {
  const replaceDisributableUrnVal = {
    '%dURN%': distributableUrn
  };
  const url = parseURLParams(
    replaceDisributableUrnVal,
    API_CONSTANTS.GET_PROJECT_BY_DURN
  );
  const apiPath = urlModifier(url);
  const reqOptions = {
    ...getHeaders()
  };

  const response = await axiosFetch(reqOptions, apiPath);
  return response.data;
};
