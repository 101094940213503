/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import constants from '../../../constants';
import './Arrow.style.scss';

const Arrow = props => {
  const { direction, onClick, testId, name } = props;

  const handleClick = () => {
    onClick();
  };

  return (
    <div className="caraousel-arrow__container">
      <IconButton
        className="caraousel-arrow"
        onClick={handleClick}
        data-testid={testId}
        aria-label={`${name}-arrow`}
        size="large"
      >
        {direction === constants.ARROW_DIRECTION_RIGHT ? (
          <ArrowForwardIosOutlinedIcon />
        ) : (
          <ArrowBackIosOutlinedIcon />
        )}
      </IconButton>
    </div>
  );
};

Arrow.propTypes = {
  direction: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default Arrow;
