/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import { axiosFetch } from './axiosFetch';
import apiConstants from '../constants/apiConstants';
import { parseURLParams, getHeaders } from '../utils';

export const getAlfrescoSiteIdByNodeRef = async nodeRef => {
  const replacement = { '%nodeRef%': nodeRef };
  const url = parseURLParams(replacement, apiConstants.GET_ALFRESCO_SITE_ID);
  const reqOptions = {
    ...getHeaders()
  };

  const response = await axiosFetch(reqOptions, url);
  return response.data;
};
