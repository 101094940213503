/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import { axiosFetch } from './axiosFetch';
import API_CONSTANTS from '../constants/apiConstants';
import { urlModifier, getHeaders, parseURLParams } from '../utils';

export const validateProductId = async (
  productId,
  entityUrn,
  checkOnlyDuplicacy = false
) => {
  const replacement = {
    '%productId%': productId,
    '%entityUrn%': entityUrn
  };
  const url = parseURLParams(replacement, API_CONSTANTS.VALIDATE_PRODUCT_ID);

  const apiPath = urlModifier(url);

  const reqOptions = {
    ...getHeaders()
  };

  // don't show error if 400 or 404  is received from api- this would be handled in code
  const errorHandlingProps = {
    skipError: true,
    codes: [400, 404, 422]
  };

  const response = await axiosFetch(
    reqOptions,
    apiPath,
    { checkOnlyDuplicacy },
    errorHandlingProps
  );
  return response.data;
};
