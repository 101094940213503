/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import TableModeConstants from '../constants/tableModeConstants';
import TableModeColumn from './TableModeColumn';
import constants from '../constants';

const tableModeColumns = [
  {
    id: TableModeConstants.COL_STATUS_ID,
    sortOn: TableModeConstants.COL_STATUS_SORT_ON_PROP,
    label: TableModeConstants.COL_STATUS_LABEL,
    visible: true,
    mandatory: true,
    draggable: true
  },
  {
    id: TableModeConstants.COL_DISTRIBUTION_ID,
    sortOn: TableModeConstants.COL_DISTRIBUTION_SORT_ON_PROP,
    label: TableModeConstants.COL_DISTRIBUTION_LABEL,
    visible: true,
    mandatory: true,
    draggable: true
  },
  {
    id: TableModeConstants.COL_COVER_IMAGE_ID,
    label: TableModeConstants.COL_COVER_IMAGE_LABEL,
    visible: true,
    draggable: true
  },
  {
    id: TableModeConstants.COL_AUTHOR_ID,
    sortOn: TableModeConstants.COL_AUTHOR_ID,
    label: TableModeConstants.COL_AUTHOR_LABEL,
    visible: true,
    mandatory: true,
    draggable: true
  },
  {
    id: TableModeConstants.COL_TITLE_ID,
    sortOn: TableModeConstants.COL_TITLE_ID,
    label: TableModeConstants.COL_TITLE_LABEL,
    visible: true,
    mandatory: true,
    draggable: true
  },
  {
    id: TableModeConstants.COL_PRODUCT_ID,
    sortOn: TableModeConstants.COL_PRODUCT_ID,
    label: TableModeConstants.COL_PRODUCT_LABEL,
    visible: true,
    draggable: true
  },
  {
    id: TableModeConstants.COL_URN_ID,
    sortOn: TableModeConstants.COL_URN_ID,
    label: TableModeConstants.COL_URN_LABEL,
    visible: true,
    draggable: true
  },
  {
    id: TableModeConstants.COL_USERS_ID,
    sortOn: TableModeConstants.COL_USERS_ID,
    label: TableModeConstants.COL_USERS_LABEL,
    visible: true,
    draggable: true
  },
  {
    id: TableModeConstants.COL_CREATED_ON_ID,
    sortOn: TableModeConstants.COL_CREATED_ON_ID,
    label: TableModeConstants.COL_CREATED_ON_LABEL,
    visible: true,
    draggable: true
  },
  {
    id: TableModeConstants.COL_SUBMITTED_BY_ID,
    sortOn: TableModeConstants.COL_SUBMITTED_BY_ID,
    label: TableModeConstants.COL_SUBMITTED_BY_LABEL,
    visible: true,
    draggable: true
  },
  {
    id: TableModeConstants.COL_SUBMITTED_ON_ID,
    sortOn: TableModeConstants.COL_SUBMITTED_ON_SORT_ON_PROP,
    label: TableModeConstants.COL_SUBMITTED_ON_LABEL,
    visible: true,
    draggable: true
  },
  {
    id: TableModeConstants.COL_STATISTICS_ID,
    label: TableModeConstants.COL_STATISTICS_LABEL,
    visible: true,
    draggable: true
  },
  {
    id: TableModeConstants.COL_DEEP_LINKS_ID,
    label: TableModeConstants.COL_DEEP_LINKS_LABEL,
    visible: true,
    draggable: true
  }
];

export default types
  .model('TableModeSettings', {
    tableModeColumns: types.optional(types.array(TableModeColumn), [
      ...tableModeColumns
    ]),
    columnToSort: types.maybeNull(types.string),
    sortDirection: TableModeConstants.DEFAULT_SORT_DIRECTION,
    serverSideSort: types.optional(types.array(types.string), [
      TableModeConstants.COL_AUTHOR_ID,
      TableModeConstants.COL_TITLE_ID
    ]),
    columnOrder: types.maybeNull(types.array(types.number)),
    isMyRoleSelected: false
  })
  .actions(self => ({
    getTableModeColumns(projectFilter) {
      if (projectFilter === constants.HIDDEN) {
        // Hide below columns if hidden projects filter is selected
        const hideColumns = [
          TableModeConstants.COL_DEEP_LINKS_ID,
          TableModeConstants.COL_STATUS_ID,
          TableModeConstants.COL_STATISTICS_ID
        ];
        const tableColumns = [];
        tableColumns.push({
          id: constants.SHOW,
          label: constants.SHOW_LABEL,
          visible: true
        });
        tableModeColumns.forEach(column => {
          if (!hideColumns.includes(column.id)) {
            const col = { ...column };
            col.visible = true;
            col.draggable = false;
            tableColumns.push(col);
          }
        });
        return tableColumns;
      }
      return self.tableModeColumns;
    },
    updateColumnVisibility(id, value) {
      const column = self.tableModeColumns.find(el => el.id === id);
      column.setVisibility(value);
    },
    getShowAllValue() {
      return self.tableModeColumns.every(option => option.visible === true);
    },
    updateAllColumnsVisibility(value) {
      const columns = self.tableModeColumns.filter(
        option => option.mandatory !== true
      );
      columns.map(column => column.setVisibility(value));
    },
    setColumnToSort(value) {
      self.columnToSort = value;
    },
    setSortDirection(value) {
      self.sortDirection = value;
    },
    getSortCriteria() {
      return {
        columnToSort: self.columnToSort,
        sortDirection: self.sortDirection,
        serverSideSort: self.serverSideSort.includes(self.columnToSort)
      };
    },
    updateColumnOrder(value) {
      self.tableModeColumns = value;
    },
    updateIsMyRoleSelected(value) {
      self.isMyRoleSelected = value;
    }
  }));
