/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import moment from 'moment';
import { isEmpty } from 'lodash';
import { deleteCookieByName, deleteCookie } from './cookies';
import Constants from '../constants';
import { EMAIL_VALIDATION_REGEX, VALID_URL_REGEX } from './regexPatterns';
import gaEventConstants from '../constants/gaEventConstants';
import { gaTriggerGTMCustomEvents } from '../../ga';

// mycloud url based on the window location
export const getIESUrl = () => {
  if (
    window.location.host === Constants.PROD_URL ||
    window.location.host === Constants.PROD_PACE_URL
  ) {
    return `https://${Constants.PROD_IES_URL}`;
  }
  if (
    window.location.host === Constants.PERF_URL ||
    window.location.host === Constants.PERF_PACE_URL
  ) {
    return `https://${Constants.PERF_IES_URL}`;
  }
  return `https://${Constants.NON_PROD_IES_URL}`;
};

export const secureLink = link => {
  if (link.indexOf('https') < 0) {
    return link.replace('http', 'https');
  }
  return link;
};

export const clearCookiesFromUDB = userId => {
  // deleting DISABLE_DELETE_WARNINGS, DISABLE_LIST_ELEMENT_WARNING cookie as requested from Cypress(PCAT-14885, PCAT-15890)
  deleteCookieByName('DISABLE_DELETE_WARNINGS');
  deleteCookieByName('DISABLE_LIST_ELEMENT_WARNING');
  deleteCookieByName('DISABLE_DI_CONVERSION_WARNING');

  deleteCookie(`${Constants.RELEASE_CONTENT_FOR}${userId}`);
  deleteCookie(`${Constants.FILTER_FOR}${userId}`);
  deleteCookie(`${Constants.TUTORIAL_FOR}${userId}}`);
};

// if token not found redirect to ies
export const redirectToLogin = () => {
  clearCookiesFromUDB();
  const IES_URL = getIESUrl();

  const httpsRedirectLink = secureLink(window.location.href);
  const redirectEncoded = encodeURIComponent(httpsRedirectLink);
  window.location.assign(
    `${IES_URL}${Constants.REDIRECT_TO_IES_LOGIN}&url=${redirectEncoded}`
  );
};

// logout on click of logout button
export const redirectToLogout = redirectLink => {
  const IES_URL = getIESUrl();

  const httpsRedirectLink = secureLink(redirectLink);
  // Below will encode the application redirect url
  const encodedApplUrl = encodeURIComponent(httpsRedirectLink);
  // constructing the goto url with IES login page and encoded application url
  const completeRedirectURL = `${IES_URL}${Constants.REDIRECT_TO_IES_LOGIN}&url=${encodedApplUrl}`;
  // encoding the complete goto url, so that IES can decode and redirect to login first
  // and after successful login, again decode and redirect to application url
  const redirectEncoded = encodeURIComponent(completeRedirectURL);
  window.location.assign(
    `${IES_URL}${Constants.IES_LOGOUT_URL}/&goto=${redirectEncoded}&azurelogout=true`
  );
};

export const urlModifier = url => {
  return url;
};

export const parseURLParams = (params, str) => {
  if (!str) {
    return str;
  }
  return str.replace(/%\w+%/g, all => params[all] || all);
};

export const replaceAllString = (str, replacements) => {
  return str.replace(
    new RegExp(Object.keys(replacements).join('|'), 'g'),
    match => {
      return replacements[match];
    }
  );
};

const triggerEventForLogout = userId => {
  const gtmDataForLogout = {
    event: gaEventConstants.SIGNOUT_BUTTON_CLICKED,
    page: window.location.href,
    userId
  };
  gaTriggerGTMCustomEvents(gtmDataForLogout);
};

export const logout = userId => {
  clearCookiesFromUDB(userId);
  triggerEventForLogout(userId);
  redirectToLogout(window.location.href);
};

/**
 * Open URL in same tab
 * @param {*} url
 */
export const redirectToExternalLink = url => {
  window.open(url, '_self');
};

/**
 * Launch URL in a blank tab
 * @param {*} url
 */
export const loadUrlInBlankTab = url => {
  window.open(url, '_blank');
};

/**
 * Launch URL in a new window
 * @param {*} url
 */
export const loadUrlInBlankWindow = url => {
  window.open(
    url,
    '_blank',
    'location=yes,height=700,width=800,scrollbars=yes,status=yes'
  );
};

/**
 * opens URL in new Tab
 * and if URL is called again from Application is on any button clicked again
 * the alreadyopened tab will reload
 * */
export const loadUrlInNewTab = link => {
  window.open(link, 'newTab');
};

/**
 * This is used to extract query param value from url.
 * This utilize browser builtin URLSearchParams to extract
 * value from search params of url
 * @param {*} searchParam search prop of useLocation() hook
 * @param {*} param query param
 * @returns value of given param if found else empty string
 */
export const getQueryParamValue = (searchParam, param) => {
  const query = new URLSearchParams(searchParam || window.location.search);
  return query.get(param) || '';
};

export const isSameRequest = (lastReq, newReq) => {
  return JSON.stringify(lastReq) === JSON.stringify(newReq);
};

export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

export const getRandomUniqueNumbers = (min, max, count) => {
  const randomUniqueNumbers = [];
  while (randomUniqueNumbers.length < count) {
    const randomNumber = getRandomNumber(min, max);
    if (randomUniqueNumbers.indexOf(randomNumber) === -1) {
      randomUniqueNumbers.push(randomNumber);
    }
  }
  return randomUniqueNumbers;
};

/**
 * getProjectCardBackgroundColor
 * Returning background color for project card and
 * maintaining closure scope
 * @return { backgroundColor }
 */
export const getProjectCardBackgroundColor = (() => {
  const backgroundColor = [
    '#FFB81C',
    '#EA7600',
    '#DB0020',
    '#12B2A6',
    '#9E007E',
    '#EA067E',
    '#84BD00',
    '#003057'
  ];

  const { length: BG_COLOR_MAX_LENGTH } = backgroundColor;
  let index = 0;

  // Returning inner function
  return () => {
    index += 1;
    if (index >= BG_COLOR_MAX_LENGTH) {
      index = 0;
    }
    return {
      backgroundColor: backgroundColor[index]
    };
  };
})();

// Check whether to enable or disable track changes based on trackChange Object state
export const checkTrackChangesEnableDisableStatus = trackChangeObject => {
  if (
    Object.keys(trackChangeObject).length &&
    trackChangeObject.activated === true
  ) {
    return true;
  }
  return false;
};

export const getProjectDetailUrl = (dUrn, entityUrn) => {
  return `/project/version/${dUrn}/entity/${entityUrn}`;
};

export const getDistributionPageUrl = (dUrn, platform) => {
  return `/distribution/${dUrn}/distribute?platform=${platform}`;
};

export const getPreflightPageUrl = (dUrn, entityUrn, taskId, filters) => {
  let queryParams = '';
  if (taskId) {
    queryParams += `taskId=${taskId}`;
  }
  if (filters) {
    queryParams += queryParams ? `&filters=${filters}` : `filters=${filters} `;
  }
  return `/preflight/projectId/${dUrn}/entityId/${entityUrn}?${queryParams}`;
};

// change only url do not reload page
export const changeUrl = url => {
  window.history.pushState({}, null, url);
};

// get formatted date based on the valid date format passed otherwise returns false
export const getFormattedDate = (date, format) => {
  if (moment(date, true).isValid()) {
    return moment(date).format(format);
  }
  return false;
};

export const sortFn = (a, b, prop) => {
  /* Keeping blank value instead of undefined/null for sorting purpose  */
  const aValue =
    a[prop] === null || a[prop] === undefined ? ' ' : a[prop].toLowerCase();
  const bValue =
    b[prop] === null || b[prop] === undefined ? ' ' : b[prop].toLowerCase();

  /* istanbul ignore else  */
  if (aValue < bValue) {
    return -1;
  }
  /* istanbul ignore else  */
  if (aValue > bValue) {
    return 1;
  }
  return 0;
};

export const sortFnForNumeber = (a, b, prop) => {
  /* Keeping blank value instead of undefined/null for sorting purpose  */
  const aValue = a[prop] === null || a[prop] === undefined ? ' ' : a[prop];
  const bValue = b[prop] === null || b[prop] === undefined ? ' ' : b[prop];

  /* istanbul ignore else  */
  if (aValue < bValue) {
    return -1;
  }
  /* istanbul ignore else  */
  if (aValue > bValue) {
    return 1;
  }
  return 0;
};
// Sorting records based on prop
export const sortByProp = prop => {
  return (a, b) => sortFnForNumeber(a, b, prop);
};

export const sortByPropAndDirection = (prop, direction, numbers = false) => {
  // Number Sort
  if (numbers) {
    return direction === 'asc'
      ? (a, b) => a[prop] - b[prop]
      : (a, b) => b[prop] - a[prop];
  }

  // String sort
  return direction === 'asc'
    ? (a, b) => sortFn(a, b, prop)
    : (a, b) => -sortFn(a, b, prop);
};

/**
 * validate email
 */
export const isEmail = email => {
  // eslint-disable-next-line no-useless-escape
  return EMAIL_VALIDATION_REGEX.test(email);
};

/**
 * Validate URL
 */
export const isValidURL = url => {
  const res = url.match(VALID_URL_REGEX);
  return res !== null;
};

// case insensitive search of array
export const includes = (array, searchValue) => {
  return array.some(value => value.toLowerCase() === searchValue.toLowerCase());
};

export const getClassNameFromLabel = label => {
  return label.trim().replace(/\s+/g, '-').toLowerCase();
};

/**
 * Get Valid logged in user avatar
 * @param {email}
 */

export const getUserAvatar = email => {
  const avatarURL = Constants.USER_OUTLOOK_URL(String(email).trim());
  const avatar3565URL = Constants.USER_OUTLOOK_365_URL(String(email).trim());
  return {
    backgroundImage: `url(${avatarURL}), url(${avatar3565URL})`
  };
};

/**
 * Convert image into base64
 * @param {file}
 */
export const convertImageIntoBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

/**
 * DragStart function
 */

export const handleDragStart = (data, dragContent) => event => {
  const fromBox = JSON.stringify({ id: data.id });
  event.dataTransfer.setData(dragContent, fromBox);
};

/**
 * DragOver function
 */
export const handleDragOver = () => event => {
  event.preventDefault();
  return false;
};

/**
 * Drop function
 */
export const handleDrop = (data, dragContent, callBackFn) => event => {
  event.preventDefault();
  const fromBox = JSON.parse(event.dataTransfer.getData(dragContent));
  const toBox = { id: data.id };
  callBackFn(fromBox, toBox);
  return false;
};

/**
 * Copying the content on clipboard
 */
export const copyToClipboard = text => {
  const textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

export const fileNameFromUrl = url => {
  if (!url) {
    return '';
  }
  return url.match(/.*\/(.*)$/)[1];
};

export const timeStampdateFormatter = timeStamp => {
  return timeStamp
    ? moment.unix(timeStamp).format(Constants.DATE_DISPLAY_FORMAT)
    : '';
};

/**
 * Parse UTC date time from string and returns local date time
 * default format of string is "MM/DD/YY h:mm:ssa"
 * Ex. 03/25/22 9:55:34am
 * Note: if isISO8601Format is true, given format will be ignored
 * @param {string} dateString
 * @param {string} format
 * @param {string} isISO8601Format if date format is like 2010-01-01T05:06:07Z
 * @returns {string} local date time, Ex. March 25, 2022 1:39 PM
 */
export const parseDateTime = (
  dateString,
  format = 'MM/DD/YY h:mm:ssa',
  isISO8601Format = false
) => {
  const parsedDate = moment.utc(
    dateString,
    isISO8601Format ? moment.ISO_8601 : format,
    true
  );
  return parsedDate.local().format(Constants.DATE_DISPLAY_FORMAT);
};

export const copyDivToClipboard = elem => {
  const range = document.createRange();
  range.selectNode(document.getElementById(elem));
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);
  document.execCommand('copy');
  window.getSelection().removeAllRanges();
};

export const getLabelByValue = (value, taxonomyList) => {
  if (value) {
    const taxonomyObj = taxonomyList.find(taxonomy => taxonomy.value === value);
    if (taxonomyObj) {
      return taxonomyObj.label;
    }
  }
  return '';
};

export const downloadFile = (content, fileName, isBlobUrl = false) => {
  const link = document.createElement('a');
  link.href = content;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  if (isBlobUrl) {
    // to save memory
    setTimeout(() => {
      URL.revokeObjectURL(content);
    }, 1500);
  }
};

export const lobFilter = (lob, projects) =>
  lob !== Constants.DEFAULT_SELECTED_LOB && !isEmpty(projects);

export const productModelFilter = (showAllPlatforms, projects) =>
  !showAllPlatforms && !isEmpty(projects);

export const projectStatusFilter = (publishedStatus, projects) =>
  publishedStatus !== Constants.STATUS_FILTER_DEFAULT && !isEmpty(projects);

export const processImportedFile = importData => {
  if (importData.length === 0) {
    return false;
  }
  return (
    importData[0].status.toLowerCase() ===
    Constants.IMPORTED_FILE_PROCESSING_STATUS
  );
};

export const fetchProductId = (publishInfo, status, projectProductId) => {
  if (publishInfo && publishInfo.productId) {
    return publishInfo.productId;
  }
  if (
    status.projectStatus.toUpperCase() === Constants.VERSION_WIP &&
    projectProductId
  ) {
    return projectProductId;
  }
  return null;
};

export const fetchLessonPublishingStatus = (status, statusMap) => {
  return statusMap[status];
};
