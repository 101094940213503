/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState, useEffect, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import { InputLabel } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import PropTypes from 'prop-types';
import './Select.styles.scss';

/**
 * CustomSelect component
 * Wrapper around Material UI - Select component to encapsulate
 * the common implementation of the component
 */
const CustomSelect = props => {
  const {
    label,
    options,
    value,
    onChange,
    className: additionalClasses = '',
    optionsClassName = '',
    variant,
    ...additionalProps
  } = props;

  // Label width
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  /**
   * Updating inputLabel width when inputLabel is changing
   */
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, [inputLabel]);

  /**
   * Returning the options to render in @mui <select>
   */
  const renderOptions = () => {
    return options.map((option, index) => {
      const i = index + 1;
      const { label: name, value: optionValue } = option;
      return (
        <MenuItem
          key={`option_${i}`}
          name={label}
          value={optionValue}
          className={`select-menu-item ${optionsClassName}`}
          aria-label={`select-component-option-${name}`}
        >
          {name}
        </MenuItem>
      );
    });
  };

  const onOptionChange = event => {
    const {
      target: { value: selectedValue }
    } = event;
    const [selectedOption] = options.filter(option => {
      const { value: optionValue } = option;
      return optionValue === selectedValue;
    });
    onChange(selectedOption);
  };

  return (
    <FormControl
      variant={variant}
      className="select-form-control"
      data-testid="select-component"
      size="small"
    >
      <InputLabel ref={inputLabel} htmlFor="select-component">
        {label}
      </InputLabel>
      <Select
        className={`select ${additionalClasses}`}
        onChange={onOptionChange}
        value={value}
        labelWidth={labelWidth}
        label={label}
        inputProps={{
          name: 'select',
          id: 'select-component'
        }}
        // Overriding default arrow icon with customized icon
        IconComponent={ExpandMoreOutlinedIcon}
        {...additionalProps}
      >
        {renderOptions()}
      </Select>
    </FormControl>
  );
};

CustomSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  optionsClassName: PropTypes.string,
  variant: PropTypes.string
};

export default CustomSelect;
